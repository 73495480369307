// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".steps_stepsWrapper_GQ2Ji {\n  margin-bottom: 20px;\n}\n.steps_stepsWrapper_GQ2Ji p {\n  text-align: center;\n}\n", "",{"version":3,"sources":["steps.styl"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB","file":"steps.styl","sourcesContent":[".stepsWrapper {\n  margin-bottom: 20px;\n}\n.stepsWrapper p {\n  text-align: center;\n}\n"]}]);
// Exports
exports.locals = {
	"stepsWrapper": "steps_stepsWrapper_GQ2Ji"
};
module.exports = exports;
