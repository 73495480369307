// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ContentLoading_loadingWrapper_gf6x7 {\n  text-align: center;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  justify-items: center;\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["ContentLoading.styl"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAa;EAAb,qBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;MAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB","file":"ContentLoading.styl","sourcesContent":[".loadingWrapper {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  justify-items: center;\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"loadingWrapper": "ContentLoading_loadingWrapper_gf6x7"
};
module.exports = exports;
