import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import JuceModal from 'components/common/modal';
import ActionButton from 'components/common/buttons/ActionButton';

class DismissableModal extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { siteUIStore } = this.props;

    return (
      <JuceModal
        isOpen={Boolean(siteUIStore.presentDismissable)}
        onRequestClose={()=>{siteUIStore.setPresentDismissable(false);}}
        hideHeader={true}
      >
        <p style={{marginBottom: '20px'}}>{siteUIStore.presentDismissable}</p>
        <ActionButton
          fullWidth
          label='OK'
          inverted={false}
          onButtonClicked={()=>{siteUIStore.setPresentDismissable(false);}}
        />
      </JuceModal>
    );
  }
}

DismissableModal.propTypes = {
  siteUIStore: PropTypes.object,
};

export default inject('siteUIStore')(observer(DismissableModal));
