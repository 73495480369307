// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".shopClosed_shopClosed_2LF17 {\n  text-align: center;\n}\n.shopClosed_shopClosed_2LF17 svg {\n  background: #e73e51;\n  padding: 20px;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  margin-bottom: 30px;\n}\n", "",{"version":3,"sources":["shopClosed.styl"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB","file":"shopClosed.styl","sourcesContent":[".shopClosed {\n  text-align: center;\n}\n.shopClosed svg {\n  background: #e73e51;\n  padding: 20px;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  margin-bottom: 30px;\n}\n"]}]);
// Exports
exports.locals = {
	"shopClosed": "shopClosed_shopClosed_2LF17"
};
module.exports = exports;
