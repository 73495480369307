import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import CloseIcon from 'components/common/icons/CloseIcon';
import LinkButton from 'components/common/buttons/LinkButton';

import styles from './OrderError.styl';

const OrderError = ({text, buttonTo, buttonLabel}) => {
  return  <div>
    <div className={styles.basketError}>
      <CloseIcon />
      <h4>{text}</h4>
      {buttonTo && buttonLabel && <LinkButton inverted={false} green={true} to={buttonTo} label={buttonLabel} />}
    </div>
    <div>
    </div>
  </div>;
};

OrderError.propTypes = {
  text: PropTypes.string,
  buttonTo: PropTypes.string,
  buttonLabel: PropTypes.string,
};

export default withStyles(styles)(OrderError);
