import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import urls from 'constants/urls';
import styles from './promptPasswordResetNotification.styl';

import JuceLogo from 'components/common/icons/JuceLogo';
import FlexWrapper from '../../wrappers/FlexWrapper';
import LinkButton from '../../common/buttons/LinkButton';
import Panel from 'components/sections/Panel';

const PromptPasswordResetNotification = () => {

  return (<FlexWrapper>
    <Panel>
      <div className={styles.locked}>
        <JuceLogo className={styles.juceIcon} />
        <h3>Password reset required</h3>
        <p>Your account requires a password reset before you can continue</p>
        <LinkButton fullWidth inverted={false} to={urls.forgotPassword} label='Request password reset'/>
      </div>
    </Panel>

  </FlexWrapper>);
};



export default withStyles(styles)(PromptPasswordResetNotification);
