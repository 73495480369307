// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PageWrapper_pageWrapper_2Z-l0 {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n}\n.PageWrapper_columnWrapper_1hxgm {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  min-height: 100vh;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  overflow-x: hidden;\n  width: 100%;\n}\n", "",{"version":3,"sources":["PageWrapper.styl"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,oBAAa;EAAb,aAAa;AACf;AACA;EACE,oBAAa;EAAb,qBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,4BAAsB;EAAtB,6BAAsB;EAAtB,8BAAsB;MAAtB,0BAAsB;UAAtB,sBAAsB;EACtB,iBAAiB;EACjB,8BAAsB;UAAtB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb","file":"PageWrapper.styl","sourcesContent":[".pageWrapper {\n  display: flex;\n}\n.columnWrapper {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  box-sizing: border-box;\n  overflow-x: hidden;\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"pageWrapper": "PageWrapper_pageWrapper_2Z-l0",
	"columnWrapper": "PageWrapper_columnWrapper_1hxgm"
};
module.exports = exports;
