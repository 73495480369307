
import { getEnvVar } from 'utils/envUtils';

const apiHost = getEnvVar('JUCE_API_URL');

export const validateVatId = async (vat_id, name) => {
  let updateResponse = await fetch(`${apiHost}/api/v1/vat/validate_vat_id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      vat_id,
      name,
    }),
  });

  return await updateResponse.json();
};
