import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import FlexWrapper from 'components/wrappers/FlexWrapper';
import ContentLoading from 'components/common/loading/ContentLoading';

class SignOutPage extends Component {

  componentDidMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const redirectUrl= queryParams.get('redirect');
      this.props.accountStore.loadSignOut(redirectUrl);
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Sign Out');
  }

  render() {
    return <FlexWrapper>
      <div>
        <ContentLoading />
      </div>
    </FlexWrapper>;
  }
}

SignOutPage.propTypes = {
  accountStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
  location: PropTypes.object,
};

export default inject('accountStore', 'siteUIStore', 'siteMetaStore')(observer(SignOutPage));
