import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import urls from 'constants/urls';
import LinkButton from 'components/common/buttons/LinkButton';

import styles from './MiniNav.styl';

class ContentLoading extends Component {

  render() {
    return (
      <div className={styles.nav}>
        <div className={styles.navButton}>
          <LinkButton
            dark
            inverted={true}
            label='Dashboard'
            to={urls.dashboard}
          />
        </div>
        <div className={styles.navButton}>
          <LinkButton
            dark
            inverted={true}
            label='Profile'
            to={urls.profile}
          />
        </div>
        <div className={styles.navButton}>
          <LinkButton
            dark
            inverted={true}
            label='Payments'
            to={urls.payments}
          />
        </div>
        <div className={styles.navButton}>
          <LinkButton
            red
            inverted={true}
            label='Log out'
            to={urls.signOut}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ContentLoading);
