// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InvalidDiscount_card_2EWQf {\n  color: #000;\n  background: #fff;\n  border: 1px solid #dcdcdc;\n  margin: 20px 0;\n  -webkit-box-flex: 1;\n  -webkit-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.InvalidDiscount_cardTitle_AUDHO {\n  font-weight: font-weight-bold;\n  color: #288dc1;\n}\n.InvalidDiscount_cardMessage_3oL4H {\n  color: #000;\n}\n.InvalidDiscount_cardMessage_3oL4H > a.InvalidDiscount_supportLink_1fAHQ {\n  color: #000;\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["InvalidDiscount.styl"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,mBAAO;EAAP,eAAO;MAAP,WAAO;UAAP,OAAO;AACT;AACA;EACE,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,0BAA0B;AAC5B","file":"InvalidDiscount.styl","sourcesContent":[".card {\n  color: #000;\n  background: #fff;\n  border: 1px solid #dcdcdc;\n  margin: 20px 0;\n  flex: 1;\n}\n.cardTitle {\n  font-weight: font-weight-bold;\n  color: #288dc1;\n}\n.cardMessage {\n  color: #000;\n}\n.cardMessage > a.supportLink {\n  color: #000;\n  text-decoration: underline;\n}\n"]}]);
// Exports
exports.locals = {
	"card": "InvalidDiscount_card_2EWQf",
	"cardTitle": "InvalidDiscount_cardTitle_AUDHO",
	"cardMessage": "InvalidDiscount_cardMessage_3oL4H",
	"supportLink": "InvalidDiscount_supportLink_1fAHQ"
};
module.exports = exports;
