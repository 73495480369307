import { action, observable, makeObservable } from 'mobx';
import { getEnvVar } from 'utils/envUtils';

import urls from 'constants/urls';

const apiHost = getEnvVar('JUCE_API_URL');

/**
 * Class that loads and stores data for the shop
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class ShopStore {

  loading;
  plans;
  plan_id;
  basket;
  customer_vat_id;
  reconfirming;
  payment_type;
  user_coupon;
  seat_quantity;
  payment_intent_client_secret;
  basketError;
  basketComplete;

  constructor() {
    makeObservable(this, {
      loading: observable,
      plans: observable,
      plan_id: observable,
      basket: observable,
      customer_vat_id: observable,
      reconfirming: observable,
      payment_type: observable,
      user_coupon: observable,
      seat_quantity: observable,
      payment_intent_client_secret: observable,
      basketError: observable,
      basketComplete: observable,
      reset: action,
      changeSeatQuantity: action.bound,
      changePaymentType: action.bound,
      changePlan: action.bound,
      setLoading: action.bound,
      changeCustomerVatId: action.bound,
      createBasket: action.bound,
      createCurrentSubscriptionBasket: action.bound,
      updateBasket: action.bound,
      terminateBasket: action.bound,
      getPlans: action.bound,
      setPlanId: action.bound,
      attachUserToBasket: action.bound,
      confirmBasket: action.bound,
      reconfirmBasket: action.bound,
      attachPaymentMethodToBasket: action.bound,
      onCreatedBasket: action('Subtotal data found'),
      onUpdatedBasket: action('Subtotal data found'),
      onPlansLoaded: action('Subtotal data found'),
    });

    this.reset();
  }

  reset() {
    this.loading = false;
    this.plans = undefined;
    this.plan_id = undefined;
    this.basket = undefined;
    this.customer_vat_id = undefined;
    this.reconfirming = false;
    this.payment_type = 'monthly';
    this.user_coupon = null;
    this.seat_quantity = 1;
    this.payment_intent_client_secret = null;
    this.basketError = null;
    this.basketComplete = null;
  }

  async requestPlans() {
    let response = await fetch(`${apiHost}/api/v1/plans`, {
      method: 'GET',
    });
    if (response.status == 200) {
      return response.json();
    } else {
      return 0;
    }
  }

  async requestCreateBasket() {
    let response = await fetch(`${apiHost}/api/v1/basket`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        plan: this.plan_id,
        seat_quantity: this.seat_quantity,
        payment_type: this.payment_type,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else {
      this.basketError = 'Error updating order basket';
      throw ('Error creating basket');
    }
  }

  async requestCreateCurrentSubscriptionBasket() {
    let response = await fetch(`${apiHost}/api/v1/basket/edit_current_subscription`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 400) {
      window.location = urls.dashboard;
    } {
      this.basketError = 'Error updating order basket';
      throw ('Error creating basket');
    }
  }

  async requestUpdateBasket() {
    let response = await fetch(`${apiHost}/api/v1/basket/${this.basket._id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        seat_quantity: this.seat_quantity,
        payment_type: this.payment_type,
        user: this.attached_user? this.attached_user.id : null,
        payment_method_id: this.payment_method_id || null,
        user_coupon: this.user_coupon || null,
        customer_vat_id: this.customer_vat_id,
        plan_id: this.plan_id,
      }),
    });
    if (response.status == 200) {
      return await response.json();
    } else {
      this.basketError = 'Error updating order basket';
      throw ('Error created basket');
    }
  }

  async requestTerminateBasket() {
    await fetch(`${apiHost}/api/v1/basket/${this.basket._id}/terminate`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async requestConfirmBasket() {
    let response = await fetch(`${apiHost}/api/v1/basket/${this.basket._id}/confirm`, {
      method: 'POST',
      credentials: 'include',
    });
    if (response.status == 200 || response.status == 400) {
      return response.json();
    }
    throw ('Error confirming basket');
  }

  async requestReconfirmBasket() {
    let response = await fetch(`${apiHost}/api/v1/basket/${this.basket._id}/reconfirm`, {
      method: 'GET',
      credentials: 'include',
    });
    if (response.status == 200 || response.status == 400) {
      return response.json();
    }
    throw ('Error reconfirming basket');
  }

  /**
   * Loads Seat Quantity
   */
  changeSeatQuantity(seatQuantity) {
    this.setLoading(true);
    this.seat_quantity = seatQuantity;
    this.updateBasket();
  }

  /**
   * Loads Seat Quantity
   */
  changePaymentType(payment_type) {
    this.setLoading(true);
    this.payment_type = payment_type;
    this.updateBasket();
  }

  /**
   * Loads Seat Quantity
   */
  changePlan(plan_id) {
    this.setLoading(true);
    this.plan_id = plan_id;
    this.updateBasket();
  }

  /**
   * Loads Seat Quantity
   */
  setLoading(bool) {
    this.loading = bool;
  }


  /**
   * Loads Seat Quantity
   */
  changeCustomerVatId(vat_id) {
    this.customer_vat_id = vat_id;
    this.updateBasket();
  }

  /**
   * Loads Seat Quantity
   */
  createBasket() {
    this.setLoading(true);
    this.requestCreateBasket()
      .then(basket => this.onCreatedBasket(basket));
  }

  /**
   * Loads Seat Quantity
   */
  createCurrentSubscriptionBasket() {
    this.setLoading(true);
    this.requestCreateCurrentSubscriptionBasket()
      .then(basket => this.onCreatedBasket(basket));
  }

  updateBasket() {
    this.setLoading(true);
    this.requestUpdateBasket()
      .then(basket => this.onUpdatedBasket(basket))
      .catch(() => {
        this.basketError = 'There was a problem updating your basket';
      });
  }

  terminateBasket() {
    this.requestTerminateBasket();
  }

  async getPlans() {
    this.requestPlans()
      .then(response => this.onPlansLoaded(response));
  }

  async setPlanId(plan_id) {
    this.plan_id = plan_id;
  }


  async attachUserToBasket(userId) {
    if (this.basket && !this.basket.user) {
      this.attached_user = userId;
      this.requestUpdateBasket()
        .then(basket => this.onUpdatedBasket(basket));
    }
  }


  async confirmBasket(callback) {
    if (this.basket && this.basket.user && this.basket.payment_method_id) {
      this.requestConfirmBasket()
        .then(response => {
          this.onUpdatedBasket(response);
          callback();
        });
    }
  }

  async reconfirmBasket(callback) {
    if (this.basket) {
      this.reconfirming = true;
      this.requestReconfirmBasket()
        .then(response => {
          this.onUpdatedBasket(response);
          this.reconfirming = false;
          callback();
        });
    }
  }

  async attachPaymentMethodToBasket(paymentMethodId, callback) {
    if (this.basket && this.basket.user) {
      this.payment_method_id = paymentMethodId;
      this.requestUpdateBasket()
        .then(basket => {
          this.onUpdatedBasket(basket);
          if (callback) callback();
        });
    }
  }

  onCreatedBasket(basket) {
    this.setLoading(false);
    this.basket = basket;
    this.customer_vat_id = basket.customer_vat_id;
    this.seat_quantity = basket.seat_quantity;
    this.payment_method_id = basket.payment_method_id;
  }

  onUpdatedBasket(basket) {
    this.setLoading(false);
    this.basket = basket;
    this.customer_vat_id = basket.customer_vat_id;
    this.seat_quantity = basket.seat_quantity;
  }

  onPlansLoaded(plans) {
    this.plans = plans.sort((a, b) => (a.listing_order > b.listing_order)? 1:-1);
  }
}

export default ShopStore;
