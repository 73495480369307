import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './PageContentWrapper.styl';

class PageContentWrapper extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
      </div>
    );
  }
}

PageContentWrapper.propTypes = {
  location: PropTypes.object.isRequired,
  siteUIStore: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(inject('siteUIStore')(observer(PageContentWrapper)));
