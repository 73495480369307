// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DownloadCard_card_2fyX1 {\n  background: #6cc04a;\n  color: #fff;\n  margin-left: 12px;\n  margin-right: 12px;\n  -webkit-box-flex: 1;\n  -webkit-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.DownloadCard_cardTitle_tPTBB {\n  font-weight: font-weight-bold;\n  color: #fff;\n}\n.DownloadCard_cardMessage_15rrn {\n  color: #fff;\n}\n.DownloadCard_cardCta_SqUdR {\n  border-bottom: 1px solid #fff;\n  text-decoration: none;\n  -webkit-transition: opacity 0.1s linear;\n  transition: opacity 0.1s linear;\n}\n.DownloadCard_cardCta_SqUdR:hover,\n.DownloadCard_cardCta_SqUdR:focus {\n  opacity: 0.6;\n}\n", "",{"version":3,"sources":["DownloadCard.styl"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,mBAAO;EAAP,eAAO;MAAP,WAAO;UAAP,OAAO;AACT;AACA;EACE,6BAA6B;EAC7B,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,uCAA+B;EAA/B,+BAA+B;AACjC;AACA;;EAEE,YAAY;AACd","file":"DownloadCard.styl","sourcesContent":[".card {\n  background: #6cc04a;\n  color: #fff;\n  margin-left: 12px;\n  margin-right: 12px;\n  flex: 1;\n}\n.cardTitle {\n  font-weight: font-weight-bold;\n  color: #fff;\n}\n.cardMessage {\n  color: #fff;\n}\n.cardCta {\n  border-bottom: 1px solid #fff;\n  text-decoration: none;\n  transition: opacity 0.1s linear;\n}\n.cardCta:hover,\n.cardCta:focus {\n  opacity: 0.6;\n}\n"]}]);
// Exports
exports.locals = {
	"card": "DownloadCard_card_2fyX1",
	"cardTitle": "DownloadCard_cardTitle_tPTBB",
	"cardMessage": "DownloadCard_cardMessage_15rrn",
	"cardCta": "DownloadCard_cardCta_SqUdR"
};
module.exports = exports;
