import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import RequestValidation from 'components/forms/Account/RequestValidation';

class RequestValidationPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Request Validation');
  }

  render() {
    const { accountStore } = this.props;
    const loadRequestValidation = accountStore.loadRequestValidation;

    return <section>
      <RequestValidation onRequestValidation={loadRequestValidation} />
    </section>;
  }
}

RequestValidationPage.propTypes = {
  accountStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
};

export default inject('accountStore', 'siteMetaStore')(observer(RequestValidationPage));
