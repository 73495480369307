import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import withStyles from 'isomorphic-style-loader/withStyles';
import {calculateSubscriptionMonthlyTotal} from 'utils/subscription';
import ContentLoading from 'components/common/loading/ContentLoading';

import styles from './ProrateSubtotal.styl';

const CreditNotice = ({cost}) => {
  return <div className={styles.creditNotice}>
    Your JUCE account will be credited with <b>${(cost/100).toFixed(2)}</b> to account for the remainder of this current billing cycle. This credit will be used to pay upcoming invoices.
  </div>;
};

const ChargeNotice = ({cost}) => {
  return <div className={styles.creditNotice}>
    You will charged an additional ${(cost/100).toFixed(2)} on your next invoice.
  </div>;
};

const ProrateSubtotal = ({
  basket,
  loading,
}) => {

  const calculateTotal = () => {
    let totalWithVat = basket.total.net;
    if (basket.total.vatPercentage) {
      totalWithVat += basket.total.net*basket.total.vatPercentage/100;
    }

    return totalWithVat;
  };

  const renderTotalProation = () => {
    if (basket.total.proration.cost > 0) {
      return <ChargeNotice cost={basket.total.proration.cost} />;
    } else if (basket.total.proration.cost < 0) {
      return <CreditNotice cost={Math.abs(basket.total.proration.cost)} />;
    }
  };

  const vatString = (basket.total.vatPercentage && basket.total.vatPercentage > 0 && basket.tax_rates && basket.tax_rates.length > 0)
    ? ` (including ${basket.tax_rates[0].jurisdiction} VAT at ${basket.tax_rates[0].percentage}%)`
    : '';

  return <div className={styles.basketSummary}>
    {!loading ? <div className={styles.subtotal}>
      <div>
        <p className={styles.modificationTitle}>Current monthly cost</p>
        <div className={styles.price}>
          <span>
            <b>${(calculateSubscriptionMonthlyTotal(basket.current_subscription)/100).toFixed(2)}</b> {vatString}
          </span>
        </div>
      </div>
      <div>
        {basket.total.proration.items.length ? <p className={styles.modificationTitle}>Subscription adjustments</p>: null}
        <ol>
          {basket.total.proration.items.map((prorationItem, index) => {
            return <li key={`pi${index}`}><b>${(prorationItem.amount/100).toFixed(2)}</b> - {prorationItem.description}</li>;
          })}
        </ol>
      </div>
      {renderTotalProation()}
      {basket.total.proration.invoice && <div><p className={styles.modificationTitle}>Next invoice amount</p><div className={styles.price}><span><b>${(basket.total.proration.invoice.amount_due/100).toFixed(2)}</b></span> on {moment(basket.total.proration.invoice.next_payment_attempt *1000).format('ll')} {vatString}</div></div>}
      <p className={styles.modificationTitle}>Monthly amount thereafter</p>
      <div className={styles.price}>
        <span><b>${(calculateTotal()/100).toFixed(2)}</b> {vatString}</span>
      </div>
    </div>: <ContentLoading />}
  </div>;
};

CreditNotice.propTypes = {
  cost: PropTypes.number,
};

ChargeNotice.propTypes = {
  cost: PropTypes.number,
};

ProrateSubtotal.propTypes = {
  basket: PropTypes.object,
  loading: PropTypes.bool,
};

export default withStyles(styles)(ProrateSubtotal);
