import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { ASSET_URL } from 'constants/roli';

class Metadata extends React.Component {
  render() {
    const { siteMetaStore: { metadata, title } } = this.props;

    const formattedTitle = title || (metadata && metadata.title) ? `${title || (metadata && metadata.title)} | JUCE` : 'JUCE';

    return (
      <Helmet>
        <title>{formattedTitle}</title>
        <meta property='og:type' content={'website'} />

        <link rel='icon' type='image/png' href={`${ASSET_URL}/favicons/juce-monogram-64.png`} sizes='64x64' />
        <link rel='stylesheet' href={`${ASSET_URL}/react-datepicker.css`} />

        <meta content={`${ASSET_URL}/favicons/juce-windows-tile-70x70.png`} name='msapplication-square70x70logo' />
        <meta content={`${ASSET_URL}/favicons/juce-windows-tile-150x150.png`} name='msapplication-square150x150logo' />
        <meta content={`${ASSET_URL}/favicons/juce-windows-tile-310x310.png`} name='msapplication-square310x310logo' />
        <meta content={`${ASSET_URL}/favicons/juce-windows-tile-144x144.png`} name='msapplication-TileImage' />
        <meta content='#090909' name='msapplication-TileColor' />

        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon.png`} rel='apple-touch-icon' sizes='57x57' />
        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon-60x60-precomposed.png`} rel='apple-touch-icon-precomposed' sizes='60x60' />
        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon-72x72-precomposed.png`} rel='apple-touch-icon-precomposed' sizes='72x72' />
        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon-76x76-precomposed.png`} rel='apple-touch-icon-precomposed' sizes='76x76' />
        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon-114x114-precomposed.png`} rel='apple-touch-icon-precomposed' sizes='114x114' />
        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon-120x120-precomposed.png`} rel='apple-touch-icon-precomposed' sizes='120x120' />
        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon-144x144-precomposed.png`} rel='apple-touch-icon-precomposed' sizes='144x144' />
        <link href={`${ASSET_URL}/favicons/juce-apple-touch-icon-152x152-precomposed.png`} rel='apple-touch-icon-precomposed' sizes='152x152' />

        <link rel='mask-icon' href={`${ASSET_URL}/favicons/juce-monogram.svg`} color='#323d47' />
        <link rel='shortcut icon' href={`${ASSET_URL}/favicons/juce-monogram.ico`} />
      </Helmet>
    );
  }
}

export default inject('siteMetaStore')(observer(Metadata));

Metadata.propTypes = {
  siteMetaStore: PropTypes.object,
};
