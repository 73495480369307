import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Modal from 'react-modal';

import styles from './LoadingModal.styl';
import CloseIcon from '../icons/CloseIcon';

const LoadingModal = ({
  isOpen,
  afterOpenModal = ()=>{},
  onRequestClose = ()=>{},
  children,
  title = '',
  hideHeader = false,
}) => {
  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onAfterOpen={afterOpenModal}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      contentLabel={title}
    >
      {!hideHeader && <header className={styles.header}>
        <h4>{title}</h4><CloseIcon onClick={onRequestClose}/>
      </header>}
      <section className={styles.content}>
        {children}
      </section>

    </Modal>
  );
};

LoadingModal.propTypes = {
  isOpen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  afterOpenModal: PropTypes.func,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  hideHeader: PropTypes.bool,
};

export default withStyles(styles)(LoadingModal);
