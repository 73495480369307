// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".promptPasswordResetNotification_locked_NeetI {\n  text-align: center;\n}\n.promptPasswordResetNotification_juceIcon_3rgSm {\n  width: 100px;\n  height: 100px;\n  margin-bottom: 15px;\n}\n", "",{"version":3,"sources":["promptPasswordResetNotification.styl"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB","file":"promptPasswordResetNotification.styl","sourcesContent":[".locked {\n  text-align: center;\n}\n.juceIcon {\n  width: 100px;\n  height: 100px;\n  margin-bottom: 15px;\n}\n"]}]);
// Exports
exports.locals = {
	"locked": "promptPasswordResetNotification_locked_NeetI",
	"juceIcon": "promptPasswordResetNotification_juceIcon_3rgSm"
};
module.exports = exports;
