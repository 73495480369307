import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import RequestValidationSent from 'components/forms/Account/RequestValidationSent';

class RequestValidationSentPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Request Validation');
  }

  render() {
    return <section>
      <RequestValidationSent />
    </section>;
  }
}

RequestValidationSentPage.propTypes = {
  accountStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
};

export default inject('accountStore', 'siteMetaStore')(observer(RequestValidationSentPage));
