import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { withCookies } from 'react-cookie';
import { retrocycle } from 'json-cycle';

import routes from '../routes';

import StoreProvider from 'containers/StoreProvider';
import DismissableModal from 'containers/DismissableModal';
import LoadingModal from 'containers/LoadingModal';
import PageWrapper from 'components/common/PageWrapper';
import PageContentWrapper from 'components/common/PageContentWrapper';
import Metadata from 'components/common/Metadata';
import Modal from 'react-modal';

class App extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && !this.props.server) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const { server, location } = this.props;
    const preloadedData = this.props.preloadedData ? retrocycle(JSON.parse(this.props.preloadedData)) : undefined;
    const matchingRoute = matchRoutes(routes, location.pathname)[0];

    return (
      <StoreProvider
        server={server}
        preloadedData={preloadedData}
        location={location}
      >
        <Metadata />

        {!matchingRoute.route.headless &&
          <PageWrapper>
            <PageContentWrapper location={location}>
              { renderRoutes(routes, { extraProp: Math.random(), pathname: location.pathname }) }
            </PageContentWrapper>
            <DismissableModal />
            <LoadingModal />
          </PageWrapper>

        }

        {matchingRoute.route.headless && renderRoutes(routes)}
      </StoreProvider>
    );
  }
}

App.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  server: PropTypes.bool,
  preloadedData: PropTypes.string,
};

export default withRouter(withCookies(App));
