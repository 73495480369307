// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Loading_iconClassName_h_K3w {\n  stroke: #535558;\n}\n", "",{"version":3,"sources":["Loading.styl"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","file":"Loading.styl","sourcesContent":[".iconClassName {\n  stroke: #535558;\n}\n"]}]);
// Exports
exports.locals = {
	"iconClassName": "Loading_iconClassName_h_K3w"
};
module.exports = exports;
