// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  font-family: 'HelveticaNeue', 'Helvetica Neue', 'HelveticaNeueRoman', 'HelveticaNeue-Roman', 'Helvetica Neue Roman', 'Helvetica', 'Tahoma', 'Geneva', 'Arial', sans-serif;\n}\na {\n  text-decoration: none;\n}\n.index_hidden_dKoSw {\n  position: absolute;\n  display: block;\n  width: 1px;\n  height: 1px;\n  text-indent: 105%;\n  overflow: hidden;\n  border: 0;\n  background: transparent;\n}\n.index_pageContent_2O5OC {\n  -webkit-box-flex: 1;\n  -webkit-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n#index_livechat-full_2UKwF {\n  bottom: 50px !important;\n}\n", "",{"version":3,"sources":["index.styl"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,yKAAyK;AAC3K;AACA;EACE,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,UAAU;EACV,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;EACT,uBAAuB;AACzB;AACA;EACE,mBAAO;EAAP,eAAO;MAAP,WAAO;UAAP,OAAO;AACT;AACA;EACE,uBAAuB;AACzB","file":"index.styl","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: 'HelveticaNeue', 'Helvetica Neue', 'HelveticaNeueRoman', 'HelveticaNeue-Roman', 'Helvetica Neue Roman', 'Helvetica', 'Tahoma', 'Geneva', 'Arial', sans-serif;\n}\na {\n  text-decoration: none;\n}\n.hidden {\n  position: absolute;\n  display: block;\n  width: 1px;\n  height: 1px;\n  text-indent: 105%;\n  overflow: hidden;\n  border: 0;\n  background: transparent;\n}\n.pageContent {\n  flex: 1;\n}\n#livechat-full {\n  bottom: 50px !important;\n}\n"]}]);
// Exports
exports.locals = {
	"hidden": "index_hidden_dKoSw",
	"pageContent": "index_pageContent_2O5OC",
	"livechat-full": "index_livechat-full_2UKwF"
};
module.exports = exports;
