import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import urls from '../../../../constants/urls';

import AccountDashboardComponent from 'components/account/dashboard';

class AccountDashboard extends Component {

  constructor(props) {
    super(props);
  }
  componentWillMount() {

    if (!this.props.siteUIStore.runningOnServer) {
      const { accountStore, shopStore } = this.props;
      accountStore.loadAccountProfileData();
      accountStore.loadAccountCustomerData();
      accountStore.loadAccountSubscriptionsData();
      accountStore.loadAccountLicenceData();
      accountStore.loadAccountSerialData();
      accountStore.loadAccountCustomerData();
      shopStore.getPlans();
      if (this.getEmailUpdatedFlag()) {
        this.props.siteUIStore.setPresentDismissable('Email address successfully updated');
        this.props.history.push(urls.dashboard);
      }

    }
    this.props.siteMetaStore.setPageMetadata(null, 'Account Dashboard');
  }

  getEmailUpdatedFlag = () => {
    let queryParams = new URLSearchParams(this.props.location.search);

    return queryParams.get('updatedEmail');
  };

  render() {
    const { accountStore, siteUIStore, shopStore } = this.props;

    const licences = (accountStore.licenceData) ? accountStore.licenceData : undefined;
    const profile = (accountStore.accountData) ? accountStore.accountData : undefined;
    const customer = (accountStore.customerData) ? accountStore.customerData : undefined;
    const subscriptions = (accountStore.subscriptionsData) ? accountStore.subscriptionsData : undefined;
    const serials = (accountStore.serialData) ? accountStore.serialData : undefined;
    const plans = (shopStore.plans) ? shopStore.plans : undefined;
    const hasMultipleStripeAccounts = (accountStore.hasMultipleStripeAccounts) ? accountStore.hasMultipleStripeAccounts : undefined;
    const loadRegisterProduct = accountStore.loadRegisterProduct;
    const loadUnregisterProduct = accountStore.loadUnregisterProduct;

    return <AccountDashboardComponent
      hasMultipleStripeAccounts={hasMultipleStripeAccounts}
      customer={customer}
      profile={profile}
      licences={licences}
      subscriptions={subscriptions}
      serials={serials}
      onRegisterProduct={loadRegisterProduct}
      onUnregisterProduct={loadUnregisterProduct}
      onDeleteSubscription={accountStore.deleteSubscription}
      hideShop={siteUIStore.hideShop}
      plans={plans}
    />;
  }
}

AccountDashboard.propTypes = {
  accountStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
  shopStore: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default inject('accountStore', 'shopStore','siteUIStore', 'siteMetaStore')(observer(AccountDashboard));
