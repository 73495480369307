import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './MainSection.styl';


const MainSection = ({ children }) => {
  return (
    <section className={styles.mainSection}>
      {children}
    </section>
  );
};

MainSection.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(MainSection);
