import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import TextHeader from 'components/common/pageHeaders/TextHeader';
import ForgotPassword from 'components/forms/Account/ForgotPassword';

class ForgotPasswordPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Password Reset');
  }

  render() {
    const { accountStore } = this.props;
    const loadForgotPassword = accountStore.loadForgotPassword;
    const submittedForgotPassword = accountStore.submittedForgotPassword;

    return <section>
      <TextHeader title='Password reset' />
      <ForgotPassword submittedForgotPassword={submittedForgotPassword} onForgotPassword={loadForgotPassword}/>
    </section>;
  }
}

ForgotPasswordPage.propTypes = {
  accountStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
};

export default inject('accountStore', 'siteMetaStore')(observer(ForgotPasswordPage));
