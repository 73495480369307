// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MainSection_mainSection_28AzT {\n  -webkit-flex-basis: 100%;\n      -ms-flex-preferred-size: 100%;\n          flex-basis: 100%;\n  -webkit-box-flex: 1;\n  -webkit-flex-grow: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n}\n@media (min-width: 850px) {\n  .MainSection_mainSection_28AzT {\n    -webkit-flex-basis: 75%;\n        -ms-flex-preferred-size: 75%;\n            flex-basis: 75%;\n  }\n}\n", "",{"version":3,"sources":["MainSection.styl"],"names":[],"mappings":"AAAA;EACE,wBAAgB;MAAhB,6BAAgB;UAAhB,gBAAgB;EAChB,mBAAY;EAAZ,oBAAY;MAAZ,oBAAY;UAAZ,YAAY;AACd;AACA;EACE;IACE,uBAAe;QAAf,4BAAe;YAAf,eAAe;EACjB;AACF","file":"MainSection.styl","sourcesContent":[".mainSection {\n  flex-basis: 100%;\n  flex-grow: 1;\n}\n@media (min-width: 850px) {\n  .mainSection {\n    flex-basis: 75%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"mainSection": "MainSection_mainSection_28AzT"
};
module.exports = exports;
