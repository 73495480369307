import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './ConfirmBasket.styl';
import Subtotal from './Subtotal';
import OrderSubmitting from './OrderSubmitting';
import ActionButton from 'components/common/buttons/ActionButton';
import PaymentMethod from 'components/shop/paymentMethod';
import Loading from 'components/common/loading/ContentLoading';
import urls from 'constants/urls';

const ConfirmBasket = ({ basket, plan, onConfirmBasket }) => {

  const [submitted, setSubmitted] = React.useState(false);
  const [termsAgreed, setTermsAgreed] = React.useState(false);

  const juceVersion = basket.plan.product.version;

  const getFriendlyPaymentType = (payment_type) => {
    if (payment_type == 'monthly') {
      return 'Monthly subscription';
    } else if (payment_type == 'one_off') {
      return 'One off';
    }
  };

  const handerConfirmBasket = () => {
    setSubmitted(true);
    onConfirmBasket();
  };

  return  <div className={styles.confirmPayment}>
    {!submitted ? <div>
      <h4>Order items</h4>
      <table className={styles.basketProperties}>
        <tbody>
          <tr>
            <td className={styles.basketPropertiesHeader}>Selected Licence:</td>
            <td>{plan.long_label}</td>
          </tr>
          <tr>
            <td className={styles.basketPropertiesHeader}>Seat Quantity:</td>
            <td>{basket.seat_quantity}</td>
          </tr>
          <tr>
            <td className={styles.basketPropertiesHeader}>Payment Type:</td>
            <td>{getFriendlyPaymentType(basket.payment_type)}</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.paymentMethod}>
        <h4>Payment details</h4>
        {(basket && basket.payment_method) ? <PaymentMethod
          shipping={basket.payment_method.billing_details}
          card={basket.payment_method.card}
          vatId={basket.customer_vat_id}
        />: <Loading />}
      </div>
      <div className={styles.basketSummary}>
        <h4>Subtotal</h4>
        <Subtotal basket={basket} showSubscriptionChangeNoticification={basket.current_subscription && basket.payment_type == 'monthly'? true: false}/>
      </div>
      <div className={styles.termsAgreementContainer}>
        <input id='termsAgreement' className={styles.checkbox} type='checkbox' onChange={(event) => setTermsAgreed(event.target.checked)} checked={termsAgreed} />
        <label htmlFor='termsAgreement'>I agree to the <a href={urls.license(juceVersion)} rel='noreferrer' target='_blank'>JUCE {juceVersion} End User Licensing Agreement</a>.</label>
      </div>

      <ActionButton disabled={!termsAgreed} fullWidth={true} inverted={false} label='Complete order' onButtonClicked={handerConfirmBasket}/>
    </div>: <OrderSubmitting title='Placing order' text='Please do not refresh the browser until the loading is complete'/>}

  </div>;
};

ConfirmBasket.propTypes = {
  basket: PropTypes.object,
  plan: PropTypes.object,
  onConfirmBasket: PropTypes.func,
};

export default withStyles(styles)(ConfirmBasket);
