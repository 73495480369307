import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './TextInput.styl';

const TextInput = ({ darkMode, isSmall, className, hasError, ...props }) => {
  // mobx-react-form passes label as a prop, which we don't want added to an input field
  if (props.label) props.label = undefined;

  const inputClasses = classNames({
    [className]: className,
    [styles.input]: true,
    [styles.inputSmall]: isSmall,
    [styles.inputLight]: !darkMode,
    [styles.inputDark]: darkMode,
    [styles.inputLightError]: !darkMode && hasError,
    [styles.inputDarkError]: darkMode && hasError,
  });

  return (
    <input
      className={inputClasses}
      {...props}
    />
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  darkMode: PropTypes.bool,
  isSmall: PropTypes.bool,
  label: PropTypes.string,
};

export default withStyles(styles)(TextInput);
