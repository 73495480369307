import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { getEnvVar } from 'utils/envUtils';
import urls from 'constants/urls';


import VerifyChangeEmail from 'components/account/verifyEmail';

const apiHost = getEnvVar('JUCE_API_URL');
class VerifyChangeEmailPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    if (!this.props.match.params.token) {
      this.setState({error: 'Invalid email verification link'});
    } else {
      this.verifyEmailHandler(this.props.match.params.token);
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Verify Email');
  }

  verifyEmailHandler = async (token) => {
    let validateResponse = await fetch(`${apiHost}/api/v1/tokens/validate_change_email`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({token}),
      }
    );
    if (validateResponse.status == 200) {
      window.location = `${urls.dashboard}?updatedEmail=true`;
    } else if (validateResponse.status == 400) {
      this.setState({error: 'Invalid email verification code, please try again'});
    } else {
      this.setState({error: validateResponse.json()});
    }
  };

  render() {
    return <section>
      <VerifyChangeEmail error={this.state.error}/>
    </section>;
  }
}

VerifyChangeEmailPage.propTypes = {
  siteMetaStore: PropTypes.object,
  match: PropTypes.object,
};

export default inject('siteMetaStore')(observer(VerifyChangeEmailPage));
