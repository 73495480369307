import { observable, action, computed, makeObservable } from 'mobx';

import debounce from 'utils/debounce';
import { getCookie } from 'utils/cookies';
import { THROTTLE_DELAY, DEFAULT_IMAGE_REQUEST_WIDTH, SHOP_HIDDEN_BY_COOKIE } from 'constants/roli';

function nearestPowerOfTwo(x) {
  if (x === 0) return 1;

  x--;
  x |= x >> 1;
  x |= x >> 2;
  x |= x >> 4;
  x |= x >> 8;
  x |= x >> 16;

  return x + 1;
}

class SiteUIStore {

  NODE_ENV = '';
  runningOnServer = false;
  fixedNavigationHeight = 0;
  regionData = undefined;
  isMobileDevice = false;
  windowWidth = 0;
  windowHeight = 0;
  presentDismissable = false;
  loading = false;
  hideShop = SHOP_HIDDEN_BY_COOKIE ? getCookie('show_shop_functionality') === '' : false;

  resizeTimer = undefined;

  get shopifyIdFieldName() {
    return this.regionData ? this.regionData.shopifyIdFieldName : undefined;
  }

  get imageRequestWidth() {
    if (!this.windowWidth) return DEFAULT_IMAGE_REQUEST_WIDTH;

    return Math.min(1120, nearestPowerOfTwo(this.windowWidth));
  }

  constructor(preloadedData) {
    makeObservable(this, {
      NODE_ENV: observable,
      runningOnServer: observable,
      fixedNavigationHeight: observable,
      regionData: observable,
      isMobileDevice: observable,
      windowWidth: observable,
      windowHeight: observable,
      presentDismissable: observable,
      loading: observable,
      hideShop: observable,
      shopifyIdFieldName: computed,
      imageRequestWidth: computed,
      checkForMobileDevice: action('Check for mobile device'),
      setPresentDismissable: action.bound,
      setRegionData: action('Picking region data by regionName'),
      setLoading: action('Set Loading'),
      updateDimensions: action,
    });

    this.NODE_ENV = process.env.NODE_ENV;
    if (preloadedData) this.setRegionData(preloadedData.regionData);
    if (typeof window !== 'undefined') {
      this.checkForMobileDevice();
      this.createListeners();
      this.updateDimensions();
    }
  }

  checkForMobileDevice() {
    this.isMobileDevice = (typeof navigator !== 'undefined' && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  setPresentDismissable(value) {
    this.presentDismissable = value;
  }

  createListeners() {
    window.addEventListener('resize', this.onWindowResized);
  }

  setRegionData(regionData) {
    this.regionData = regionData;
  }

  setLoading(bool) {
    this.loading = bool;
  }

  updateDimensions = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = undefined;
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  };

  onWindowResized = debounce(() => {
    if (!this.resizeTimer) {
      this.resizeTimer = setTimeout(this.updateDimensions, 20);
    }
  }, THROTTLE_DELAY);
}

export default SiteUIStore;
