// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MiniNav_nav_1VTWl {\n  margin-bottom: 25px;\n}\n.MiniNav_navButton_1oh54 {\n  display: inline-block;\n  margin-right: 1rem;\n}\n", "",{"version":3,"sources":["MiniNav.styl"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,kBAAkB;AACpB","file":"MiniNav.styl","sourcesContent":[".nav {\n  margin-bottom: 25px;\n}\n.navButton {\n  display: inline-block;\n  margin-right: 1rem;\n}\n"]}]);
// Exports
exports.locals = {
	"nav": "MiniNav_nav_1VTWl",
	"navButton": "MiniNav_navButton_1oh54"
};
module.exports = exports;
