import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './MetaSidebarSection.styl';


const MetaSection = ({ children }) => {
  return (
    <section className={styles.metaSidebarSection}>
      {children}
    </section>
  );
};

MetaSection.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(MetaSection);
