import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import LockedNotification from 'components/account/lockedNotification';

class LockedNotificationPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Account Locked');
  }

  render() {
    return <LockedNotification />;
  }
}

LockedNotificationPage.propTypes = {
  siteMetaStore: PropTypes.objectOrObservableObject,
};

export default inject('siteMetaStore')(observer(LockedNotificationPage));
