// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OrderSubmitting_orderProcessing_3A_Fh {\n  text-align: center;\n}\n.OrderSubmitting_orderProcessing_3A_Fh p {\n  text-align: center;\n}\n.OrderSubmitting_orderProcessing_3A_Fh svg {\n  width: 100px;\n  height: 100px;\n  fill: #6cc04a;\n}\n.OrderSubmitting_loadingIcon_3a92V {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n", "",{"version":3,"sources":["OrderSubmitting.styl"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","file":"OrderSubmitting.styl","sourcesContent":[".orderProcessing {\n  text-align: center;\n}\n.orderProcessing p {\n  text-align: center;\n}\n.orderProcessing svg {\n  width: 100px;\n  height: 100px;\n  fill: #6cc04a;\n}\n.loadingIcon {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n"]}]);
// Exports
exports.locals = {
	"orderProcessing": "OrderSubmitting_orderProcessing_3A_Fh",
	"loadingIcon": "OrderSubmitting_loadingIcon_3a92V"
};
module.exports = exports;
