// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".user_profileWrapper_1nwJZ {\n  margin-left: 12px;\n  margin-right: 12px;\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["user.styl"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB","file":"user.styl","sourcesContent":[".profileWrapper {\n  margin-left: 12px;\n  margin-right: 12px;\n  margin-bottom: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"profileWrapper": "user_profileWrapper_1nwJZ"
};
module.exports = exports;
