import React from 'react';

const RegisterIcon = ({ ...props }) => (
  <svg width={32} height={32} viewBox='0 0 100 100' {...props}>
    <path d='M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50c27.6,0,50-22.4,50-50C100,22.4,77.6,0,50,0z M75,54.2H54.2V75 c0,2.3-1.9,4.2-4.2,4.2s-4.2-1.9-4.2-4.2V54.2H25c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2h20.8V25c0-2.3,1.9-4.2,4.2-4.2	s4.2,1.9,4.2,4.2v20.8H75c2.3,0,4.2,1.9,4.2,4.2C79.2,52.3,77.3,54.2,75,54.2z'></path>
  </svg>
);



export default RegisterIcon;
