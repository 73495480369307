import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';

import TextHeader from 'components/common/pageHeaders/TextHeader';
import FlexWrapper from 'components/wrappers/FlexWrapper';
import UserFunnel from 'components/shop/userFunnel';
import ShopClosed from 'components/shop/shopClosed';

class ProductRedirectPage extends Component {

  componentWillMount() {
    const { siteUIStore, shopStore, accountStore } = this.props;

    if (!siteUIStore.runningOnServer) {
      shopStore.getPlans();
      accountStore.loadAccountProfileData(true);
    }
  }

  render() {
    const { accountStore, siteUIStore } = this.props;

    return siteUIStore.hideShop ?
      <ShopClosed />
      : <section>
        <TextHeader
          title={'Get JUCE'}
          intro={''}
          inverted={false}
        />
        <FlexWrapper>
          {!accountStore.signedIn ? <UserFunnel planSlug={this.props.match.params.planSlug} /> : <Redirect to={`${this.props.match.params.planSlug}/basket`} />}
        </FlexWrapper>
      </section>;
  }
}

ProductRedirectPage.propTypes = {
  shopStore: PropTypes.object,
  accountStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  match: PropTypes.object,
};

export default inject('shopStore', 'accountStore', 'siteUIStore')(observer(ProductRedirectPage));
