import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import AccountUser from 'components/account/user';

class AccountUserContainer extends Component {

  componentWillMount() {
    const { accountStore, siteUIStore } = this.props;
    if (!siteUIStore.runningOnServer) {
      if (!accountStore.accountData) {
        accountStore.loadAccountProfileData();
      }
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Profile');
  }

  onUpdateUserHandler = (userUpdate) => {
    this.props.accountStore.loadUpdateUser(userUpdate);
  };

  onDeleteUserHandler = () => {
    this.props.accountStore.loadDeleteAccount();
  };

  render() {
    const { accountStore } = this.props;
    const profile = (accountStore.accountData) ? accountStore.accountData : undefined;
    const updateError = accountStore.updateError;

    return <section>
      <AccountUser updateError={updateError} onUpdateUser={this.onUpdateUserHandler} onDeleteAccount={this.onDeleteUserHandler} profile={profile}/>
    </section>;
  }
}

AccountUserContainer.propTypes = {
  accountStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
};

export default inject('accountStore', 'siteUIStore', 'siteMetaStore')(observer(AccountUserContainer));
