import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import LoadingModalComponent from 'components/common/modal/LoadingModal';
import ContentLoading from 'components/common/loading/ContentLoading';

class LoadingModal extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { siteUIStore } = this.props;

    return (
      <LoadingModalComponent
        isOpen={Boolean(siteUIStore.loading)}
        onRequestClose={()=>{}}
        hideHeader={true}
        transparent={true}
      >
        <ContentLoading />
        {siteUIStore.loading ? <p>{siteUIStore.loading}</p>: null}
      </LoadingModalComponent>
    );
  }
}

LoadingModal.propTypes = {
  siteUIStore: PropTypes.object,
};

export default inject('siteUIStore')(observer(LoadingModal));
