import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';

import { Serial, OldSerial } from './Serial';
import styles from './Serials.styl';


const Serials = ({serials, onActivateSerial, onUnregister}) => {
  if (!serials) {
    return <p>No serial numbers found, please contact support if this is incorrect.</p>;
  }

  const availableSerials = serials.filter((serial) => !serial.expired);

  const unavailableSerials = serials.map((serial) => {
    const oldSerials = serial.product_history.map((ph) => ({
      metadata: {
        expires_at: serial.metadata.expires_at,
        licence_type: ph.licence_type,
      },
      product: ph.product,
      status: `Upgraded to ${serial.product.name}`,
    }));
    if (serial.expired) {
      oldSerials.push(serial);
    }

    return oldSerials;
  }).flat();

  return <div>
    {availableSerials.length > 0 &&
      <div>
        <span className={styles.serialsTableHeading}>Available</span>
        <Table className={styles.serialsTable}>
          <Thead >
            <Tr>
              <Th></Th>
              <Th>Payment</Th>
              <Th>Type</Th>
              <Th>Version</Th>
              <Th>Owner</Th>
              <Th>Activated by</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {availableSerials.map((serial) => {
              return <Serial onActivateSerial={onActivateSerial} key={serial._id} onUnregister={onUnregister} serial={serial} />;
            })}
          </Tbody>
        </Table>
      </div>
    }
    {unavailableSerials.length > 0 &&
      <div>
        <span className={styles.serialsTableHeading}>Upgraded and expired</span>
        <Table className={styles.serialsTable}>
          <Thead >
            <Tr>
              <Th></Th>
              <Th>Payment</Th>
              <Th>Type</Th>
              <Th>Version</Th>
              <Th>Status</Th>
              <Th>Activated by</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {unavailableSerials.map((serial, index) => {
              return <OldSerial key={`old-serial-${index}`} onUnregister={onUnregister} serial={serial} />;
            })}
          </Tbody>
        </Table>
      </div>
    }
  </div>;
};

Serials.propTypes = {
  serials: PropTypes.array,
  onActivateSerial: PropTypes.func,
  onUnregister: PropTypes.func,
};

export default withStyles(styles)(Serials);
