import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {Redirect} from 'react-router-dom';

import TextHeader from 'components/common/pageHeaders/TextHeader';
import NewBasket from 'components/shop/baskets/new';
import FlexWrapper from 'components/wrappers/FlexWrapper';
import ContentLoading from 'components/common/loading/ContentLoading';
import urls from 'constants/urls';

class Basket extends Component {
  componentWillMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      this.props.shopStore.reset();
      this.props.shopStore.getPlans();
      this.props.accountStore.loadAccountCustomerData();
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Get JUCE');
  }

  render() {
    const { shopStore, accountStore } = this.props;
    const plans = (shopStore.plans) ? shopStore.plans : undefined;
    const plan = plans ? plans.filter(plan => (plan.list_in_shop  === true) && (plan.url_slug == this.props.match.params.planSlug))[0] : null;

    return <section>
      <TextHeader
        title={'Get JUCE'}
        intro='Choose the right plan for you'
        inverted={false}
      />
      { plan? <FlexWrapper>
        { this.props.accountStore.signedIn ? <NewBasket
          plan={plan}
          customer={accountStore.customerData}
          basketError={shopStore.basketError}
          basket={shopStore.basket}
          shopLoading={shopStore.loading}

          onChangeSeatQuantity={shopStore.changeSeatQuantity}
          onChangePaymentPlan={shopStore.changePaymentType}
          onSetPlanId={shopStore.setPlanId}
          onCreateBasket={shopStore.createBasket}
          onAttachPaymentMethodToBasket={shopStore.attachPaymentMethodToBasket}
          onChangeVatId={shopStore.changeCustomerVatId}
          onConfirmBasket={shopStore.confirmBasket}
          onReconfirmBasket={shopStore.reconfirmBasket}
          onTerminateBasket={shopStore.terminateBasket}
        /> : <Redirect to={urls.getJUCE}/> }
      </FlexWrapper>: <ContentLoading />}
    </section>;
  }
}

Basket.propTypes = {
  shopStore: PropTypes.object,
  accountStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
  match: PropTypes.object,
};

export default inject('shopStore', 'accountStore', 'siteUIStore', 'siteMetaStore')(observer(Basket));
