import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './RedeemCard.styl';
import AddIcon from 'components/common/icons/AddIcon';

import InfoCard from './InfoCard';

const RedeemCard = ({onClick}) => (
  <InfoCard onClick={onClick} className={styles.card}>
    <div className={styles.addIcon}>
      <AddIcon />
    </div>
    <h3 className={styles.cardTitle}>
      Activate a licence in this account
    </h3>
    <p>Click here to enter a serial number</p>
  </InfoCard>
);

RedeemCard.propTypes = {
  onClick: PropTypes.func,
};

export default withStyles(styles)(RedeemCard);
