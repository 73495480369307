import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './InfoCard.styl';

const InfoCard = ({ children, onClick = () => {}, className }) => {
  const infoCardClasses = classNames({
    [styles.infoCard]: true,
    [className]: className,
  });

  return (
    <div onClick={onClick} className={infoCardClasses}>
      <div className={styles.infoCardInner}>
        {children}
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default withStyles(styles)(InfoCard);
