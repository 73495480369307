// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SupportCard_card_2iyyy {\n  background: #288dc1;\n  color: #f2f2f2;\n  margin-left: 12px;\n  margin-right: 12px;\n  -webkit-box-flex: 1;\n  -webkit-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.SupportCard_cardTitle_15z2G {\n  font-weight: font-weight-bold;\n  color: #f2f2f2;\n}\n.SupportCard_cardMessage_3hgfC {\n  color: #f2f2f2;\n}\n.SupportCard_cardCta_2IZpa {\n  border-bottom: 1px solid #f2f2f2;\n  text-decoration: none;\n  -webkit-transition: opacity 0.1s linear;\n  transition: opacity 0.1s linear;\n}\n.SupportCard_cardCta_2IZpa:hover,\n.SupportCard_cardCta_2IZpa:focus {\n  opacity: 0.6;\n}\n", "",{"version":3,"sources":["SupportCard.styl"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,mBAAO;EAAP,eAAO;MAAP,WAAO;UAAP,OAAO;AACT;AACA;EACE,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gCAAgC;EAChC,qBAAqB;EACrB,uCAA+B;EAA/B,+BAA+B;AACjC;AACA;;EAEE,YAAY;AACd","file":"SupportCard.styl","sourcesContent":[".card {\n  background: #288dc1;\n  color: #f2f2f2;\n  margin-left: 12px;\n  margin-right: 12px;\n  flex: 1;\n}\n.cardTitle {\n  font-weight: font-weight-bold;\n  color: #f2f2f2;\n}\n.cardMessage {\n  color: #f2f2f2;\n}\n.cardCta {\n  border-bottom: 1px solid #f2f2f2;\n  text-decoration: none;\n  transition: opacity 0.1s linear;\n}\n.cardCta:hover,\n.cardCta:focus {\n  opacity: 0.6;\n}\n"]}]);
// Exports
exports.locals = {
	"card": "SupportCard_card_2iyyy",
	"cardTitle": "SupportCard_cardTitle_15z2G",
	"cardMessage": "SupportCard_cardMessage_3hgfC",
	"cardCta": "SupportCard_cardCta_2IZpa"
};
module.exports = exports;
