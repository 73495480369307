import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './OrderSubmitting.styl';
import ContentLoading from 'components/common/loading/ContentLoading';

const OrderSubmitting = ({title, text}) => {
  return  <div className={styles.orderProcessing}>
    <div className={styles.loadingIcon}>
      <ContentLoading />
    </div>
    <h3>{title}</h3>
    <p>{text}</p>
  </div>;
};

OrderSubmitting.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default withStyles(styles)(OrderSubmitting);
