import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import urls from 'constants/urls';
import styles from './SignIn.styl';

import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';
import LinkButton from 'components/common/buttons/LinkButton';
import JuceLogo from '../../../common/icons/JuceLogo';
import FlexWrapper from '../../../wrappers/FlexWrapper';
import Panel from '../../../sections/Panel';

const SignInForm = ({ onLoadSignIn, registerLink, verified, signInError }) => (
  <FlexWrapper>
    <Panel>
      <div className={styles.header}>
        <JuceLogo className={styles.juceIcon} />
        {verified && <div className={styles.verified}><span >Account verified</span></div>}
      </div>
      <form id='loginForm' className={styles.signInForm} onSubmit={(e) => {
        e.preventDefault();
      }}>
        <legend className={styles.formLegend}><span>Sign in to your JUCE account</span></legend>
        <fieldset>
          <div className={styles.formInput}>
            <label>Email or username</label>
            <TextInput id='email' placeholder='Enter your email address' className={styles.textInput} />
          </div>
          <div className={styles.formInput}>
            <label>Password</label>
            <TextInput id='password' placeholder='Enter your password' type='password' className={styles.textInput} />
          </div>
        </fieldset>
        <div className={styles.passwordHelp}>
          <a href={urls.forgotPassword}><span>Forgotten your password?</span></a>
        </div>
        {signInError && <div className={styles.loginError}><p>{signInError}</p></div>}
        <div className={styles.formFooter}>
          <ActionButton fullWidth inverted={false} onButtonClicked={(e) => {
            e.preventDefault();
            let password = document.getElementById('password').value;
            let email = document.getElementById('email').value;
            onLoadSignIn(email, password);
          }} label={'Sign In'} />
          {registerLink && <div className={styles.registerContainer}><p>or</p><LinkButton to={registerLink} inverted={false} fullWidth label='Register a new account' /></div>}
        </div>
        <div className={styles.validationHelp}>
          <a href={urls.requestValidation}><span>Validation email not arrived?</span></a>
        </div>
      </form>
    </Panel>
  </FlexWrapper>
);

SignInForm.propTypes = {
  signInError: PropTypes.object,
  verified: PropTypes.bool,
  onLoadSignIn: PropTypes.func,
  registerLink: PropTypes.string,
};

export default withStyles(styles)(SignInForm);
