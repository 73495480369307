import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, useStaticRendering } from 'mobx-react';
import { withCookies, Cookies } from 'react-cookie';

import SiteUIStore from 'stores/ui/SiteUIStore';
import SiteMetaStore from 'stores/domain/SiteMetaStore';
import ShopStore from 'stores/domain/ShopStore';
import AccountStore from 'stores/domain/AccountStore';

class StoreProvider extends Component {

  constructor(props) {
    super(props);
    const preloadedData = props.preloadedData;

    this.siteUIStore = new SiteUIStore();
    if (props.server) {
      this.siteUIStore.runningOnServer = props.server;
      // Stop MobX from getting too clever about reactions when running on the server
      useStaticRendering(true);
    }

    // Page stores
    this.siteMetaStore = new SiteMetaStore();
    this.accountStore = new AccountStore(this.siteMetaStore, this.siteUIStore, preloadedData);
    this.shopStore = new ShopStore(this.siteMetaStore, preloadedData);
  }

  render() {
    return (
      <Provider
        siteUIStore={this.siteUIStore}
        siteMetaStore={this.siteMetaStore}
        accountStore={this.accountStore}
        shopStore={this.shopStore}
      >
        <div>
          { this.props.children }
        </div>
      </Provider>
    );
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  server: PropTypes.bool,
  preloadedData: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  location: PropTypes.object,
};

export default withCookies(StoreProvider);
