export const elementInView = (element, runningOnServer) => {
  if (runningOnServer || !element) return null;

  const boundingBox = element.getBoundingClientRect();

  return (boundingBox.bottom > 0 && boundingBox.top < window.innerHeight);
};

export const elementTopInView = (element, runningOnServer) => {
  if (runningOnServer || !element) return null;

  const boundingBox = element.getBoundingClientRect();

  return (boundingBox.top > 0 && boundingBox.top < window.innerHeight);
};

export const elementBottomInView = (element, runningOnServer) => {
  if (runningOnServer || !element) return null;

  const boundingBox = element.getBoundingClientRect();

  return (boundingBox.bottom > 0 && boundingBox.bottom < window.innerHeight);
};

export const elementCenterInView = (element, runningOnServer) => {
  if (runningOnServer || !element) return null;

  const boundingBox = element.getBoundingClientRect();
  const boundingBoxMiddle = (boundingBox.top + boundingBox.height * 0.5);

  return (boundingBoxMiddle > 0 && boundingBoxMiddle < window.innerHeight);
};

export const scrollWindowToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};
