import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './LinkButton.styl';

import { makeRelative, isInternal } from 'utils/linkUtils';

const LinkButton = ({
  icon,
  label,
  to,
  target,
  inverted,
  light,
  dark,
  white,
  red,
  green,
  dummyButton,
  showHoverState,
  fullWidth,
}) => {
  const classes = classNames({
    [styles.button]: true,
    [styles.inverted]: inverted,
    [styles.light]: light,
    [styles.dark]: dark,
    [styles.white]: white,
    [styles.dummyButton]: dummyButton,
    [styles.red]: red,
    [styles.green]: green,
    [styles.hovered]: showHoverState,
    [styles.fullWidth]: fullWidth,
  });

  const internal = isInternal(to);

  const attrs = {
    className: classes,
    title: label,
  };

  let Component = Link;
  if (dummyButton) {
    Component = 'span';
  } else {
    if (internal) {
      attrs.to = makeRelative(to);
    } else {
      attrs.href = to;
      attrs.target = (target || '_blank');
      Component = 'a';
    }
  }

  return (
    <Component {...attrs}>
      {label}
      { icon && <figure className={styles.icon}>
        {icon}
      </figure> }
    </Component>
  );
};

LinkButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  target: PropTypes.string,
  inverted: PropTypes.bool,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  white: PropTypes.bool,
  red: PropTypes.bool,
  green: PropTypes.bool,
  dummyButton: PropTypes.bool,
  showHoverState: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

LinkButton.defaultProps = {
  inverted: true,
  white: false,
};

export default withStyles(styles)(LinkButton);
