import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './PaymentCard.styl';
import PaymentIconVisa from '../../shop/icons/PaymentIconVisa';
import PaymentIconMastercard from '../../shop/icons/PaymentIconMastercard';


const renderCardIcon = brand => {
  switch (brand) {
    case 'visa':
      return <PaymentIconVisa className={styles.cardIcon}/>;
    case 'mastercard':
      return <PaymentIconMastercard className={styles.cardIcon} />;
    default:
      return <div></div>;
  }
};

const PaymentCard = ({ card }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.cardDetail}>{renderCardIcon(card.brand)}</div>
      <div className={styles.cardDetail}><span><b>**** {card.last4}</b></span></div>
      <div className={styles.cardDetail}><span>expires: <b>{('0'+ card.exp_month).slice(-2)}/{String(card.exp_year).slice(-2)}</b></span></div>
    </div>
  );
};

PaymentCard.propTypes = {
  card: PropTypes.object,
};

export default withStyles(styles)(PaymentCard);
