import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';
import JuceLogo from 'components/common/icons/JuceLogo';
import FlexWrapper from 'components/wrappers/FlexWrapper';
import Panel from 'components/sections/Panel';

import styles from './ForgotPassword.styl';

const ForgotPasswordForm = ({ onForgotPassword, submittedForgotPassword}) => {

  return (<FlexWrapper>
    <Panel>
      <JuceLogo className={styles.juceIcon} />
      <p>Please enter the email address associated with your account and we will send you a reset link.</p>
      {!submittedForgotPassword && <form className={styles.forgotPasswordForm} onSubmit={(e) => {
        e.preventDefault();
        onForgotPassword(document.getElementById('email').value);
      }}>
        <legend className={styles.formLegend}><span>Enter your email adress</span></legend>
        <fieldset>
          <div className={styles.formInput}>
            <TextInput placeholder='Your email' id='email' className={styles.formTextInput} />
          </div>
        </fieldset>
        <div className={styles.formFooter}>
          <ActionButton blue fullWidth inverted={false} type='submit' onButtonClicked={()=>{}} label='Send password reset link' />
        </div>
      </form>}
      {submittedForgotPassword === false && <div className={styles.errorContainer}><p>Email address not recognised.</p></div>}
      {submittedForgotPassword && <div><p><b>Thanks, please check your email for a password reset link.</b></p></div>}

    </Panel>
  </FlexWrapper>);
};

ForgotPasswordForm.propTypes = {
  submittedForgotPassword: PropTypes.bool,
  onForgotPassword: PropTypes.func,
};

export default withStyles(styles)(ForgotPasswordForm);
