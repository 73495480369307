import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './AccountHasCreditNotice.styl';

import InfoCard from './InfoCard';

const AccountHasCreditNotice = () => (
  <InfoCard className={styles.card}>
    <h3 className={styles.cardTitle}>
      Your account has credit applied
    </h3>
    <p className={styles.cardMessage}>
      Please contact <a href='mailto:sales@juce.com'>sales@juce.com</a> to purchase a licence
    </p>
  </InfoCard>
);

export default withStyles(styles)(AccountHasCreditNotice);
