// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".userFunnel_funnelWrapper_E5Vib {\n  width: 100%;\n  text-align: center;\n}\n.userFunnel_buttonWrapper_eZ7PJ {\n  margin-top: 40px;\n}\n.userFunnel_funnelWrapper_E5Vib a {\n  margin-bottom: 20px;\n}\n.userFunnel_juceIcon_1J81- {\n  width: 100%;\n  height: 100px;\n  margin-bottom: 35px;\n}\n", "",{"version":3,"sources":["userFunnel.styl"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB","file":"userFunnel.styl","sourcesContent":[".funnelWrapper {\n  width: 100%;\n  text-align: center;\n}\n.buttonWrapper {\n  margin-top: 40px;\n}\n.funnelWrapper a {\n  margin-bottom: 20px;\n}\n.juceIcon {\n  width: 100%;\n  height: 100px;\n  margin-bottom: 35px;\n}\n"]}]);
// Exports
exports.locals = {
	"funnelWrapper": "userFunnel_funnelWrapper_E5Vib",
	"buttonWrapper": "userFunnel_buttonWrapper_eZ7PJ",
	"juceIcon": "userFunnel_juceIcon_1J81-"
};
module.exports = exports;
