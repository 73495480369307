import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import {Elements} from '@stripe/react-stripe-js';
import { stripePromise } from 'utils/stripeUtils';

import ActionButton from 'components/common/buttons/ActionButton';
import PaymentMethod from 'components/shop/paymentMethod';
import NewPaymentMethodForm from 'components/shop/newPaymentMethodForm';

import styles from './PaymentMethodSelection.styl';

const PaymentMethodSelection = ({
  useExistingLabel,
  useNewLabel,
  existingCustomerVatId,
  existingPaymentMethod,
  existingCustomerShipping,
  onPaymentIntent,
  onChangeVatId,
  showContinue,
  forceNew,
}) => {
  const [useExisting, setUseExisting] = React.useState(existingPaymentMethod);

  const changePaymentMethod = (e) => {
    if (e.target.value == 'existing') {
      setUseExisting(true);
    } else {
      setUseExisting(false);
    }
  };

  const useExistingPaymentMethod = () => {
    onPaymentIntent(existingPaymentMethod.id);
  };

  const renderChooseMethod = () => {
    return <form className={styles.billingForm}>
      {existingCustomerShipping && existingPaymentMethod && !forceNew && <div>
        <div className={styles.paymentMethodRadios}>
          <input type='radio' onChange={changePaymentMethod} id='existing' name='use_existing' value='existing' />
          <input type='radio' onChange={changePaymentMethod} id='new' name='use_existing' value='new' />
        </div>
        <div className={styles.paymentMethods}>
          <label className={useExisting? styles.paymentMethodActive : styles.paymentMethod}  htmlFor='existing'>
            <div className={styles.paymentTypeTitle}>{useExistingLabel}</div>
          </label>
          <label className={!useExisting? styles.paymentMethodActive : styles.paymentMethod} htmlFor='new'>
            <div>
              <div className={styles.paymentTypeTitle}>{useNewLabel}</div>
            </div>
          </label>
        </div>
      </div>}
    </form>;
  };

  const renderExistingMethod = () => {
    return <div className={styles.existingBillingDetails}>
      <PaymentMethod
        shipping={existingCustomerShipping}
        card={existingPaymentMethod.card}
        vatId={existingCustomerVatId}
      />
      {showContinue && <div className='checkoutButtons'>
        <ActionButton fullWidth={true} inverted={false} onButtonClicked={useExistingPaymentMethod} type='submit' label='Continue' />
      </div> }
    </div>;
  };

  const renderNewMethod = () => {
    return <Elements stripe={stripePromise}>
      <NewPaymentMethodForm
        buttonLabel='Add new payment details'
        onUpdatePaymentDetails={onPaymentIntent}
        onChangeVatId={onChangeVatId}
        customerVatId={existingCustomerVatId}
      />
    </Elements>;
  };

  return  <div>
    {renderChooseMethod()}
    {useExisting && !forceNew ? renderExistingMethod(): renderNewMethod()}
  </div>;
};

PaymentMethodSelection.propTypes = {
  useExistingLabel: PropTypes.string,
  useNewLabel: PropTypes.string,
  existingCustomerVatId: PropTypes.string,
  existingPaymentMethod: PropTypes.object,
  existingCustomerShipping: PropTypes.object,
  onPaymentIntent: PropTypes.func,
  onChangeVatId: PropTypes.func,
  showContinue: PropTypes.bool,
  forceNew: PropTypes.bool,
};

export default withStyles(styles)(PaymentMethodSelection);
