import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import urls from 'constants/urls';
import styles from './lockedNotification.styl';

import JuceLogo from 'components/common/icons/JuceLogo';
import FlexWrapper from '../../wrappers/FlexWrapper';
import LinkButton from '../../common/buttons/LinkButton';
import Panel from 'components/sections/Panel';

const LockedNotification = () => {

  return (<FlexWrapper>
    <Panel>
      <div className={styles.locked}>
        <JuceLogo className={styles.juceIcon} />
        <h3>Email verification required</h3>
        <p><b>You must verify your email address before you can login to JUCE.</b></p>
        <p>Please check your email inbox for a verification link to continue</p>
        <LinkButton fullWidth inverted={false} to={urls.requestValidation} label='Resend verification email'/>
      </div>
    </Panel>
  </FlexWrapper>);
};

export default withStyles(styles)(LockedNotification);
