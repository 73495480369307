// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OrderError_basketError_1-npg {\n  text-align: center;\n  margin-top: 50px;\n}\n.OrderError_basketError_1-npg p {\n  text-align: center;\n}\n.OrderError_basketError_1-npg svg {\n  width: 90px;\n  height: 90px;\n  fill: #6cc04a;\n  background: #e73e51;\n  border-radius: 50%;\n  padding: 20px;\n}\n.OrderError_message_3ocY4 {\n  text-align: center;\n}\n", "",{"version":3,"sources":["OrderError.styl"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,kBAAkB;AACpB","file":"OrderError.styl","sourcesContent":[".basketError {\n  text-align: center;\n  margin-top: 50px;\n}\n.basketError p {\n  text-align: center;\n}\n.basketError svg {\n  width: 90px;\n  height: 90px;\n  fill: #6cc04a;\n  background: #e73e51;\n  border-radius: 50%;\n  padding: 20px;\n}\n.message {\n  text-align: center;\n}\n"]}]);
// Exports
exports.locals = {
	"basketError": "OrderError_basketError_1-npg",
	"message": "OrderError_message_3ocY4"
};
module.exports = exports;
