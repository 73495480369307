// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Panel_panelWrapper_mxToA {\n  min-height: 100vh;\n  width: 100%;\n}\n.Panel_panel_3yKQf {\n  max-width: 400px;\n  width: 100%;\n  padding: 20px;\n  border-radius: 4px;\n  margin: 0px auto;\n  background: #fff;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n", "",{"version":3,"sources":["Panel.styl"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,8BAAsB;UAAtB,sBAAsB;AACxB","file":"Panel.styl","sourcesContent":[".panelWrapper {\n  min-height: 100vh;\n  width: 100%;\n}\n.panel {\n  max-width: 400px;\n  width: 100%;\n  padding: 20px;\n  border-radius: 4px;\n  margin: 0px auto;\n  background: #fff;\n  box-sizing: border-box;\n}\n"]}]);
// Exports
exports.locals = {
	"panelWrapper": "Panel_panelWrapper_mxToA",
	"panel": "Panel_panel_3yKQf"
};
module.exports = exports;
