// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".RequestValidation_header_GekQA {\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: row;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  text-align: center;\n  display: block;\n}\n.RequestValidation_juceIcon_2WiEs {\n  width: 100px;\n  height: 100px;\n  margin-bottom: 25px;\n}\n.RequestValidation_formTextInput_1QtYc {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-flex-basis: 100%;\n      -ms-flex-preferred-size: 100%;\n          flex-basis: 100%;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  width: 100%;\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["RequestValidation.styl"],"names":[],"mappings":"AAAA;EACE,8BAAmB;EAAnB,6BAAmB;EAAnB,2BAAmB;MAAnB,uBAAmB;UAAnB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,oBAAa;EAAb,qBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,wBAAgB;MAAhB,6BAAgB;UAAhB,gBAAgB;EAChB,8BAAsB;UAAtB,sBAAsB;EACtB,WAAW;EACX,mBAAmB;AACrB","file":"RequestValidation.styl","sourcesContent":[".header {\n  flex-direction: row;\n  text-align: center;\n  display: block;\n}\n.juceIcon {\n  width: 100px;\n  height: 100px;\n  margin-bottom: 25px;\n}\n.formTextInput {\n  display: flex;\n  flex-basis: 100%;\n  box-sizing: border-box;\n  width: 100%;\n  margin-bottom: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"header": "RequestValidation_header_GekQA",
	"juceIcon": "RequestValidation_juceIcon_2WiEs",
	"formTextInput": "RequestValidation_formTextInput_1QtYc"
};
module.exports = exports;
