import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import ActionButton from 'components/common/buttons/ActionButton';
import {scrollWindowToTop} from 'utils/scrollingUtils';
import EditSubscription from './steps/EditSubscription';
import ProrateSubtotal from './steps/ProrateSubtotal';
import ContentLoading from '../../common/loading/ContentLoading';
import OrderAuthroisation from './steps/OrderAuthroisation';
import OrderComplete from './steps/OrderComplete';
import OrderError from './steps/OrderError';
import OrderSubmitting from './steps/OrderSubmitting';
import { stripePromise } from 'utils/stripeUtils';

import styles from './basket.styl';

class EditSubscriptionBasket extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      basket_step: 0,
      ui_basket_step: 0,
      submitted: false,
    };
    this.basketContainer = React.createRef();
  }

  async componentDidMount() {
    this.stripe = await stripePromise;
  }

  static getDerivedStateFromProps(props) {
    if (props.basketError) {
      props.onTerminateBasket();

      return {
        basket_step: 2,
      };
    }

    return null;
  }

  confirmBasketHandler = () => {
    this.setState({ submitted: true });
    scrollWindowToTop();
    this.props.onConfirmBasket(() => {
      if (this.props.basket.status == 'authorisation_required') {
        this.addIframeListener();
        this.setState({ basket_step: 1 });
        this.stripe.confirmCardPayment(this.props.basket.payment_intent_client_secret, {
          payment_method: this.props.basket.payment_method_id,
        }).then(result => {
          if (result.error) {
            this.setState({ basket_step: 2 });
            this.props.onTerminateBasket();
          } else {
            if (result.paymentIntent && result.paymentIntent.status == 'succeeded') {
              this.on3DSComplete();
            } else {
              this.setState({ basket_step: 2 });
              this.props.onTerminateBasket();
            }
          }
        });
      } else if (this.props.basket.status === 'complete') {
        this.setState({
          basket_step: 3,
        });
      } else {
        this.setState({ basket_step: 2 });
        this.props.onTerminateBasket();
      }
      scrollWindowToTop();
    });
  };

  renderEditSubscription() {
    return <div>
      {!this.state.submitted ? <div>
        <EditSubscription
          plan={this.props.plan}
          isCurrentPlanAvailable={this.props.plan.list_in_shop}
          plans={this.props.plans}
          basket={this.props.basket}
          onChangeSeatQuantity={this.props.onChangeSeatQuantity}
          onSetPlanId={this.props.onSetPlanId}
        />
        {this.props.basket ? <div>
          <h3>Summary</h3>
          <ProrateSubtotal loading={this.props.shopLoading} basket={this.props.basket} showSubscriptionChangeNoticification={true} />
        </div> : null}
        <ActionButton disabled={this.props.basket.status == 'initial' || this.props.basket.invalid_seat_count || (this.props.basket.seat_quantity === 0)} fullWidth onButtonClicked={this.confirmBasketHandler} inverted={false} label='Save changes to subscription' />
      </div> : <OrderSubmitting title='Updating subscription' text='Please do not refresh the browser until complete' />}
    </div>;
  }

  renderStep = () => {
    switch (this.state.basket_step) {
      case 0:
        return this.renderEditSubscription();
      case 1:
        return <OrderAuthroisation />;
      case 2:
        return <OrderError text='Sorry, there was an issue when making a change to your subscription.' />;
      case 3:
        return <OrderComplete title='Subscription updated' />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div ref={this.basketContainer} className={styles.basketWrapper}>
        <div className={styles.basketContainer}>
          <div>
            {this.props.plan && this.props.basket ? this.renderStep() : <ContentLoading />}
          </div>
        </div>
      </div>
    );
  }
}

EditSubscriptionBasket.propTypes = {
  plan: PropTypes.object,
  plans: PropTypes.object,
  customer: PropTypes.object,
  basket: PropTypes.object,
  shopLoading: PropTypes.bool,
  onChangeVatId: PropTypes.func,
  onChangeSeatQuantity: PropTypes.func,
  onSetPlanId: PropTypes.func,
  onConfirmBasket: PropTypes.func,
  onTerminateBasket: PropTypes.func,
};

export default withStyles(styles)(EditSubscriptionBasket);
