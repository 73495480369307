import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import SignIn from 'components/forms/Account/SignIn';
import urls from 'constants/urls';


class SignInPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Sign In');
  }

  signedInHandler = () => {
    let queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.get('redirect')) {
      window.location = queryParams.get('redirect');
    } else {
      window.location = urls.dashboard;
    }
  };

  onLoadSignIn = (email, password) => {
    this.props.accountStore.loadSignIn(email, password, false, this.signedInHandler);
  };

  getVerifiedFlag = () => {
    const queryParams = new URLSearchParams(this.props.location.search);

    return queryParams.get('verified') !== null;
  };

  render() {
    const { accountStore } = this.props;
    const signInError = accountStore.signInError;
    const loading = accountStore.loading;

    return <section>
      <SignIn registerLink={urls.register} title={'Sign In'} verified={this.getVerifiedFlag()} signInError={signInError} loading={loading} onLoadSignIn={this.onLoadSignIn}/>
    </section>;
  }
}

SignInPage.propTypes = {
  siteMetaStore: PropTypes.object,
  accountStore: PropTypes.object,
  location: PropTypes.object,
};

export default inject('accountStore', 'siteMetaStore')(observer(SignInPage));
