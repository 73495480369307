import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import globalStyles from 'globalStyles';

import JuceLogo from 'components/common/icons/JuceLogo';
import CircularTickIcon from 'components/common/icons/CircularTickIcon';

import styles from './licence.styl';

const Licence = ({ licence }) => {

  return (
    <div className={styles.licence}>
      <div className={styles.licenceDetails}>
        <div className={styles.licenceImage}>
          <JuceLogo />
          <CircularTickIcon className={styles.tickIcon} />
        </div>
        <div className={styles.textColumn}>
          <dl className={styles.postDetails}>
            <div>
              <dt className={styles.productName}>{licence.product_name}</dt>
            </div>
            {licence.licence_type && <div>
              <dt>Licence type:</dt>
              <dd>{licence.licence_type.replace('-', ' ').replace('juce', 'JUCE')}</dd>
            </div>}
            <div>
              <dt>Status:</dt>
              <dd className={licence.status == 'active' ? styles.active: styles.unactive}>{licence.status}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

Licence.propTypes = {
  licence: PropTypes.object,
};

export default withStyles(globalStyles, styles)(Licence);
