import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import urls from 'constants/urls';
import Register from 'components/forms/Account/Register';
import FlexWrapper from 'components/wrappers/FlexWrapper';

class RegisterPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Register');
  }

  registeredHandler = () => {
    let queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.get('redirect')) {
      window.location = queryParams.get('redirect');
    } else {
      window.location = urls.dashboard;
    }
  };

  loadRegisterHandler = (newUser, turnstileToken) => {
    this.props.accountStore.loadRegister(newUser, turnstileToken, this.registeredHandler);
  };

  render() {
    const { accountStore } = this.props;
    const registerError = accountStore.registerError;
    const loading = accountStore.loading;

    return <section>
      <FlexWrapper>
        <Register title={'Register a JUCE account'} loading={loading} onLoadRegister={this.loadRegisterHandler} registerError={registerError} />
      </FlexWrapper>
    </section>;
  }
}

RegisterPage.propTypes = {
  siteMetaStore: PropTypes.object,
  accountStore: PropTypes.object,
  location: PropTypes.object,
};

export default inject('accountStore', 'siteMetaStore')(observer(RegisterPage));
