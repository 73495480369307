import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import globalStyles from 'globalStyles';

import {calculateSubscriptionMonthlyTotal} from 'utils/subscription';

import CalandarIcon from 'components/common/icons/CalandarIcon';
import CircularTickIcon from 'components/common/icons/CircularTickIcon';
import LinkButton from 'components/common/buttons/LinkButton';
import ActionButton from 'components/common/buttons/ActionButton';
import JuceModal from 'components/common/modal';
import urls from 'constants/urls';

import styles from './subscription.styl';


const Subscription = ({ subscription, onDeleteSubscription, hideShop, latestJuceVersion  }) => {
  const [cancelOpen, setCancelOpen] = useState(false);

  const handerDeleteSubscription = () => {
    onDeleteSubscription(subscription.id);
    setCancelOpen(false);
  };

  const closeCancelModal = () => {
    setCancelOpen(false);
  };

  const openCancelModal = () => {
    setCancelOpen(true);
  };

  const renderCancelModal = () => {
    return [<JuceModal
      key={`${subscription.id}modal`}
      isOpen={cancelOpen}
      onRequestClose={closeCancelModal}
      title='Cancel subscription'
    >
      <div>
        <p>Are you sure you would like to cancel this subscription? The serial numbers associated with this subscription will expire at the end of the billing cycle.</p>
        <ActionButton red fullWidth inverted={false} label='Yes, cancel this subscription' onButtonClicked={handerDeleteSubscription} />
      </div>
    </JuceModal>,
    <ActionButton key={`${subscription.id}cancel`} red onButtonClicked={openCancelModal} label='Cancel subscription' />,
    ];
  };

  const renderUpdateButton = () => {
    if (!subscription.plan.product.name.includes(latestJuceVersion)) {
      return <LinkButton dummyButton={subscription.status !== 'active'} fullWidth to={urls.editSubscription} green inverted={false} label={`Upgrade to JUCE ${latestJuceVersion}`} />;
    }

    return <LinkButton dummyButton={subscription.status !== 'active'} fullWidth to={urls.editSubscription} blue label='Update seats/type' />;
  };

  return (
    <div className={styles.subscription}>
      <div className={styles.subscriptionDetails}>
        <div className={styles.subscriptionImageContainer}>
          <div className={styles.subscriptionImage}>
            <CalandarIcon />
            <CircularTickIcon className={styles.tickIcon} />
          </div>
        </div>
        <div className={styles.textColumn}>
          <dl className={styles.postDetails}>
            <div>
              <dt className={styles.productName}>{subscription.plan.product.name}</dt>
            </div>
            <div>
              <dt>Started/Updated:</dt>
              <dd>{moment(subscription.created * 1000).format('ll')}</dd>
            </div>
            <div>
              <dt>Due date:</dt>
              <dd>{subscription.status == 'active'? moment(subscription.current_period_end * 1000).format('ll'): '-'}</dd>
            </div>
            <div>
              <dt>Seats:</dt>
              <dd>{subscription.quantity}</dd>
            </div>
            <div>
              <dt>Monthly total:</dt>
              <dd><b>${(calculateSubscriptionMonthlyTotal(subscription)/100).toFixed(2)}</b></dd>
            </div>
            <div>
              <dt>Status:</dt>
              <dd className={subscription.status == 'active' ? styles.active: styles.unactive}>{subscription.status}</dd>
            </div>
          </dl>
        </div>
      </div>
      {!hideShop && <div className={styles.buttons}>
        {renderUpdateButton()}
        <LinkButton fullWidth to={urls.editSubscriptionPaymentDetails} blue label='View/Update payment details' />
        {renderCancelModal()}
      </div>}
    </div>
  );
};

Subscription.propTypes = {
  subscription: PropTypes.object,
  onDeleteSubscription: PropTypes.func,
  hideShop: PropTypes.bool,
  latestJuceVersion: PropTypes.string,
};

export default withStyles(globalStyles, styles)(Subscription);
