import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import urls from 'constants/urls';
import styles from './InvalidSeatCountNotice.styl';

import InfoCard from './InfoCard';
import LinkButton from '../buttons/LinkButton';

const InvalidSeatCountNotice = () => (
  <InfoCard className={styles.card}>
    <h3 className={styles.cardTitle}>
      Check your requested seat count
    </h3>
    <p className={styles.cardMessage}>
      You currently have more serial numbers active that you have requested, please deactivate these first before reducing your required seat count.
    </p>
    <LinkButton green inverted={false} fullWidth label='Visit your dashboard' to={urls.dashboard} />
  </InfoCard>
);

export default withStyles(styles)(InvalidSeatCountNotice);
