import React from 'react';
import PropTypes from 'prop-types';

const PadlockIcon = (props) => (
  <svg  x='0px' y='0px' width='485.213px' {...props} height='485.212px' viewBox='0 0 485.213 485.212'>
    <g>
      <path d='M121.304,163.711v-42.407C121.304,54.434,175.723,0,242.606,0c66.902,0,121.302,54.434,121.302,121.304v42.407
        c-19.457-8.884-39.772-15.338-60.653-19.662v-22.745c0-33.465-27.153-60.653-60.648-60.653c-33.496,0-60.651,27.188-60.651,60.653
        v22.745C161.091,148.374,140.761,154.828,121.304,163.711z M394.235,265.823c0,105.845-64.588,194.011-151.629,219.39
        c-87.039-25.379-151.629-113.545-151.629-219.39c0-26.649,4.116-52.122,11.624-75.812c86.329-51.175,193.667-51.175,279.995,0
        C390.091,213.701,394.235,239.173,394.235,265.823z M261.62,296.033c6.753-5.567,11.312-13.684,11.312-23.103
        c0-16.764-13.562-30.324-30.326-30.324c-16.762,0-30.324,13.561-30.324,30.324c0,9.419,4.56,17.535,11.311,23.103l-11.311,67.873
        h60.65L261.62,296.033z'/>
    </g>
  </svg>
);

PadlockIcon.propTypes = {
  className: PropTypes.string,
};

export default PadlockIcon;
