// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".RequestValidationSent_juceIcon_1--cx {\n  width: 100%;\n  height: 100px;\n  margin-bottom: 35px;\n}\n", "",{"version":3,"sources":["RequestValidationSent.styl"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB","file":"RequestValidationSent.styl","sourcesContent":[".juceIcon {\n  width: 100%;\n  height: 100px;\n  margin-bottom: 35px;\n}\n"]}]);
// Exports
exports.locals = {
	"juceIcon": "RequestValidationSent_juceIcon_1--cx"
};
module.exports = exports;
