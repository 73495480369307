// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ConfirmBasket_confirmPayment_3a2nu p {\n  text-align: left;\n}\n.ConfirmBasket_basketProperties_3lLrg {\n  width: 100%;\n  background: #fff;\n  border: 1px solid #dcdcdc;\n  padding: 16px;\n  border-radius: 4px;\n}\n.ConfirmBasket_basketPropertiesHeader_3hCM4 {\n  font-weight: bold;\n  width: 150px;\n}\n.ConfirmBasket_basketProperties_3lLrg td,\ntr {\n  background: none;\n  padding: 0;\n}\n.ConfirmBasket_termsAgreementContainer_7qFvY {\n  margin-bottom: 20px;\n}\n.ConfirmBasket_termsAgreementContainer_7qFvY a {\n  text-decoration: underline;\n}\n.ConfirmBasket_checkbox_FAq_e {\n  margin-right: 7px;\n  -webkit-appearance: checkbox;\n}\n", "",{"version":3,"sources":["ConfirmBasket.styl"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;;EAEE,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,mBAAmB;AACrB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,iBAAiB;EACjB,4BAA4B;AAC9B","file":"ConfirmBasket.styl","sourcesContent":[".confirmPayment p {\n  text-align: left;\n}\n.basketProperties {\n  width: 100%;\n  background: #fff;\n  border: 1px solid #dcdcdc;\n  padding: 16px;\n  border-radius: 4px;\n}\n.basketPropertiesHeader {\n  font-weight: bold;\n  width: 150px;\n}\n.basketProperties td,\ntr {\n  background: none;\n  padding: 0;\n}\n.termsAgreementContainer {\n  margin-bottom: 20px;\n}\n.termsAgreementContainer a {\n  text-decoration: underline;\n}\n.checkbox {\n  margin-right: 7px;\n  -webkit-appearance: checkbox;\n}\n"]}]);
// Exports
exports.locals = {
	"confirmPayment": "ConfirmBasket_confirmPayment_3a2nu",
	"basketProperties": "ConfirmBasket_basketProperties_3lLrg",
	"basketPropertiesHeader": "ConfirmBasket_basketPropertiesHeader_3hCM4",
	"termsAgreementContainer": "ConfirmBasket_termsAgreementContainer_7qFvY",
	"checkbox": "ConfirmBasket_checkbox_FAq_e"
};
module.exports = exports;
