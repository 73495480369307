// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Subtotal_totalPrice_QNGGt {\n  font-weight: bold;\n  font-size: 150%;\n}\n.Subtotal_discountDescription_iVySK {\n  padding-left: 7px;\n}\n.Subtotal_taxNotice_23Hop {\n  margin-top: 10px;\n}\n.Subtotal_taxTerms_3d9Vq {\n  font-size: 50%;\n  margin-top: 10px;\n}\n.Subtotal_basketSummary_2DW0C {\n  background: #fff;\n  padding: 16px;\n  border: 1px solid #dcdcdc;\n  margin-bottom: 30px;\n  border-radius: 4px;\n}\n.Subtotal_subscriptionChange_1l3JM {\n  margin-top: 20px;\n}\n.Subtotal_chargeTitle_2h162 {\n  color: #288dc1;\n  font-weight: 800;\n}\n.Subtotal_summarySection_Pxv1L {\n  padding-bottom: 1.5rem;\n}\n", "",{"version":3,"sources":["Subtotal.styl"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB","file":"Subtotal.styl","sourcesContent":[".totalPrice {\n  font-weight: bold;\n  font-size: 150%;\n}\n.discountDescription {\n  padding-left: 7px;\n}\n.taxNotice {\n  margin-top: 10px;\n}\n.taxTerms {\n  font-size: 50%;\n  margin-top: 10px;\n}\n.basketSummary {\n  background: #fff;\n  padding: 16px;\n  border: 1px solid #dcdcdc;\n  margin-bottom: 30px;\n  border-radius: 4px;\n}\n.subscriptionChange {\n  margin-top: 20px;\n}\n.chargeTitle {\n  color: #288dc1;\n  font-weight: 800;\n}\n.summarySection {\n  padding-bottom: 1.5rem;\n}\n"]}]);
// Exports
exports.locals = {
	"totalPrice": "Subtotal_totalPrice_QNGGt",
	"discountDescription": "Subtotal_discountDescription_iVySK",
	"taxNotice": "Subtotal_taxNotice_23Hop",
	"taxTerms": "Subtotal_taxTerms_3d9Vq",
	"basketSummary": "Subtotal_basketSummary_2DW0C",
	"subscriptionChange": "Subtotal_subscriptionChange_1l3JM",
	"chargeTitle": "Subtotal_chargeTitle_2h162",
	"summarySection": "Subtotal_summarySection_Pxv1L"
};
module.exports = exports;
