import React from 'react';

import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './OrderAuthorisation.styl';
import ContentLoading from 'components/common/loading/ContentLoading';


const OrderAuthorisation = () => {
  return  <div>
    <div className={styles.basketSuccess}>
      <p>We need to perform additional authorisation for the card details supplied.</p>
      <p>Please follow the instructions provided to complete the order.</p>
      <ContentLoading />
    </div>
  </div>;
};


export default withStyles(styles)(OrderAuthorisation);
