import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './FlexWrapper.styl';


const FlexWrapper = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
};

FlexWrapper.propTypes = {
  children: PropTypes.node,
};

export default withStyles(styles)(FlexWrapper);
