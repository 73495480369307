import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import PaymentCard from 'components/common/cards/PaymentCard';
import styles from './paymentMethod.styl';

const PaymentMethod = ({ shipping, card, vatId }) => (
  <div className={styles.addressAndCard}>
    <div className={styles.customerAddress}>
      <span className={styles.detailHeader}>Organisation details</span>
      <div>
        <div>{shipping.name}</div>
        <div>{shipping.address.line1}</div>
        <div>{shipping.address.line2}</div>
        <div>{shipping.address.city}</div>
        <div>{shipping.address.state}</div>
        <div>{shipping.address.postal_code}</div>
        <div>{shipping.address.country}</div>
        {vatId && <div>VAT ID: {vatId}</div>}
      </div>
    </div>
    <div>
      <span className={styles.detailHeader}>Payment method</span>
      <PaymentCard card={card} />
    </div>
  </div>
);

PaymentMethod.propTypes = {
  shipping: PropTypes.object,
  card: PropTypes.object,
  vatId: PropTypes.string,
};

export default withStyles(styles)(PaymentMethod);



