import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Turnstile, { useTurnstile } from 'react-turnstile';

import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';
import { TURNSTILE_KEY } from 'constants/roli';

import styles from './Register.styl';

const RegisterForm = ({ onLoadRegister, registerError, title }) => {
  const turnstile = useTurnstile();
  let turnstileToken = null;
  if (turnstile) {
    turnstile.reset();
  }

  const fieldHasError = (fieldName) => {
    if (registerError && registerError.errors) {
      let foundErrors = registerError.errors.filter(error => error.field == fieldName);
      if (foundErrors.length) return foundErrors[0].message;
    }

    return false;
  };

  return <div className={styles.register}>
    <h2 className={styles.header}>{title}</h2>
    <form id='loginForm' className={styles.signInForm} onSubmit={(e) => {
      e.preventDefault();
    }}>
      <legend className={styles.formLegend}><span>Enter your login details</span></legend>

      <fieldset>
        <div><span className={styles.required}>* required</span></div>
        <div className={styles.formInput}>
          <label>First name *</label>
          <TextInput autoComplete='off' hasError={fieldHasError('first_name') ? true : false} id='first_name' placeholder='Your first name' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('first_name')}</span></div>
        </div>
        <div className={styles.formInput}>
          <label>Last name *</label>
          <TextInput autoComplete='off' hasError={fieldHasError('last_name') ? true : false} id='last_name' placeholder='Your last name' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('last_name')}</span></div>
        </div>
        <div className={styles.formInput}>
          <label>Organisation</label>
          <TextInput autoComplete='off' hasError={fieldHasError('organisation') ? true : false} id='organisation' placeholder='Your organisation (optional)' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('organisation')}</span></div>
        </div>
        <div className={styles.formInput}>
          <label>Username *</label>
          <TextInput autoComplete='off' hasError={fieldHasError('username') ? true : false} id='username' placeholder='Your new username' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('username')}</span></div>
        </div>
        <div className={styles.formInput}>
          <label>Email *</label>
          <TextInput autoComplete='off' hasError={fieldHasError('email') ? true : false} id='email' placeholder='your@email.com' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('email')}</span></div>
        </div>
        <div className={styles.formInput}>
          <label>Email confirmation *</label>
          <TextInput autoComplete='off' hasError={fieldHasError('email_confirmation') ? true : false} id='email_confirmation' placeholder='your@email.com again' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('email_confirmation')}</span></div>
        </div>
        <div className={styles.formInput}>
          <label>Password *</label>
          <TextInput id='password' hasError={fieldHasError('password') ? true : false} placeholder='Your password' type='password' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('password')}</span></div>
        </div>
        <div className={styles.formInput}>
          <label>Password confirmation *</label>
          <TextInput id='password_confirmation' hasError={fieldHasError('password_confirmation') ? true : false} placeholder='Your password again' type='password' className={styles.textInput} />
          <div className={styles.validationError}><span>{fieldHasError('password_confirmation')}</span></div>
        </div>
        <div>
          <input className={styles.checkbox} id='newsletter' type='checkbox' />
          <label htmlFor='newsletter'>Sign up for updates on the JUCE newsletter?</label>
        </div>
      </fieldset>
      <div className={styles.formFooter}>
        <Turnstile
          className={styles.turnstyle}
          sitekey={TURNSTILE_KEY}
          autoResetOnExpire={true}
          theme='light'
          onVerify={(token) => {
            turnstileToken = token;
          }}
        />
        <ActionButton fullWidth={true} inverted={false} onButtonClicked={async (e) => {
          e.preventDefault();
          let first_name = document.getElementById('first_name').value;
          let last_name = document.getElementById('last_name').value;
          let email = document.getElementById('email').value;
          let email_confirmation = document.getElementById('email_confirmation').value;
          let username = document.getElementById('username').value;
          let organisation = document.getElementById('organisation').value;
          let password = document.getElementById('password').value;
          let password_confirmation = document.getElementById('password_confirmation').value;
          let opt_in_newsletter = document.getElementById('newsletter').checked;
          let newUser = {
            first_name,
            last_name,
            organisation,
            username,
            email,
            email_confirmation,
            password,
            password_confirmation,
            opt_in_newsletter,
          };
          onLoadRegister(newUser, turnstileToken);
        }} label={'Register new account'} />
      </div>
    </form>
  </div>;
};

RegisterForm.propTypes = {
  onLoadRegister: PropTypes.func,
  registerError: PropTypes.object,
  title: PropTypes.string,
};

export default withStyles(styles)(RegisterForm);
