// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UserShouldModifySubscriptionNotice_card_2dF5D {\n  border: 1px solid #dcdcdc;\n  border-width: 1px;\n  background: #fff;\n  margin: 20px 0;\n  -webkit-box-flex: 1;\n  -webkit-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.UserShouldModifySubscriptionNotice_cardTitle_3huns {\n  font-weight: font-weight-bold;\n  color: #288dc1;\n}\n.UserShouldModifySubscriptionNotice_cardMessage_1iuky {\n  color: #000;\n}\n.UserShouldModifySubscriptionNotice_cardCta_qDSAR {\n  border-bottom: 1px solid #fff;\n  text-decoration: none;\n  -webkit-transition: opacity 0.1s linear;\n  transition: opacity 0.1s linear;\n}\n.UserShouldModifySubscriptionNotice_cardCta_qDSAR:hover,\n.UserShouldModifySubscriptionNotice_cardCta_qDSAR:focus {\n  opacity: 0.6;\n}\n.UserShouldModifySubscriptionNotice_linkButton_1MFKz {\n  width: 100%;\n}\n", "",{"version":3,"sources":["UserShouldModifySubscriptionNotice.styl"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,mBAAO;EAAP,eAAO;MAAP,WAAO;UAAP,OAAO;AACT;AACA;EACE,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,uCAA+B;EAA/B,+BAA+B;AACjC;AACA;;EAEE,YAAY;AACd;AACA;EACE,WAAW;AACb","file":"UserShouldModifySubscriptionNotice.styl","sourcesContent":[".card {\n  border: 1px solid #dcdcdc;\n  border-width: 1px;\n  background: #fff;\n  margin: 20px 0;\n  flex: 1;\n}\n.cardTitle {\n  font-weight: font-weight-bold;\n  color: #288dc1;\n}\n.cardMessage {\n  color: #000;\n}\n.cardCta {\n  border-bottom: 1px solid #fff;\n  text-decoration: none;\n  transition: opacity 0.1s linear;\n}\n.cardCta:hover,\n.cardCta:focus {\n  opacity: 0.6;\n}\n.linkButton {\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"card": "UserShouldModifySubscriptionNotice_card_2dF5D",
	"cardTitle": "UserShouldModifySubscriptionNotice_cardTitle_3huns",
	"cardMessage": "UserShouldModifySubscriptionNotice_cardMessage_1iuky",
	"cardCta": "UserShouldModifySubscriptionNotice_cardCta_qDSAR",
	"linkButton": "UserShouldModifySubscriptionNotice_linkButton_1MFKz"
};
module.exports = exports;
