import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './UpgradeCard.styl';

import InfoCard from './InfoCard';

import urls from 'constants/urls';

const UpgradeCard = ({ latestJuceVersion }) => (
  <InfoCard className={styles.card}>
    <h3 className={styles.cardTitle}>
      Upgrade to JUCE {latestJuceVersion}
    </h3>
    <p className={styles.cardMessage}>
    You are eligible for a 30% discount on perpetual licences
    </p>
    <div><a href={urls.getJuce} className={styles.cardCta}>
      Upgrade now
    </a></div>
  </InfoCard>
);

UpgradeCard.propTypes = {
  latestJuceVersion: PropTypes.string,
};

export default withStyles(styles)(UpgradeCard);
