import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import urls from 'constants/urls';

import SignIn from 'components/forms/Account/SignIn';

class SignInPage extends Component {

  onLoadSignIn = (email, password) => {
    if (this.sso && this.sig) {
      this.props.accountStore.loadSignInDiscourse(email, password, this.sso, this.sig, this.signedInHandler);
    }
  };

  componentDidMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      this.queryParams = new URLSearchParams(this.props.location.search);
      this.sso = this.queryParams.get('sso');
      this.sig = this.queryParams.get('sig');
      this.props.accountStore.loadSignInReauthenticateDiscourse(this.sso, this.sig, this.signedInHandler);
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Sign In');
  }

  render() {
    const { accountStore } = this.props;
    const signInError = accountStore.signInError;
    const loading = accountStore.loading;

    return <section>
      <SignIn registerLink={urls.register} title={'Sign In'} signInError={signInError} loading={loading} onLoadSignIn={this.onLoadSignIn}/>
    </section>;
  }
}

SignInPage.propTypes = {
  siteUIStore: PropTypes.object,
  accountStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
  location: PropTypes.object,
};

export default inject('accountStore', 'siteUIStore', 'siteMetaStore')(observer(SignInPage));
