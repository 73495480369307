import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import TextHeader from 'components/common/pageHeaders/TextHeader';
import ResetPassword from 'components/forms/Account/ResetPassword';

class ForgotPasswordPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Reset Password');
  }

  render() {
    const { accountStore, location } = this.props;
    const loadResetPassword = accountStore.loadResetPassword;
    const submittedForgotPassword = accountStore.submittedForgotPassword;
    const resetCode = new URLSearchParams(location.search).get('reset_token');

    return <section>
      <TextHeader title='Choose a new password' />
      {resetCode ? <ResetPassword resetCode={resetCode} submittedForgotPassword={submittedForgotPassword} onLoadResetPassword={(password)=>{loadResetPassword(resetCode, password );}}/>: <p>Sorry, there was a problem with the reset code</p>}
    </section>;
  }
}

ForgotPasswordPage.propTypes = {
  accountStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
  location: PropTypes.object,
};

export default inject('accountStore', 'siteMetaStore')(observer(ForgotPasswordPage));
