// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AccountHasCreditNotice_card_2Kx9Z {\n  border: 1px solid #dcdcdc;\n  border-width: 1px;\n  background: #fff;\n  margin: 20px 0;\n  -webkit-box-flex: 1;\n  -webkit-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.AccountHasCreditNotice_cardTitle_5BxYP {\n  font-weight: font-weight-bold;\n  color: #288dc1;\n}\n.AccountHasCreditNotice_cardMessage_3tNsN {\n  color: #000;\n}\n.AccountHasCreditNotice_cardMessage_3tNsN>a[href] {\n  color: #000;\n}\n", "",{"version":3,"sources":["AccountHasCreditNotice.styl"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,mBAAO;EAAP,eAAO;MAAP,WAAO;UAAP,OAAO;AACT;AACA;EACE,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb","file":"AccountHasCreditNotice.styl","sourcesContent":[".card {\n  border: 1px solid #dcdcdc;\n  border-width: 1px;\n  background: #fff;\n  margin: 20px 0;\n  flex: 1;\n}\n.cardTitle {\n  font-weight: font-weight-bold;\n  color: #288dc1;\n}\n.cardMessage {\n  color: #000;\n}\n.cardMessage>a[href] {\n  color: #000;\n}\n"]}]);
// Exports
exports.locals = {
	"card": "AccountHasCreditNotice_card_2Kx9Z",
	"cardTitle": "AccountHasCreditNotice_cardTitle_5BxYP",
	"cardMessage": "AccountHasCreditNotice_cardMessage_3tNsN"
};
module.exports = exports;
