import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import CircularTickIcon from 'components/common/icons/CircularTickIcon';
import LinkButton from 'components/common/buttons/LinkButton';
import urls from 'constants/urls';

import styles from './OrderComplete.styl';


const OrderComplete = ({title}) => {
  return  <div>
    <div className={styles.basketSuccess}>
      <CircularTickIcon />
      <h4>{title}</h4>
      <LinkButton inverted={false} green={true} to={urls.dashboard} label='Visit account dashboard' />
      <div className={styles.downloadLink}>
        <LinkButton inverted={false} green={true} to={urls.downloadLinks} label='Download JUCE' />
      </div>
    </div>
    <div>
    </div>
  </div>;
};

OrderComplete.propTypes = {
  title: PropTypes.string,
};

export default withStyles(styles)(OrderComplete);
