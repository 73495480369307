import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './DownloadCard.styl';
import InfoCard from './InfoCard';
import urls from 'constants/urls';

const DownloadCard = () => (
  <a href={urls.downloadLinks}>
    <InfoCard className={styles.card}>
      <h3 className={styles.cardTitle}>
        Download JUCE
      </h3>
      <p className={styles.cardMessage}>
        Select your development platform
      </p>
    </InfoCard>
  </a>
);

export default withStyles(styles)(DownloadCard);
