import ProductRedirectPage from 'containers/pages/Shop/ProductRedirectPage';
import SignIn from 'containers/pages/Account/SignIn';
import SignOut from 'containers/pages/Account/SignOut';
import SignInDiscourse from 'containers/pages/Account/SignInDiscourse';
import Register from 'containers/pages/Account/Register';
import ForgotPasswordPage from 'containers/pages/Account/ForgotPassword';
import ResetPasswordPage from 'containers/pages/Account/ResetPassword';
import RequestValidationPage from 'containers/pages/Account/RequestValidation';
import RequestValidationSentPage from 'containers/pages/Account/RequestValidationSent';
import AccountCharges from 'containers/pages/Account/Charges';
import PromptPasswordResetPage from 'containers/pages/Account/PromptPasswordReset';
import LockedNotification from 'containers/pages/Account/LockedNotification';
import AccountUser from './containers/pages/Account/User';
import AccountVerifyEmailPage from './containers/pages/Account/VerifyEmail';
import AccountVerifyChangeEmailPage from './containers/pages/Account/VerifyChangeEmail';
import Basket from './containers/pages/Shop/Basket';
import ConfirmBasket from './containers/pages/Shop/ConfirmBasket';
import EditSubscriptionBasket from './containers/pages/Shop/EditSubscription';
import EditSubscriptionPaymentBasket from './containers/pages/Shop/EditSubscriptionPayment';
import AccountDashboard from './containers/pages/Account/Dashboard';

export default [
  {
    path: '/account/dashboard',
    component: AccountDashboard,
    exact: true,
  },
  {
    path: '/account/profile',
    component: AccountUser,
    exact: true,
  },
  {
    path: '/account/subscription/edit',
    component: EditSubscriptionBasket,
    exact: true,
  },
  {
    path: '/account/subscription/edit/payment',
    component: EditSubscriptionPaymentBasket,
    exact: true,
  },
  {
    path: '/account/payments',
    component: AccountCharges,
    exact: true,
  },
  {
    path: '/verification/locked',
    component: LockedNotification,
    exact: true,
  },
  {
    path: '/verification/signin',
    component: SignIn,
    exact: true,
  },
  {
    path: '/verification/signout',
    component: SignOut,
    exact: true,
  },
  {
    path: '/verification/signin/discourse',
    component: SignInDiscourse,
    exact: true,
  },
  {
    path: '/verification/register',
    component: Register,
    exact: true,
  },
  {
    path: '/verification/password-reset-required',
    component: PromptPasswordResetPage,
    exact: true,
  },
  {
    path: '/verification/forgot-password',
    component: ForgotPasswordPage,
    exact: true,
  },
  {
    path: '/verification/request-validation',
    component: RequestValidationPage,
    exact: true,
  },
  {
    path: '/verification/request-validation/sent',
    component: RequestValidationSentPage,
    exact: true,
  },
  {
    path: '/verification/reset-password',
    component: ResetPasswordPage,
    exact: true,
  },
  {
    path: '/verification/verify-email/:token',
    component: AccountVerifyEmailPage,
    exact: true,
  },
  {
    path: '/verification/verify-change-email/:token',
    component: AccountVerifyChangeEmailPage,
    exact: true,
  },
  {
    path: '/verification/verify-email',
    component: AccountVerifyEmailPage,
    exact: true,
  },
  {
    path: '/get-juce/:planSlug',
    component: ProductRedirectPage,
    exact: true,
  },
  {
    path: '/get-juce/:planSlug/basket',
    component: Basket,
    exact: true,
  },
  {
    path: '/get-juce/basket/confirm',
    component: ConfirmBasket,
    exact: true,
  },
];
