import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import FlexWrapper from 'components/wrappers/FlexWrapper';
import MainSection from 'components/sections/MainSection';
import MetaSidebarSection from 'components/sections/MetaSidebarSection';
import SupportCard from 'components/common/cards/SupportCard';
import LearningCard from 'components/common/cards/LearningCard';
import DownloadCard from 'components/common/cards/DownloadCard';
import UserDetails from 'components/forms/Account/UserDetails';
import ContentLoading from 'components/common/loading/ContentLoading';
import MiniNav from 'components/common/MiniNav';

import styles from './user.styl';

const AccountUserComponent = ({ profile, onUpdateUser, onDeleteAccount, updateError }) => {
  return (
    <FlexWrapper>
      <MainSection>
        <div className={styles.profileWrapper}>
          {profile ? <div><MiniNav /><UserDetails updateError={updateError} onUpdateUser={onUpdateUser} onDeleteAccount={onDeleteAccount} profile={profile}/></div>: <ContentLoading />}
        </div>
      </MainSection>
      {profile? <MetaSidebarSection>
        <DownloadCard />
        <LearningCard />
        <SupportCard />
      </MetaSidebarSection>: null}
    </FlexWrapper>
  );
};

AccountUserComponent.propTypes = {
  profile: PropTypes.object,
  updateError: PropTypes.object,
  onUpdateUser: PropTypes.func,
  onDeleteAccount: PropTypes.func,
};

export default withStyles(styles)(AccountUserComponent);
