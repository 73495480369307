// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ProrateSubtotal_discountDescription_2D4-s {\n  font-weight: bold;\n}\n.ProrateSubtotal_basketSummary_3OzNX {\n  background: #fff;\n  padding: 16px;\n  border: 1px solid #dcdcdc;\n  margin-bottom: 30px;\n  border-radius: 4px;\n}\n.ProrateSubtotal_subscriptionChange_HF0rU {\n  margin-top: 20px;\n}\n.ProrateSubtotal_creditNotice_3UtRX {\n  margin: 20px 0;\n}\n.ProrateSubtotal_price_18bU9 {\n  font-size: 110%;\n  margin-bottom: 16px;\n  font-weight: 500;\n}\n.ProrateSubtotal_modificationTitle_2wgSI {\n  font-size: 130%;\n  font-weight: 900;\n  color: #288dc1;\n}\n.ProrateSubtotal_modificationTitle_2wgSI p {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["ProrateSubtotal.styl"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB","file":"ProrateSubtotal.styl","sourcesContent":[".discountDescription {\n  font-weight: bold;\n}\n.basketSummary {\n  background: #fff;\n  padding: 16px;\n  border: 1px solid #dcdcdc;\n  margin-bottom: 30px;\n  border-radius: 4px;\n}\n.subscriptionChange {\n  margin-top: 20px;\n}\n.creditNotice {\n  margin: 20px 0;\n}\n.price {\n  font-size: 110%;\n  margin-bottom: 16px;\n  font-weight: 500;\n}\n.modificationTitle {\n  font-size: 130%;\n  font-weight: 900;\n  color: #288dc1;\n}\n.modificationTitle p {\n  margin-bottom: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"discountDescription": "ProrateSubtotal_discountDescription_2D4-s",
	"basketSummary": "ProrateSubtotal_basketSummary_3OzNX",
	"subscriptionChange": "ProrateSubtotal_subscriptionChange_HF0rU",
	"creditNotice": "ProrateSubtotal_creditNotice_3UtRX",
	"price": "ProrateSubtotal_price_18bU9",
	"modificationTitle": "ProrateSubtotal_modificationTitle_2wgSI"
};
module.exports = exports;
