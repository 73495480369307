import { ACCOUNT_JUCE_COM_URL, ACCOUNT_JUCE_COM_HOSTNAME } from 'constants/roli';
import urlParse from 'url-parse';

export const makeRelative = (url) => {
  if (isInternal(url)) {
    const parsedUrl = urlParse(url);
    parsedUrl.set('protocol', '');
    parsedUrl.set('slashes', '');
    parsedUrl.set('hostname', '');
    parsedUrl.set('port', '');

    const relUrl = parsedUrl.toString();

    return relUrl ? relUrl : '/';
  }

  return url;
};

export const addBaseSitePrefix = (url) => (url.charAt(0) === '/') ? `${ACCOUNT_JUCE_COM_URL}${url}` : url;

export const isInternal = (url) => {
  const parsedUrl = urlParse(url);

  // Check that hostname _starts_ with roli.com in order to not match subdomains.
  // Also allow empty hostname for relative links being parsed server side.
  // Paths needing proxy handling are also exceptions.
  return parsedUrl.hostname.indexOf(ACCOUNT_JUCE_COM_HOSTNAME) === 0 || parsedUrl.hostname === '';
};
