import React  from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './Loading.styl';

const Loading = (props) => (
  <svg width={48} height={48} viewBox='7 7 86 86' {...props}>
    <rect x='0' y='0' width='100' height='100' fill='none'></rect>
    <circle cx='50' cy='50' r='40' stroke='none' fill='none' strokeWidth='10' strokeLinecap='round'></circle>
    <circle className={props.iconClassName || styles.iconClassName} cx='50' cy='50' r='40' fill='none' strokeWidth='6' strokeLinecap='round' visibility='hidden'>
      <set attributeName='visibility' to='visible' begin='0s' />
      <animate attributeName='opacity' dur='0.5s' repeatCount='1' begin='0s' from='0' to='1' fill='freeze'></animate>
      <animate attributeName='stroke-dashoffset' dur='2s' repeatCount='indefinite' from='0' to='502'></animate>
      <animate attributeName='stroke-dasharray' dur='2s' repeatCount='indefinite' values='150.6 100.4;1 250;150.6 100.4'></animate>
    </circle>
  </svg>
);

Loading.propTypes = {
  iconClassName: PropTypes.string,
};

export default withStyles(styles)(Loading);
