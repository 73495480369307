import { getEnvVar } from 'utils/envUtils';
import urlParse from 'url-parse';

// Links
export const JUCE_COM_URL = getEnvVar('JUCE_COM_URL') || 'https://juce.com';
export const JUCE_COM_HOSTNAME = urlParse(JUCE_COM_URL).hostname || 'juce.com';
export const ACCOUNT_JUCE_COM_URL = getEnvVar('ACCOUNT_JUCE_COM_URL') || 'https://account.juce.com';
export const ACCOUNT_JUCE_COM_HOSTNAME = urlParse(ACCOUNT_JUCE_COM_URL).hostname || 'account.juce.com';
export const JUCE_API_URL = getEnvVar('JUCE_API_URL') || 'https://api.juce.com';
export const DEPLOYMENT = getEnvVar('DEPLOYMENT') || 'production';
export const SHOP_HIDDEN_BY_COOKIE = Number(getEnvVar('SHOP_HIDDEN_BY_COOKIE')) === 1;
export const TURNSTILE_KEY = ACCOUNT_JUCE_COM_HOSTNAME.endsWith('juce.com.local') ? getEnvVar('TURNSTILE_KEY_LOCAL_DEV') : getEnvVar('TURNSTILE_KEY');

// __APP_VERSION__ is replaced by webpack
export const USE_ASSET_CDN = (getEnvVar('USE_ASSET_CDN') === '1');
export const ASSET_URL = USE_ASSET_CDN
  ? 'https://assets.juce.com/juce-com/__APP_VERSION__'
  : '/assets';

export const THROTTLE_DELAY = 100;

export const DEFAULT_IMAGE_REQUEST_WIDTH = 512;
