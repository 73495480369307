import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { decycle } from 'json-cycle';
import StyleContext from 'isomorphic-style-loader/StyleContext';

import App from './containers/App';

const css = new Set();
const insertCss = (...styles) => styles.forEach(stylesheet => css.add(stylesheet._insertCss()));

const preloadedData = window.__preloadedData__ ? JSON.stringify(decycle(window.__preloadedData__)) : undefined;
render(
  <StyleContext.Provider value={{ insertCss }}>
    <BrowserRouter location={window.location.pathname}>
      <CookiesProvider>
        <App preloadedData={preloadedData} />
      </CookiesProvider>
    </BrowserRouter>
  </StyleContext.Provider>,
  document.getElementById('root')
);
