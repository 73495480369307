import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './ActionButton.styl';

const ActionButton = ({ icon, label, disabled, type, onButtonClicked, inverted, light, dark, orange, blue, red, fullWidth, showHoverState }) => {
  const classes = classNames({
    [styles.button]: true,
    [styles.inverted]: inverted,
    [styles.light]: light,
    [styles.dark]: dark,
    [styles.orange]: orange,
    [styles.blue]: blue,
    [styles.red]: red,
    [styles.hovered]: showHoverState,
    [styles.fullWidth]: fullWidth,
  });

  return (
    <button
      className={classes}
      onClick={async (e) => {
        if (!disabled) await onButtonClicked(e);
      }}
      disabled={disabled}
      type={type}
    >
      {label}
      { icon && <span className={styles.icon}>
        {icon}
      </span> }
    </button>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onButtonClicked: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  orange: PropTypes.bool,
  blue: PropTypes.bool,
  red: PropTypes.bool,
  showHoverState: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

ActionButton.defaultProps = {
  inverted: true,
};

export default withStyles(styles)(ActionButton);
