import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './TextHeader.styl';

const TextHeader = ({ title, intro, inverted, headlessLayout }) => {
  const classes = classNames({
    [styles.textHeader]: true,
    [styles.inverted]: inverted,
    [styles.headlessLayout]: headlessLayout,
  });

  return (
    <div className={classes}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          {intro && <p className={styles.intro}>{intro}</p>}
        </div>
      </div>
    </div>
  );
};

TextHeader.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  inverted: PropTypes.bool,
  headlessLayout: PropTypes.bool,
};

export default withStyles(styles)(TextHeader);
