import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { getEnvVar } from 'utils/envUtils';
import ActionButton from 'components/common/buttons/ActionButton';

import styles from './updatePaymentMethodForm.styl';

const UpdatePaymentMethodForm = ({ returnUrl }) => {
  const [infoMessage, setInfoMessage] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setInfoMessage('Updating payment details. Please do not refresh the page. You may be redirected to authorise the change.');

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setInfoMessage(`${submitError.message} Please refresh the page and try again.`);

      return;
    }

    let clientSecret = '';
    try {
      const res = await fetch(`${getEnvVar('JUCE_API_URL')}/api/v1/basket/create_setup_intent`, {
        method: 'POST',
        credentials: 'include',
      });
      clientSecret = (await res.json()).client_secret;
    } catch (e) {
      setInfoMessage('Unable to setup payment method authorisation. Please refresh the page and try again. If this error persists contact JUCE support.');

      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${getEnvVar('JUCE_API_URL')}/api/v1/basket/apply_setup_intent?return_url=${encodeURIComponent(returnUrl)}`,
      },
    });

    if (error) {
      setInfoMessage(`${error.message} Refresh the page to enter different information.`);
    } else {
      // We have been redirected to `return_url`
    }
  };

  return (
    <div>
      <form className={infoMessage ? styles.hidden : styles.paymentDetailsForm} onSubmit={handleSubmit}>
        <div className={styles.stripeInput}>
          <PaymentElement />
        </div>
        <ActionButton inverted={false} type='submit' label={'Update payment details'} onButtonClicked={() => { }} disabled={!stripe} />
      </form>
      <span className={infoMessage ? styles.infoMessage : styles.hidden}>{infoMessage}</span>
    </div>
  );
};

UpdatePaymentMethodForm.propTypes = {
  returnUrl: PropTypes.string,
};

export default withStyles(styles)(UpdatePaymentMethodForm);
