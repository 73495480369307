import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';
import InvalidSeatCountNotice from 'components/common/cards/InvalidSeatCountNotice';
import UserShouldModifySubscriptionNotice from 'components/common/cards/UserShouldModifySubscriptionNotice';
import AccountHasCreditNotice from 'components/common/cards/AccountHasCreditNotice';
import InvalidDiscount from 'components/common/cards/InvalidDiscount';
import ContentLoading from 'components/common/loading/ContentLoading';
import Subtotal from './Subtotal';

import styles from './LicenceOptions.styl';

const LicenceOptions = ({
  shopLoading,
  plan,
  basket,
  onChangeSeatQuantity,
  onChangePaymentPlan,
  submitHandler,
  customer,
}) => {

  const [seatQuantity, setSeatQuanity] = React.useState(1);
  const [validSeatCount, setValidSeatCount] = React.useState(true);

  const changeSeatQuantityHandler = (e) => {
    let newValue = !e.currentTarget.value.includes('-') ? e.currentTarget.value : 1;

    setSeatQuanity(newValue);
    if (newValue)  {
      onChangeSeatQuantity(newValue);
      setValidSeatCount(true);
    } else {
      setValidSeatCount(false);
    }
  };

  const changePaymentPlanHandler = (e) => {
    onChangePaymentPlan(e.currentTarget.value);
  };

  const invalidSeatCount = () => {
    if (basket && basket.current_subscription && (basket.payment_type == 'monthly')) {
      if (basket.seat_quantity < basket.current_subscription.active_serial_numbers.length) {
        return true;
      }
    }

    return false;
  };

  const invalidDiscount = () => {
    if (basket && basket.seat_quantity && basket.total) {
      return (basket.seat_quantity > 0 && basket.upcoming_customer_invoice_items.reduce((total, item) => total + item.amount, basket.total.net) <= 0);
    }

    return false;
  };

  const renderSummary = () => {
    if (!basket) {
      return;
    }
    if (basket && basket.payment_type == 'monthly' && basket.current_subscription) {
      return <UserShouldModifySubscriptionNotice />;
    }
    if (customer && customer.balance && customer.balance !== 0) {
      return <AccountHasCreditNotice />;
    }

    return (
      <div>
        <div>
          <h4>Summary</h4>
          <Subtotal basket={basket} loading={shopLoading} />
        </div>
        <div className='checkoutButtons'>
          <RenderNextAction />
        </div>
      </div>
    );
  };

  const RenderNextAction = () => {
    if (invalidSeatCount()) {
      return <InvalidSeatCountNotice />;
    }
    if (invalidDiscount()) {
      return <InvalidDiscount />;
    }

    return <ActionButton fullWidth={true} className={styles.continueButton} disabled={!validSeatCount ||  (basket.seat_quantity < 1)} inverted={false} onButtonClicked={()=>{}} type='submit' label='Continue' />;
  };

  return  <div>
    <h2>{plan.long_label}</h2>
    {basket? <form onSubmit={submitHandler} className={styles.basketForm}>
      <div className={styles.quantity}>
        <h4>Choose number of seats</h4>
        <TextInput style={{
          display: 'inline-block',
        }} type='number' onChange={changeSeatQuantityHandler} id='base-numseats' min='1' max='1000' name='num_seats' required='required' value={seatQuantity} />
        <label htmlFor='base-numseats'> seats</label>
      </div>
      {basket && basket.invalid_seat_count ? <InvalidSeatCountNotice count={basket.current_subscription.active_serial_numbers.length}/>: null}
      <div>
        <h4>Choose your payment plan</h4>
        <input className={styles.radio} type='radio' onChange={changePaymentPlanHandler} id='monthly' name='billing_cycle' value='monthly' />
        <input className={styles.radio} type='radio' onChange={changePaymentPlanHandler} id='one_off' name='billing_cycle' value='one_off' />
        <div className={styles.paymentPlans}>
          <label className={basket.payment_type == 'monthly'? styles.cycleSelectionActive: styles.cycleSelection} htmlFor='monthly'>
            <div className={styles.paymentTypeTitle}>Monthly</div>
            <div>${(plan.monthly_price/100)} per seat / month</div>
          </label>
          <label className={basket.payment_type == 'one_off' ? styles.cycleSelectionActive: styles.cycleSelection} htmlFor='one_off'>
            <div>
              <div className={styles.paymentTypeTitle}>Single Payment</div>
              <div>${plan.one_off_price/100} per seat</div>
            </div>
          </label>
        </div>
      </div>
      {renderSummary()}
    </form>:<ContentLoading />}
  </div>;
};

LicenceOptions.propTypes = {
  shopLoading: PropTypes.bool,
  plan: PropTypes.object,
  basket: PropTypes.object,
  customer: PropTypes.object,
  onChangeSeatQuantity: PropTypes.func,
  onChangePaymentPlan: PropTypes.func,
  submitHandler: PropTypes.func,
};

export default withStyles(styles)(LicenceOptions);
