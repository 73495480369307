
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import {scrollWindowToTop} from 'utils/scrollingUtils';

import Steps from 'components/common/steps';
import LicenceOptions from './steps/LicenceOptions';
import PaymentMethodSelection from './steps/PaymentMethodSelection';
import ConfirmBasket from './steps/ConfirmBasket';
import OrderComplete from './steps/OrderComplete';
import ContentLoading from 'components/common/loading/ContentLoading';
import OrderAuthroisation from './steps/OrderAuthroisation';
import OrderError from './steps/OrderError';
import { stripePromise } from 'utils/stripeUtils';
import urls from 'constants/urls';

import styles from './basket.styl';

const basketSteps = [
  {title:'Licence options'},
  {title:'Billing details'},
  {title:'Confirm order'},
  {title:'Complete'},
];

class NewBasket extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      basket_step: 0,
      ui_basket_step: 0,
    };
    this.basketContainer = React.createRef();
  }

  async componentDidMount() {
    this.stripe = await stripePromise;
    this.props.onSetPlanId(this.props.plan._id);
    this.props.onCreateBasket();
  }

  static getDerivedStateFromProps(props) {
    if (props.basketError) {
      props.onTerminateBasket();

      return {
        basket_step: 5,
      };
    }

    return null;
  }

  addIframeListener = () => {
    window.addEventListener('message', (ev) => {
      if (ev.data === '3DS-authentication-complete') {
        this.on3DSComplete();
      }
    }, false);
  };

  submitLicenceOptionsFormHandler = (e) => {
    e.preventDefault();
    this.setState({
      basket_step: 1,
      ui_basket_step: 1,
    });
    scrollWindowToTop();
  };

  on3DSComplete = () => {
    this.props.onReconfirmBasket(() => {
      if (this.props.basket.status == 'complete') {
        this.setState({
          basket_step: 6,
          ui_basket_step: 3,
        });
      } else {
        this.setState({basket_step: 5});
        this.props.onTerminateBasket();
      }
    });
  };

  onPaymentIntentHandler = (paymentIntentId) => {
    this.props.onAttachPaymentMethodToBasket(paymentIntentId);
    this.setState({
      basket_step: 2,
      ui_basket_step: 2,
    });
    scrollWindowToTop();
  };

  confirmBasketHandler = () => {
    scrollWindowToTop();
    this.props.onConfirmBasket(()=>{
      if (this.props.basket.status == 'authorisation_required') {
        this.addIframeListener();
        this.setState({basket_step: 3});
        this.stripe.confirmCardPayment(this.props.basket.payment_intent_client_secret, {
          payment_method: this.props.basket.payment_method_id,
        }).then(result => {
          if (result.error) {
            this.setState({basket_step: 5});
            this.props.onTerminateBasket();
          } else {
            if (result.paymentIntent && result.paymentIntent.status == 'succeeded') {
              this.on3DSComplete();
            } else {
              this.setState({basket_step: 5});
              this.props.onTerminateBasket();
            }
          }
        });
      } else if (this.props.basket.status == 'complete') {
        this.setState({
          basket_step: 6,
          ui_basket_step: 3,
        });
      } else {
        this.setState({basket_step: 5});
        this.props.onTerminateBasket();
      }
      scrollWindowToTop();
    });
  };

  getCustomersExistingPaymentMethod = () => {
    if (!this.props.customer || !this.props.customer.default_payment_method) return null;

    return this.props.customer.default_payment_method;
  };

  getCustomersExistingShipping = () => {
    if (!this.props.customer || !this.props.customer.shipping) return null;

    return this.props.customer.shipping;
  };

  renderStep = () => {
    switch (this.state.basket_step) {
      case 0:
        return <LicenceOptions
          plan={this.props.plan}
          basket={this.props.basket}
          onChangeSeatQuantity={this.props.onChangeSeatQuantity}
          onChangePaymentPlan={this.props.onChangePaymentPlan}
          submitHandler={this.submitLicenceOptionsFormHandler}
          shopLoading={this.props.shopLoading}
          customer={this.props.customer}
        />;
      case 1:
        return <PaymentMethodSelection
          useExistingLabel='Use existing'
          useNewLabel='Enter new'
          existingCustomerVatId={this.props.basket.customer_vat_id}
          existingPaymentMethod={this.getCustomersExistingPaymentMethod()}
          existingCustomerShipping={this.getCustomersExistingShipping()}
          onPaymentIntent={this.onPaymentIntentHandler}
          onChangeVatId={this.props.onChangeVatId}
          forceNew={true}
          showContinue={true}
        />;
      case 2:
        return <ConfirmBasket plan={this.props.plan} basket={this.props.basket} onConfirmBasket={this.confirmBasketHandler} onModifyBasket={()=>{}}/>;
      case 3:
        return <OrderAuthroisation />;
      case 4:
        return <OrderAuthroisation />;
      case 5:
        return <OrderError text='Sorry, your order was unsuccessful.' buttonTo={urls.getJuce} buttonLabel='Try again' />;
      case 6:
        return <OrderComplete title='Order complete'/>;
      default:
        return null;
    }
  };

  render() {
    return (
      <div ref={this.basketContainer} className={styles.basketWrapper}>
        <div className={styles.basketContainer}>
          <Steps steps={basketSteps} activeStep={this.state.ui_basket_step} />
          <div>
            {this.props.plan ? this.renderStep(): <ContentLoading />}
          </div>
        </div>
      </div>
    );
  }
}

NewBasket.propTypes = {
  plan: PropTypes.object,
  customer: PropTypes.object,
  basket: PropTypes.object,
  shopLoading: PropTypes.bool,
  onConfirmBasket: PropTypes.func,
  onReconfirmBasket: PropTypes.func,
  onChangeVatId: PropTypes.func,
  onChangeSeatQuantity: PropTypes.func,
  onChangePaymentPlan: PropTypes.func,
  onSetPlanId: PropTypes.func,
  onCreateBasket: PropTypes.func,
  onAttachPaymentMethodToBasket: PropTypes.func,
  onTerminateBasket: PropTypes.func,
};

export default withStyles(styles)(NewBasket);
