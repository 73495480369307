import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import Loading from 'components/common/icons/Loading';

import styles from './ContentLoading.styl';

class ContentLoading extends Component {

  render() {

    return (
      <div className={styles.loadingWrapper}>
        <Loading className={styles.icon} />
      </div>
    );
  }
}

export default withStyles(styles)(ContentLoading);
