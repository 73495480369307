import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import urls from 'constants/urls';

import FlexWrapper from 'components/wrappers/FlexWrapper';
import Panel from 'components/sections/Panel';
import ContentLoading from 'components/common/loading/ContentLoading';
import LinkButton from 'components/common/buttons/LinkButton';
import CloseIcon from 'components/common/icons/CloseIcon';
import JuceLogo from 'components/common/icons/JuceLogo';

import styles from './verifyEmail.styl';

const VerifyEmail = ({ error}) => {

  const renderLoading = () => {
    return (<div>
      <JuceLogo className={styles.juceIcon} />
      <h3>Verifying your account...</h3>
      <ContentLoading />
    </div>
    );
  };

  const renderError = () => {
    return (<div>
      <CloseIcon className={styles.closeIcon} />
      <p>{error}</p>
      <LinkButton inverted={false} fullWidth to={urls.requestValidation} label='Request new validation link' />
    </div>);
  };

  return (<FlexWrapper>
    <Panel>
      <div className={styles.verifyWrapper}>
        {!error && renderLoading()}
        {error && renderError()}
      </div>
    </Panel>
  </FlexWrapper>);
};

VerifyEmail.propTypes = {
  error: PropTypes.string,
};

export default withStyles(styles)(VerifyEmail);
