import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';

import {validateVatId} from 'services/vatValidation.js';
import {isoCountries} from '../../../constants/shop';
import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';

import styles from './newPaymentMethodForm.styl';

const NewPaymentMethodForm = ({ onUpdatePaymentDetails, onChangeVatId, buttonLabel, customerVatId}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [country, setCountry] = React.useState('');

  const [formError, setFormError] = React.useState(false);

  const [vatId, setVatId] = React.useState(customerVatId);

  const parseStripeError = (error) => {
    if (error.param) {
      let errorField = error.param.replace('[address]','').replace('billing_details[','').replace(']', '');
      setFormError(errorField);
    }
  };

  const checkFormFields = () => {
    let error = false;
    if (!country) error = 'country';
    if (!state) error = 'state';
    if (!city) error = 'city';
    if (!address1) error = 'line1';
    if (!name) error = 'name';
    setFormError(error);

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError(false);

    let validVat = true;
    if (vatId) {
      let validateResponse = await validateVatId(vatId, name);
      if (!validateResponse.valid) {
        validVat = false;
        setFormError('vatId');
      } else {
        //attach tax code to customer
        onChangeVatId(vatId);
      }
    } else {
      onChangeVatId(null);
    }

    if (validVat && !checkFormFields()) {
      setFormError(false);

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: name,
          address: {
            line1: address1,
            line2: address2,
            city: city,
            state: state,
            country: country,
          },
        },
      });

      if (error) {
        parseStripeError(error);
      } else {
        onUpdatePaymentDetails(paymentMethod.id);
      }
    }
  };

  return (
    <form className={styles.paymentDetailsForm} onSubmit={handleSubmit}>
      <legend className={styles.formLegend}><span>Enter your address</span></legend>

      <fieldset>
        <div className={styles.required}>* required</div>
        <div className={styles.formInput}>
          <label>Name / Organisation *</label>
          <TextInput onChange={(e)=>setName(e.target.value)} hasError={formError == 'name'} name='name' value={name} id='name' className={styles.textInput} />
        </div>
        <div className={styles.formInput}>
          <label>Address line 1 *</label>
          <TextInput onChange={(e)=>setAddress1(e.target.value)} hasError={formError == 'line1'} name='address1' value={address1} className={styles.textInput} />
        </div>
        <div className={styles.formInput}>
          <label>Address line 2</label>
          <TextInput onChange={(e)=>setAddress2(e.target.value)} hasError={formError == 'line2'} name='address2' value={address2} className={styles.textInput} />
        </div>
        <div className={styles.formInput}>
          <label>City *</label>
          <TextInput onChange={(e)=>setCity(e.target.value)} hasError={formError == 'city'} name='city' value={city} className={styles.textInput} />
        </div>
        <div className={styles.formInput}>
          <label>State *</label>
          <TextInput onChange={(e)=>setState(e.target.value)} hasError={formError == 'state'} name='state' value={state} className={styles.textInput} />
        </div>
        <div className={styles.formInput}>
          <label>Country *</label>
          <select onChange={(e)=>{setCountry(e.target.value);}} style={{border: (formError == 'country')? '1px solid rgb(205, 52, 67)': '1px solid #e8e8e9'}} name='country' className={styles.selectField}>
            <option></option>
            {Object.keys(isoCountries).map(key => <option key={key} value={key}>{isoCountries[key]}</option>)}
          </select>
        </div>
        <div className={styles.formInput}>
          <label>EU VAT ID (European union only) {formError == 'vatId'?' (Please check the supplied ID. Contact support if the error persists.)':''}</label>
          <TextInput onChange={(e)=>setVatId(e.target.value)} hasError={formError == 'vatId'} name='vatId' value={vatId} className={styles.textInput} />
        </div>
      </fieldset>

      <fieldset>
        <div >
          <label>Credit/Debit card *</label>
          <div className={styles.stripeInput}>

            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    backgroundColor: 'white',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>

        </div>
      </fieldset>
      <ActionButton inverted={false} type='submit' label={buttonLabel} onButtonClicked={()=>{}} disabled={!stripe} />

    </form>
  );
};

NewPaymentMethodForm.propTypes = {
  onUpdatePaymentDetails: PropTypes.func,
  onChangeVatId: PropTypes.func,
  buttonLabel: PropTypes.string,
  customerVatId: PropTypes.string,
};

export default withStyles(styles)(NewPaymentMethodForm);
