import { observable, action, makeObservable } from 'mobx';

/**
 * Class that loads and stores meta data for the site
 * @class
 */
class SiteMetaStore {
  metadata = null;
  title = null;

  constructor() {
    makeObservable(this, {
      metadata: observable,
      title: observable,
      setPageMetadata: action('Set page metadata'),
    });
  }

  setPageMetadata(metadata, title) {
    this.metadata = metadata;
    this.title = title;
  }
}

export default SiteMetaStore;
