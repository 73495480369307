import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import PromptPasswordResetNotification from 'components/account/promptPasswordResetNotification';

class LockedNotificationPage extends Component {

  componentWillMount() {
    this.props.siteMetaStore.setPageMetadata(null, 'Account Locked');
  }

  render() {
    return <PromptPasswordResetNotification />;
  }
}

LockedNotificationPage.propTypes = {
  siteMetaStore: PropTypes.object,
};

export default inject('siteMetaStore')(observer(LockedNotificationPage));
