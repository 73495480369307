import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import FlexWrapper from 'components/wrappers/FlexWrapper';
import Loading from 'components/common/loading/ContentLoading';
import MainSection from 'components/sections/MainSection';
import MetaSidebarSection from 'components/sections/MetaSidebarSection';
import SupportCard from 'components/common/cards/SupportCard';
import LearningCard from 'components/common/cards/LearningCard';
import DownloadCard from 'components/common/cards/DownloadCard';
import ChargesTable from './chargesTable';
import MiniNav from 'components/common/MiniNav';

import styles from './charges.styl';

const AccountChargesComponent = ({ charges }) => {
  return (<FlexWrapper>
    <MainSection>
      {charges ? <div><MiniNav /><ChargesTable charges={charges} /></div>:<Loading />}
    </MainSection>
    {charges && <MetaSidebarSection>
      <DownloadCard />
      <LearningCard />
      <SupportCard />
    </MetaSidebarSection>}
  </FlexWrapper>);
};

AccountChargesComponent.propTypes = {
  charges: PropTypes.object,
};

export default withStyles(styles)(AccountChargesComponent);
