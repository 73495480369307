import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import {Link} from 'react-router-dom';
import styles from './EditSubscription.styl';

import TextInput from 'components/common/forms/TextInput';
import InvalidSeatCountNotice from 'components/common/cards/InvalidSeatCountNotice';

const EditSubscription = ({
  plan,
  isCurrentPlanAvailable,
  plans,
  basket,
  onChangeSeatQuantity,
  onSetPlanId,
}) => {

  const [seatQuantity, setSeatQuanity] = React.useState(basket.seat_quantity);

  const changeSeatQuantityHandler = (e) => {
    let newValue = !e.currentTarget.value.includes('-') ? e.currentTarget.value : 1;
    setSeatQuanity(newValue);
    onChangeSeatQuantity(newValue);
  };

  const changePlanHandler = (e) => {
    onSetPlanId(e.currentTarget.value);
  };

  const renderUpgradeNotice = () => {
    if (basket && basket.current_subscription && (basket.payment_type == 'monthly') && !hasExistingSelectedLicence()) {
      return <div>
        <p>When you are upgrading to a new version of JUCE you can only upgrade to the same number of seats, {basket.current_subscription.quantity}, or more.</p>
        <p>To reduce your seat count, please <Link to={`/account/subscription/${basket.current_subscription.id}`}>edit your subscription</Link>.</p>
      </div>;
    }
  };

  const hasExistingSelectedLicence = () => {
    if (basket && basket.payment_type == 'monthly') {
      if (basket.current_subscription) {
        if (basket.current_subscription.plan.id == plan.stripe_plan_id) {
          return true;
        }
      }
    }

    return false;
  };

  const renderCurrentSubscription = () => <div>
    <div className={styles.planNotAvailable}><p className={styles.planNotAvailableTitle}>Your current JUCE plan is no longer available for purchase.</p>
      <span>To modify your subscription. please select a new plan below.</span></div>
  </div>;

  const renderVolumeDiscount = () => <div className={styles.volumeDiscount}>
    <span>{basket.total.volumeDiscount.description} applied</span>
  </div>;

  const renderPaymentPlan = (plan, basket) => {

    return [
      <label key={`${plan._id}label`} className={plan._id == basket.plan._id ? styles.cycleSelectionActive: styles.cycleSelection} htmlFor={plan._id}>
        <div className={styles.paymentTypeTitle}>{plan.long_label}</div>
        <div>${(plan.monthly_price/100)} per seat / month</div>
      </label>,
      <input key={`${plan._id}input`} className={styles.radio} type='radio' onChange={changePlanHandler} id={plan._id} name='plan' value={plan._id} />,
    ];
  };

  return  <div>
    <form className={styles.basketForm}>
      {plan && renderUpgradeNotice()}
      {basket.invalid_seat_count ? <InvalidSeatCountNotice count={basket.current_subscription.active_serial_numbers.length}/>: null}
      {plans && basket && <div>
        <h4>Change your plan</h4>
        <p><b>Current Plan:</b> {plan.long_label}</p>
        {!isCurrentPlanAvailable && (basket.plan.stripe_plan_id == basket.current_subscription.plan.id)&&renderCurrentSubscription()}
        <div className={styles.paymentPlans}>
          {plans.filter(plan => plan.monthly_price && plan.list_in_shop).sort().map(plan => {
            return renderPaymentPlan(plan, basket);
          })}
        </div>
      </div>}
      <div className={styles.quantity}>
        <h4>Change number of seats</h4>
        <TextInput style={{
          display: 'inline-block',
        }} type='number' disabled={!isCurrentPlanAvailable && (basket.plan.stripe_plan_id == basket.current_subscription.plan.id)} onChange={changeSeatQuantityHandler} id='base-numseats' min='1' max='1000' name='num_seats' required='required' value={seatQuantity} />
        <label htmlFor='base-numseats'> seats</label>
        {basket.total.volumeDiscount && basket.total.volumeDiscount.discount_percent && renderVolumeDiscount()}
      </div>
      <div>
      </div>
    </form>
  </div>;
};

EditSubscription.propTypes = {
  plan: PropTypes.object,
  isCurrentPlanAvailable: PropTypes.bool,
  plans: PropTypes.object,
  basket: PropTypes.object,
  onChangeSeatQuantity: PropTypes.func,
  onSetPlanId: PropTypes.func,
};

export default withStyles(styles)(EditSubscription);
