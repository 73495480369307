import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import JuceLogo from 'components/common/icons/JuceLogo';
import FlexWrapper from 'components/wrappers/FlexWrapper';
import Panel from 'components/sections/Panel';

import styles from './RequestValidationSent.styl';

const RequestValidationSent = () => {
  return (<FlexWrapper>
    <Panel>
      <JuceLogo className={styles.juceIcon} />
      <p>Please check your email inbox for a verification link.</p>
    </Panel>
  </FlexWrapper>);
};

export default withStyles(styles)(RequestValidationSent);
