// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Serial_serialRow_3h8r3 {\n  background: #fff !important;\n  border: 1px solid #dcdcdc !important;\n  border-radius: 4px;\n  text-transform: capitalize;\n  margin-bottom: 5px;\n}\n.Serial_serialRow_3h8r3 span {\n  display: inline-block;\n}\n.Serial_registeredEmail_3A0pz {\n  text-transform: lowercase;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 200px;\n}\n.Serial_serialRow_3h8r3 svg {\n  width: 24px;\n  height: 24px;\n}\n.Serial_serialNumber_1FtkG {\n  font-size: 1.5rem;\n  font-weight: bold;\n  margin: 10px 0;\n  background: #dcdcdc;\n  border-radius: 4px;\n  padding: 5px;\n}\n.Serial_actionButton_2sieA {\n  background: #f00;\n  border: 1px solid #ff0;\n}\n.Serial_userDetails_2ULJy {\n  word-break: break-all;\n}\n", "",{"version":3,"sources":["Serial.styl"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,oCAAoC;EACpC,kBAAkB;EAClB,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,iBAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,qBAAqB;AACvB","file":"Serial.styl","sourcesContent":[".serialRow {\n  background: #fff !important;\n  border: 1px solid #dcdcdc !important;\n  border-radius: 4px;\n  text-transform: capitalize;\n  margin-bottom: 5px;\n}\n.serialRow span {\n  display: inline-block;\n}\n.registeredEmail {\n  text-transform: lowercase;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 200px;\n}\n.serialRow svg {\n  width: 24px;\n  height: 24px;\n}\n.serialNumber {\n  font-size: 24px;\n  font-weight: bold;\n  margin: 10px 0;\n  background: #dcdcdc;\n  border-radius: 4px;\n  padding: 5px;\n}\n.actionButton {\n  background: #f00;\n  border: 1px solid #ff0;\n}\n.userDetails {\n  word-break: break-all;\n}\n"]}]);
// Exports
exports.locals = {
	"serialRow": "Serial_serialRow_3h8r3",
	"registeredEmail": "Serial_registeredEmail_3A0pz",
	"serialNumber": "Serial_serialNumber_1FtkG",
	"actionButton": "Serial_actionButton_2sieA",
	"userDetails": "Serial_userDetails_2ULJy"
};
module.exports = exports;
