// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paymentMethod_addressAndCard_a54Wi {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 16px;\n  background: #fff;\n  padding: 16px;\n  -webkit-flex-wrap: wrap;\n      -ms-flex-wrap: wrap;\n          flex-wrap: wrap;\n  border: 1px solid #dcdcdc;\n  border-radius: 4px;\n}\n.paymentMethod_detailHeader_HaSeG {\n  font-weight: bold;\n  display: block;\n  margin-bottom: 10px;\n}\n.paymentMethod_customerAddress_pHk7- {\n  -webkit-flex-basis: 50%;\n      -ms-flex-preferred-size: 50%;\n          flex-basis: 50%;\n  -webkit-flex-shrink: 0;\n      -ms-flex-negative: 0;\n          flex-shrink: 0;\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["paymentMethod.styl"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,uBAAe;MAAf,mBAAe;UAAf,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,uBAAe;MAAf,4BAAe;UAAf,eAAe;EACf,sBAAc;MAAd,oBAAc;UAAd,cAAc;EACd,mBAAmB;AACrB","file":"paymentMethod.styl","sourcesContent":[".addressAndCard {\n  display: flex;\n  margin-bottom: 16px;\n  background: #fff;\n  padding: 16px;\n  flex-wrap: wrap;\n  border: 1px solid #dcdcdc;\n  border-radius: 4px;\n}\n.detailHeader {\n  font-weight: bold;\n  display: block;\n  margin-bottom: 10px;\n}\n.customerAddress {\n  flex-basis: 50%;\n  flex-shrink: 0;\n  margin-bottom: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"addressAndCard": "paymentMethod_addressAndCard_a54Wi",
	"detailHeader": "paymentMethod_detailHeader_HaSeG",
	"customerAddress": "paymentMethod_customerAddress_pHk7-"
};
module.exports = exports;
