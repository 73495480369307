import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import AccountCharges from 'components/account/charges';

class AccountChargesComponent extends Component {

  componentWillMount() {
    const { accountStore, siteUIStore } = this.props;
    if (!siteUIStore.runningOnServer) {
      if (!accountStore.accountData) {
        accountStore.loadAccountProfileData();
      }
      if (!accountStore.chargesData) {
        accountStore.loadAccountChargesData();
      }
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Payments');
  }

  render() {
    const { accountStore } = this.props;
    const profile = (accountStore.accountData) ? accountStore.accountData : undefined;
    const charges = (accountStore.chargesData) ? accountStore.chargesData : undefined;
    const loading = (accountStore.loading);

    return <section>
      <AccountCharges profile={profile} loading={loading} charges={charges}/>
    </section>;
  }
}

AccountChargesComponent.propTypes = {
  accountStore: PropTypes.object,
  siteUIStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
};

export default inject('accountStore', 'siteUIStore', 'siteMetaStore')(observer(AccountChargesComponent));
