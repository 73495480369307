import { JUCE_COM_URL } from 'constants/roli';

export default {
  dashboard: '/account/dashboard',
  profile: '/account/profile',
  payments: '/account/payments',
  editSubscription: '/account/subscription/edit',
  editSubscriptionPaymentDetails: '/account/subscription/edit/payment',

  signIn: '/verification/signin',
  signOut: '/verification/signout',
  signInDiscourse: '/verification/signin/discourse',
  register: '/verification/register',
  forgotPassword: '/verification/forgot-password',
  requestValidation: '/verification/request-validation',
  requestValidationSent: '/verification/request-validation/sent',
  resetPassword: '/verificatipn/reset-password',
  verifyEmail: '/verification/verify-email', //with /:token
  accountLocked: '/verification/locked',
  accountPasswordResetRequired: '/verification/password-reset-required',

  getJuce: `${JUCE_COM_URL}/get-juce`,
  downloadLinks: `${JUCE_COM_URL}/get-juce/download`,
  forum: 'https://forum.juce.com',
  tutorials: `${JUCE_COM_URL}/learn/tutorials`,

  license: (version) => `${JUCE_COM_URL}/legal/juce-${version}-licence`,
};
