import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';
import JuceLogo from 'components/common/icons/JuceLogo';
import FlexWrapper from 'components/wrappers/FlexWrapper';
import Panel from 'components/sections/Panel';

import styles from './RequestValidation.styl';

const ResetPasswordForm = ({ onRequestValidation }) => {

  return (<FlexWrapper>
    <Panel>
      <div className={styles.header}>
        <JuceLogo className={styles.juceIcon} />
      </div>
      <p>Please enter you email address below to resend the verification email with an activation link.</p>
      <form className={styles.forgotPasswordForm} onSubmit={(e) => {
        e.preventDefault();
        let email = document.getElementById('email').value;
        if(email) onRequestValidation(email);
      }}>
        <div className={styles.formInput}>
          <TextInput id='email' placeholder='Your email address' className={styles.formTextInput} />
        </div>
        <div className={styles.formFooter}>
          <ActionButton inverted={false} blue={true} fullWidth type='submit' onButtonClicked={()=>{}} label='Request Validation' />
        </div>
      </form>
    </Panel>
  </FlexWrapper>);
};

ResetPasswordForm.propTypes = {
  onRequestValidation: PropTypes.func,
};

export default withStyles(styles)(ResetPasswordForm);
