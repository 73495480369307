import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/withStyles';
import ContentLoading from 'components/common/loading/ContentLoading';
import styles from './Subtotal.styl';

const Subtotal = ({
  basket,
  loading = false,

  showSubscriptionChangeNoticification,
}) => {
  if (basket) {

    const totalWithUpcomingInvoiceItems = basket.upcoming_customer_invoice_items.reduce((total, item) => total + item.amount, basket.total.net)/100;
    const totalVAT = totalWithUpcomingInvoiceItems * basket.total.vatPercentage/100;

    const renderPendingCharges = (basket) => {
      if (basket.upcoming_customer_invoice_items.length > 0) {
        return <div className={styles.summarySection}>
          <p className={styles.chargeTitle}>Pending account charges:</p>
          <ol>
            {basket.upcoming_customer_invoice_items.map((prorationItem, index) => {
              if (prorationItem.proration) return <li key={`pi${index}`}><b>${(prorationItem.amount/100).toFixed(2)}</b> - {prorationItem.description}</li>;
            })}
          </ol>
          <p>Pending account charges will be included in this transaction.</p>
        </div>;
      }

      return null;
    };

    const renderTotal = (showTerm) => {
      return <div>
        <div className={styles.totalPrice}>
          <span>${(totalWithUpcomingInvoiceItems + totalVAT).toFixed(2)}</span>
        </div>
        {showTerm && <div className={styles.subtotalTerms}>
          {basket.payment_type == 'monthly'? ' per month': ' paid now'}
        </div>}
      </div>;
    };

    const renderNewCharges = (basket) => {
      const price = basket.payment_type === 'monthly' ? basket.plan.monthly_price : basket.plan.one_off_price;

      return <div className={styles.summarySection}>
        <p className={styles.chargeTitle}>New items:</p>
        <p>
          <span><b>${((basket.seat_quantity * price)/100).toFixed(2)}</b></span>
          <span className={styles.discountDescription}>- {basket.seat_quantity} x {basket.plan.long_label}</span>
        </p>
        {basket.total.upgradeUserDiscount ? <div>
          <span><b>{`- $${((basket.total.gross * (basket.total.upgradeUserDiscount.discount_percent/100))/100).toFixed(2)} `}</b></span>
          <span className={styles.discountDescription}> - {basket.total.upgradeUserDiscount.discount_percent}% {basket.total.upgradeUserDiscount.description}</span>
        </div>:null}
        {basket.total.volumeDiscount && basket.total.volumeDiscount.discount_percent ? <div>
          <span><b>{`- $${((basket.total.gross * (basket.total.volumeDiscount.discount_percent/100))/100).toFixed(2)}`}</b></span>
          <span className={styles.discountDescription}> - {basket.total.volumeDiscount.description}</span>
        </div>:null}
        {basket.total.customDiscounts.map((discount, index) => {
          return <div key={index}>
            <p>
              <span>{`- $${(discount.netDeduction/100).toFixed(2)}`} </span>
              <span className={styles.discountDescription}> - {discount.model.description}</span>
            </p>
          </div>;
        })}
      </div>;
    };

    return <div className={styles.basketSummary}>
      {!loading ? <div className={styles.subtotal}>
        {renderNewCharges(basket)}
        {renderPendingCharges(basket)}
        {basket.tax_rates && basket.tax_rates.length > 0 ? <div className={styles.taxNotice}>
          <span>{`$${totalVAT.toFixed(2)}`}</span><span> {basket.tax_rates[0].jurisdiction} VAT at {basket.tax_rates[0].percentage}%</span>
        </div>:null}
        {!showSubscriptionChangeNoticification ? renderTotal(true): null}
      </div>: <ContentLoading />}
    </div>;
  } else {
    return null;
  }

};

Subtotal.propTypes = {
  basket: PropTypes.object,
  loading: PropTypes.bool,
  showSubscriptionChangeNoticification: PropTypes.bool,
};

export default withStyles(styles)(Subtotal);
