import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import copy from 'copy-to-clipboard';
import {  Tr, Td } from 'react-super-responsive-table';
import moment from 'moment';

import ActionButton from 'components/common/buttons/ActionButton';
import JuceModal from 'components/common/modal/index';
import KeyIcon from 'components/common/icons/KeyIcon';

import styles from './Serial.styl';

moment.locale('en-gb');

const getPaymentTerm = (expiresAt) => expiresAt ? 'Subscription' : 'Perpetual';
const formatLicenceType = (licenceType) => licenceType.replace('juce', '').replace('-', '');

const OldSerial = withStyles(styles)(({ serial, onUnregister }) => {
  return <Tr className={styles.serialRow}>
    <Td><KeyIcon /></Td>
    <Td><span>{getPaymentTerm(serial.metadata.expires_at)}</span></Td>
    <Td><span>{serial.metadata.licence_type ? formatLicenceType(serial.metadata.licence_type) : '-'}</span></Td>
    <Td><span>{serial.product.version}</span></Td>
    <Td><span>{serial.status ? serial.status : `Expired ${moment(serial.metadata.expires_at).format('ll')}`}</span></Td>
    <Td>{serial.registered_user ? <span className={styles.registeredEmail} title={serial.registered_user.user.email}>{serial.registered_user.user.email}</span> : '-'}</Td>
    {serial.registered_user && serial.serial_number && <Td><ActionButton red key={`revoke-${serial.serial_number}`} label='Revoke' fullWidth={true} onButtonClicked={() => onUnregister(serial.serial_number)} /></Td>}
  </Tr>;
});

const Serial = withStyles(styles)(({serial, onActivateSerial, onUnregister}) => {

  const [revokeIsOpen, setRevokeIsOpen] = useState(false);
  const [issueIsOpen, setIssueIsOpen] = useState(false);

  const unregister = () => {
    onUnregister(serial.serial_number);
    setRevokeIsOpen(false);
  };

  function openRevokeModal() {
    setRevokeIsOpen(true);
  }

  function closeRevokeModal() {
    setRevokeIsOpen(false);
  }

  function openIssueModal() {
    setIssueIsOpen(true);
  }
  function closeIssueModal() {
    setIssueIsOpen(false);
  }

  const renderRevokeModal = () => {
    return [
      <ActionButton red key={'revokeSerialButton'} label='Revoke' fullWidth={true} onButtonClicked={openRevokeModal}  />,
      <JuceModal key={'revokeSerialModak'} isOpen={revokeIsOpen} title='Revoke serial' onRequestClose={closeRevokeModal}>
        <p>Are you sure you would like to revoke the activated serial number for the following user?</p>
        <br />
        <p className={styles.userDetails}><b>{serial.registered_user.user.first_name} {serial.registered_user.user.last_name}<br /> {serial.registered_user.user.email}</b></p>
        <ActionButton fullWidth red label='Yes, revoke' onButtonClicked={unregister} inverted={false} />
      </JuceModal>,
    ];
  };

  const activateSerialHandler = () => {
    closeIssueModal();
    onActivateSerial(serial.serial_number);
  };

  const copySerialToClipboard = () => {
    copy(serial.serial_number, {
      message: 'Copied to clipboard',
      format: 'text/plain',
    });
    closeIssueModal();
  };

  const renderIssueModal = () => {
    return [
      <ActionButton  key={'isueSerialButton'} label='Redeem' fullWidth={true} onButtonClicked={openIssueModal}  />,
      <JuceModal key={'issueSerialModal'} isOpen={issueIsOpen} title='Issue serial' onRequestClose={closeIssueModal}>
        <p>Serial number:</p>
        <pre className={styles.serialNumber}>{serial.serial_number}</pre>
        <ActionButton fullWidth label='Copy to clipboard' onButtonClicked={copySerialToClipboard} inverted={false} />
        <ActionButton fullWidth label='Activate in this account' onButtonClicked={activateSerialHandler} inverted={false} />
      </JuceModal>,
    ];
  };

  const getActionButton = () => {
    if (serial.product.version > 4) {
      if (serial.registered_user && serial.registered_user.registered_date) {
        return renderRevokeModal();
      }

      return renderIssueModal();
    }

    return null;
  };

  return <Tr className={styles.serialRow}>
    <Td><KeyIcon /></Td>
    <Td><span>{getPaymentTerm(serial.metadata.expires_at)}</span></Td>
    <Td><span>{serial.metadata.licence_type ? formatLicenceType(serial.metadata.licence_type) : '-'}</span></Td>
    <Td><span>{serial.product.version}</span></Td>
    <Td><span className={styles.registeredEmail} title={serial.metadata.buyer_email}>{serial.metadata.buyer_email}</span></Td>
    <Td>{serial.registered_user ? <span className={styles.registeredEmail} title={serial.registered_user.user.email}>{serial.registered_user.user.email}</span>:'-'}</Td>
    <Td className='serialButton'>{getActionButton()}</Td>
  </Tr>;
});

OldSerial.propTypes = {
  serial: PropTypes.object,
  onUnregister: PropTypes.func,
};

Serial.propTypes = {
  serial: PropTypes.object,
  onActivateSerial: PropTypes.func,
  onUnregister: PropTypes.func,
};

export {
  Serial,
  OldSerial,
};
