import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Stepper from 'react-stepper-horizontal';

import styles from './steps.styl';

const ShopSteps = ({ steps, activeStep }) => (
  <div className={styles.stepsWrapper}>
    <Stepper
      steps={steps}
      activeStep={activeStep}
      circleTop={0}
      completeTitleColor={'#67c057'}
      completeBarColor={'#67c057'}
      completeColor={'#67c057'}
      activeColor={'#67c057'}
    />
  </div>
);

ShopSteps.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
};

export default withStyles(styles)(ShopSteps);
