import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import urls from 'constants/urls';
import styles from './UserShouldModifySubscriptionNotice.styl';

import InfoCard from './InfoCard';
import LinkButton from 'components/common/buttons/LinkButton';

const UserShouldModifySubscriptionNotice = () => (
  <InfoCard className={styles.card}>
    <h3 className={styles.cardTitle}>
      You have an active subscription
    </h3>
    <p className={styles.cardMessage}>
      Please make changes to you current subscription in your account dashboard
    </p>
    <div className={styles.linkButton}>
      <LinkButton inverted={false} green label='Edit current subscription' to={urls.editSubscription} />
    </div>

  </InfoCard>
);

export default withStyles(styles)(UserShouldModifySubscriptionNotice);
