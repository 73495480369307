import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import TextHeader from 'components/common/pageHeaders/TextHeader';
import EditSubscriptionBasket from 'components/shop/baskets/editSubscription';
import FlexWrapper from 'components/wrappers/FlexWrapper';
import ContentLoading from 'components/common/loading/ContentLoading';

class EditSubscriptionPage extends Component {


  componentWillMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      this.props.accountStore.loadAccountProfileData();
      this.props.shopStore.reset();
      this.props.shopStore.getPlans();
      this.props.accountStore.loadAccountCustomerData();
      this.props.shopStore.createCurrentSubscriptionBasket();
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Edit Subscription');
  }

  render() {
    const { shopStore } = this.props;
    const plans = (shopStore.plans) ? shopStore.plans : undefined;
    const plan = shopStore.basket && plans ? plans.filter(plan => plan.stripe_plan_id == shopStore.basket.current_subscription.plan.id)[0]:null;

    return <section>
      <TextHeader
        title={'Edit your subscription'}
        inverted={false}
      />
      <FlexWrapper>
        { plan && this.props.accountStore.signedIn ? <EditSubscriptionBasket
          plan={plan}
          plans={plans}
          basket={shopStore.basket}
          shopLoading={shopStore.loading}
          onChangeSeatQuantity={shopStore.changeSeatQuantity}
          onSetPlanId={shopStore.changePlan}
          onChangeVatId={shopStore.changeVatId}
          onConfirmBasket={shopStore.confirmBasket}
          onTerminateBasket={shopStore.terminateBasket}
        /> : <ContentLoading />
        }
      </FlexWrapper>
    </section>;
  }
}

EditSubscriptionPage.propTypes = {
  siteUIStore: PropTypes.object,
  shopStore: PropTypes.object,
  accountStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
};

export default inject('shopStore', 'accountStore', 'siteUIStore', 'siteMetaStore')(observer(EditSubscriptionPage));
