import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import JuceModal from 'components/common/modal';
import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';
import Loading from 'components/common/loading/ContentLoading';

import styles from './user.styl';

const AccountForm = ({ profile, onDeleteAccount, onUpdateUser, updateError}) => {

  const [deleteAccountIsOpen, setDeleteAccountIsOpen] = useState(false);
  const [changePasswordIsOpen, setChangePasswordIsOpen] = useState(false);
  const [profileObject, setProfileObject] = useState(Object.assign({}, profile));
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const [deleteUserEmail, setDeleteUserEmail] = useState('');

  const deleteAccount = () => {
    onDeleteAccount();
    setDeleteAccountIsOpen(false);
  };

  const changePassword = async () => {
    try {
      await onUpdateUser({
        password: oldPassword,
        new_password: newPassword,
      });
      setChangePasswordIsOpen(false);
    } catch(e) {
      // TODO
    }
  };

  const fieldHasError = (fieldName) => {
    if (updateError && updateError.errors) {
      let foundErrors = updateError.errors.filter(error => error.field === fieldName);
      if (foundErrors.length) return foundErrors[0].message;
    }

    return false;
  };

  function openDeleteAccountModal() {
    setDeleteUserEmail(null);
    setDeleteAccountIsOpen(true);
  }
  function closeDeleteAccountModal() {

    setDeleteAccountIsOpen(false);
  }

  function openChangePasswordModal() {
    setNewPassword('');
    setOldPassword('');
    setNewPasswordConfirmation('');
    setChangePasswordIsOpen(true);
  }
  function closeChangePasswordModal() {
    setChangePasswordIsOpen(false);
  }

  const renderChangePasswordModal = () => {
    const changeOldPassword = (e) => {
      setOldPassword(e.target.value);
    };
    const changeNewPassword = (e) => {
      setNewPassword(e.target.value);
    };
    const changeNewPasswordConfirmation = (e) => {
      setNewPasswordConfirmation(e.target.value);
    };
    const isValidChange = () => {
      if (oldPassword && newPassword && (newPassword === newPasswordConfirmation)) {
        return true;
      }

      return false;
    };

    return [
      <ActionButton key={'passwordModalButton'} blue label='Change password' onButtonClicked={openChangePasswordModal} inverted={false} />,
      <JuceModal key={'passwordModal'} title='Change password' isOpen={changePasswordIsOpen} onRequestClose={closeChangePasswordModal}>
        <form className={styles.profileForm}>
          <div className={styles.formInput}>
            <label className={styles.formLabel}>Old password</label>
            <TextInput value={oldPassword} className={styles.formTextInput} id='oldPassword' type='password' onChange={changeOldPassword} />
          </div>
          <div className={styles.formInput}>
            <label className={styles.formLabel}>New password</label>
            <TextInput value={newPassword} className={styles.formTextInput} id='new_password' type='password' onChange={changeNewPassword}/>
          </div>
          <div className={styles.formInput}>
            <label className={styles.formLabel}>Confirm new password</label>
            <TextInput value={newPasswordConfirmation} className={styles.formTextInput} id='new_password_confirmation' type='password' onChange={changeNewPasswordConfirmation}/>
          </div>
        </form>

        <ActionButton green fullWidth label='Change password' disabled={!isValidChange()} onButtonClicked={changePassword} inverted={false} />
      </JuceModal>,
    ];
  };

  const renderDeleteAccountModal = () => {
    return [
      <ActionButton red key={'deleteAccountModalButton'} label='Delete Account' onButtonClicked={openDeleteAccountModal} inverted={false} />,
      <JuceModal key={'deleteAccountModal'} title='Delete account' isOpen={deleteAccountIsOpen} onRequestClose={closeDeleteAccountModal}>
        <div className={styles.deleteModal}>
          <p><b>Are you sure you would like to delete this account?</b></p>
          <br />
          <p>Any subscriptions you have will be cancelled and you will lose access to all of your serial numbers.</p>
          <div className={styles.deleteEmail}>
            <TextInput onChange={(e)=> setDeleteUserEmail(e.target.value)} value={deleteUserEmail} placeholder={'Enter your email address to confirm deletion'} />
          </div>
          <ActionButton disabled={deleteUserEmail != profile.email} fullWidth red label='Yes, delete this account' onButtonClicked={deleteAccount} inverted={false} />
        </div>
      </JuceModal>,
    ];
  };

  const updateUser = async () => {
    try {
      await onUpdateUser(profileObject);
    } catch(e) {
      // TODO
    }
  };

  const updateProfileObject = (e) => {
    setProfileObject({...profileObject, [e.target.id]: e.target.value });
  };

  return (<div>
    <h3>Your profile</h3>
    {profile ?
      <div className={styles.account}>
        <form className={styles.profileForm} onSubmit={(e) => {
          e.preventDefault();
        }}>
          <fieldset>
            <div className={styles.formInput}>
              <label className={styles.formLabel}>First name</label>
              <TextInput hasError={fieldHasError('first_name')} id='first_name' onChange={updateProfileObject} value={profileObject.first_name} className={styles.formTextInput} />
              <div className={styles.validationError}><span>{fieldHasError('first_name')}</span></div>
            </div>
            <div className={styles.formInput}>
              <label className={styles.formLabel}>Last name</label>
              <TextInput hasError={fieldHasError('last_name')} onChange={updateProfileObject} id='last_name' value={profileObject.last_name}  className={styles.formTextInput} />
              <div className={styles.validationError}><span>{fieldHasError('last_name')}</span></div>
            </div>
            <div className={styles.formInput}>
              <label className={styles.formLabel}>Organisation</label>
              <TextInput hasError={fieldHasError('organisation')} onChange={updateProfileObject} id='organisation' value={profileObject.organisation} className={styles.formTextInput} />
              <div className={styles.validationError}><span>{fieldHasError('organisation')}</span></div>
            </div>
            <div className={styles.formInput}>
              <label className={styles.formLabel}>Email</label>
              <TextInput hasError={fieldHasError('email')} onChange={updateProfileObject} id='email' value={profileObject.email} className={styles.formTextInput} />
              <div className={styles.validationError}><span>{fieldHasError('email')}</span></div>
            </div>
            <div className={styles.formInput}>
              <label className={styles.formLabel}>Username</label>
              <TextInput hasError={fieldHasError('username')} onChange={updateProfileObject} id='username' value={profileObject.username} className={styles.formTextInput} />
              <div className={styles.validationError}><span>{fieldHasError('username')}</span></div>
            </div>
          </fieldset>
          <div className={styles.formFooter}>
            <ActionButton blue inverted={false} type='submit' onButtonClicked={updateUser} label='Update Details' />
            {renderChangePasswordModal()}
            {renderDeleteAccountModal()}
          </div>
        </form>
      </div>
      : <Loading />}
  </div>);
};

AccountForm.propTypes = {
  onDeleteAccount: PropTypes.func,
  onUpdateUser: PropTypes.func,
  updateError: PropTypes.object,
  profile: PropTypes.object,
};

export default withStyles(styles)(AccountForm);
