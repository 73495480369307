import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './InvalidDiscount.styl';

import InfoCard from './InfoCard';

const InvalidDiscount = () => (
  <InfoCard className={styles.card}>
    <h3 className={styles.cardTitle}>
      Your discounts entitle you to a free licence
    </h3>
    <p className={styles.cardMessage}>
      Please either increase the number of seats or contact support, <a className={styles.supportLink} href={'mailto:support@juce.com'}>support@juce.com</a>, to redeem your free licence.
    </p>

  </InfoCard>
);

export default withStyles(styles)(InvalidDiscount);
