import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import resetStyles from 'globalStyles/reset'; // Included here so as to not be included in the static header/footer build
import styles from './PageWrapper.styl';

class PageWrapper extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { children } = this.props;

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.columnWrapper}>
          {children}
        </div>
      </div>
    );
  }
}

PageWrapper.propTypes = {
  children: PropTypes.node,
};

export default withStyles(resetStyles, styles)(PageWrapper);
