import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import Panel from 'components/sections/Panel';

import styles from './shopClosed.styl';

const ShopClosed = () => (
  <Panel>
    <div className={styles.shopClosed}>
      <br />
      <br />
      <h3>The JUCE shop is being updated</h3>
      <br />
      <br />
      <p>Please bear with us, it will be back soon.</p>
    </div>
  </Panel>
);

export default withStyles(styles)(ShopClosed);
