// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".LearningCard_card_1kiZ6 {\n  background: #a65a95;\n  color: #fff;\n  margin-left: 12px;\n  margin-right: 12px;\n  -webkit-box-flex: 1;\n  -webkit-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.LearningCard_cardTitle_YSDVt {\n  font-weight: font-weight-bold;\n  color: #fff;\n}\n.LearningCard_cardMessage_RGJTN {\n  color: #fff;\n}\n.LearningCard_cardCta_24NvE {\n  border-bottom: 1px solid #f2f2f2;\n  text-decoration: none;\n  -webkit-transition: opacity 0.1s linear;\n  transition: opacity 0.1s linear;\n}\n.LearningCard_cardCta_24NvE:hover,\n.LearningCard_cardCta_24NvE:focus {\n  opacity: 0.6;\n}\n", "",{"version":3,"sources":["LearningCard.styl"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,mBAAO;EAAP,eAAO;MAAP,WAAO;UAAP,OAAO;AACT;AACA;EACE,6BAA6B;EAC7B,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,gCAAgC;EAChC,qBAAqB;EACrB,uCAA+B;EAA/B,+BAA+B;AACjC;AACA;;EAEE,YAAY;AACd","file":"LearningCard.styl","sourcesContent":[".card {\n  background: #a65a95;\n  color: #fff;\n  margin-left: 12px;\n  margin-right: 12px;\n  flex: 1;\n}\n.cardTitle {\n  font-weight: font-weight-bold;\n  color: #fff;\n}\n.cardMessage {\n  color: #fff;\n}\n.cardCta {\n  border-bottom: 1px solid #f2f2f2;\n  text-decoration: none;\n  transition: opacity 0.1s linear;\n}\n.cardCta:hover,\n.cardCta:focus {\n  opacity: 0.6;\n}\n"]}]);
// Exports
exports.locals = {
	"card": "LearningCard_card_1kiZ6",
	"cardTitle": "LearningCard_cardTitle_YSDVt",
	"cardMessage": "LearningCard_cardMessage_RGJTN",
	"cardCta": "LearningCard_cardCta_24NvE"
};
module.exports = exports;
