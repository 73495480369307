import React, { useState } from 'react';
import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/withStyles';
import JuceModal from 'components/common/modal';

import FlexWrapper from 'components/wrappers/FlexWrapper';
import MainSection from 'components/sections/MainSection';
import MetaSidebarSection from 'components/sections/MetaSidebarSection';
import SupportCard from 'components/common/cards/SupportCard';
import LearningCard from 'components/common/cards/LearningCard';
import RedeemCard from 'components/common/cards/RedeemCard';
import UpgradeCard from 'components/common/cards/UpgradeCard';
import DownloadCard from 'components/common/cards/DownloadCard';
import ActionButton from 'components/common/buttons/ActionButton';
import TextInput from 'components/common/forms/TextInput';
import Loading from 'components/common/loading/ContentLoading';
import Licence from './licence';
import Subscription from './subscription';
import Serials from './Serials';
import MiniNav from 'components/common/MiniNav';

import styles from './dashboard.styl';

const AccountDashboardComponent = ({ licences, customer, profile, plans, onUnregisterProduct, onDeleteSubscription, subscriptions, serials, onRegisterProduct, hideShop }) => {

  const [redeemModalIsOpen, setRedeemModalIsOpen] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');

  const sortedLicences = licences ? [...licences].sort((a, b) => b.product_version - a.product_version) : [];
  const sortedSerials = serials ? [...serials].sort((a, b) => b.product.version - a.product.version) : [];

  function openModal() {
    setSerialNumber('');
    setRedeemModalIsOpen(true);
  }
  function closeModal() {
    setRedeemModalIsOpen(false);
  }

  const registerProduct = (serialNumber) => {
    onRegisterProduct(serialNumber);
    closeModal();
  };

  const latestJuceVersion = plans
    ? plans.reduce(
      (latest, {list_in_shop, product}) => (list_in_shop === true) ? Math.max(latest, product.version) : latest,
      0,
    )
    : 0;

  const renderSubscriptions = () => (
    <div>
      <h3>Subscriptions</h3>
      {subscriptions.map(subscription => <Subscription latestJuceVersion={`${latestJuceVersion}`} hideShop={hideShop} onDeleteSubscription={onDeleteSubscription} customer={customer} key={subscription.id} subscription={subscription} />)}
    </div>
  );

  const renderBalance = () => (
    <div>
      <h3>Account balance</h3>
      <div className={styles.balanceDetails}>
        <span><b>${(-customer.balance/100).toFixed(2)}</b> available to pay upcoming invoices</span>
      </div>
    </div>
  );

  const registerProductSerial = () => {
    return <JuceModal
      isOpen={redeemModalIsOpen}
      title='Register serial'
      onRequestClose={closeModal}
    >
      <p>Please enter the serial number provided during the purchase of a JUCE licence</p>
      <form>
        <TextInput
          placeholder='Enter your serial'
          className={styles.serialFormTextInput}
          value={serialNumber}
          onChange={e => setSerialNumber(e.target.value)}
        />
        <div>
          <ActionButton fullWidth inverted={false} label='Activate' onButtonClicked={() => registerProduct(serialNumber)} />
        </div>
      </form>
    </JuceModal>;
  };

  const renderUpgrade = () => {
    if (latestJuceVersion > 0 && sortedSerials.length > 0 && sortedSerials[0].product.version < latestJuceVersion) {
      return <UpgradeCard latestJuceVersion={`${latestJuceVersion}`}/>;
    }

    return null;
  };

  return (
    <FlexWrapper>
      <MainSection>
        { profile && serials && subscriptions ? <div className={styles.dashboard}>
          <MiniNav />
          <div>
            <h3 className={styles.title}>Licences activated in this account</h3>
            {sortedLicences && sortedLicences.map(licence => <Licence key={licence.id} licence={licence} />)}
            {sortedLicences && sortedLicences.length === 0 && <p>You have no licences activated on this JUCE account</p>}
          </div>

          {renderUpgrade()}

          {(subscriptions && subscriptions.length > 0) ? renderSubscriptions() : null}

          {(customer && customer.balance && customer.balance !== 0) ? renderBalance() : null}

          {(sortedSerials.length > 0) ? <div>
            <h3 className={styles.title}>Serial numbers</h3>
            <Serials onActivateSerial={registerProduct} onUnregister={onUnregisterProduct} serials={sortedSerials} />
          </div>: null}

        </div>:<Loading />}
      </MainSection>
      {profile? <MetaSidebarSection>
        <RedeemCard onClick={openModal}/>
        {registerProductSerial()}
        <DownloadCard />
        <LearningCard />
        <SupportCard />

      </MetaSidebarSection>: null}
    </FlexWrapper>
  );
};

AccountDashboardComponent.propTypes = {
  customer: PropTypes.object,
  licences: PropTypes.array,
  profile: PropTypes.object,
  plans: PropTypes.array,
  subscriptions: PropTypes.array,
  serials: PropTypes.array,
  onRegisterProduct: PropTypes.func,
  onUnregisterProduct: PropTypes.func,
  onDeleteSubscription: PropTypes.func,
  hideShop: PropTypes.bool,
};

export default withStyles(styles)(AccountDashboardComponent);
