import React, {useState} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import DatePicker from 'react-datepicker';
moment.locale('en-gb');
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import LinkButton from 'components/common/buttons/LinkButton';

import styles from './chargesTable.styl';
import { JUCE_API_URL } from './../../../constants/roli';

const ChargeItem = ({ charge }) => (
  <Tr className={styles.chargeRow}>
    <Td><span><b>{moment(charge.created*1000).format('l')}</b></span></Td>
    <Td><span>${(charge.amount/100).toFixed(2)}</span></Td>
    <Td><span>{charge.amount_refunded? `$${(charge.amount_refunded/100).toFixed(2)}`: '-'}</span></Td>
    <Td><span>{charge.payment_method_details? `${charge.payment_method_details.card.brand} **** ${charge.payment_method_details.card.last4}`: 'Unknown'}</span></Td>
    <Td><span>{charge.status}</span></Td>
    <Td>{charge.invoice && charge.receipt_url ? <LinkButton fullWidth inverted={false} green to={`${JUCE_API_URL}/api/v1/user/stripe/invoice/${charge.invoice.id}`} target='_blank' label='download'></LinkButton>: null}</Td>
    <Td>{charge.receipt_url ? <LinkButton fullWidth inverted={false} green to={charge.receipt_url} target='_blank' label='download'></LinkButton> : null}</Td>
  </Tr>
);

const ChargesTable = ({ charges }) => {
  const [fromFilter,setFromFilter] = useState(new Date( new Date().getFullYear()-1, 0, 1));
  const [toFilter,setToFilter] = useState(new Date());

  return (
    <div className={styles.chargesWrapper}>
      <h3>Previous Payments</h3>
      {charges.length ? <div className={styles.dateFilters}>
        <form onSubmit={(e)=>{
          e.preventDefault();
        }} className={styles.filters}>

          <div className={styles.filter}>
            <label>To:</label><DatePicker
              dateFormat='MMMM d, yyyy'
              showYearDropdown
              selected={toFilter}
              onChange={setToFilter}
              className={styles.datepicker}
            />
          </div>

          <div className={styles.filter}>
            <label>From</label>
            <DatePicker
              dateFormat='MMMM d, yyyy'
              showYearDropdown
              selected={fromFilter}
              onChange={setFromFilter}
            />
          </div>
        </form>
        <Table className={styles.chargesTable}>
          <Thead className={styles.chargesHead}>
            <Tr>
              <Th>Date</Th>
              <Th>Amount</Th>
              <Th>Refunded</Th>
              <Th>Payment Card</Th>
              <Th>Status</Th>
              <Th>Invoice</Th>
              <Th>Receipt</Th>
            </Tr>
          </Thead>
          <Tbody className={styles.chargesBody}>
            {charges.map(charge => {
              let createdDate = new Date(charge.created*1000);
              if ((createdDate >= fromFilter ) && (createdDate <= toFilter)) {
                return <ChargeItem key={charge.id} charge={charge} />;
              }
            })}
          </Tbody>
        </Table>
      </div>:<p>You have not made any purchases yet.</p>}

    </div>
  );

};

ChargeItem.propTypes = {
  charge: PropTypes.object,
};

ChargesTable.propTypes = {
  charges: PropTypes.object,
};



export default withStyles(styles)(ChargesTable);
