import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './SupportCard.styl';

import InfoCard from './InfoCard';
import urls from 'constants/urls';

const SupportCard = () => (
  <InfoCard className={styles.card}>
    <h3 className={styles.cardTitle}>
      Need Help?
    </h3>
    <p className={styles.cardMessage}>
      Our support team is always happy to help out
    </p>
    <div><a href={urls.forum} className={styles.cardCta}>
      Visit the JUCE forum
    </a></div>
    <br/>
    <div><a href={'mailto:support@juce.com'} className={styles.cardCta}>
      Contact support
    </a></div>
  </InfoCard>
);

export default withStyles(styles)(SupportCard);
