// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PageContentWrapper_pageContentWrapper_2k1SX {\n  -webkit-box-flex: 1;\n  -webkit-flex: auto;\n      -ms-flex: auto;\n          flex: auto;\n  min-height: 60vh;\n}\n.PageContentWrapper_withSubNav_3Spe8 {\n  margin-top: 64px;\n}\n@media (min-width: 750px) {\n  .PageContentWrapper_withSubNav_3Spe8 {\n    margin-top: 0;\n  }\n}\n", "",{"version":3,"sources":["PageContentWrapper.styl"],"names":[],"mappings":"AAAA;EACE,mBAAU;EAAV,kBAAU;MAAV,cAAU;UAAV,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,aAAa;EACf;AACF","file":"PageContentWrapper.styl","sourcesContent":[".pageContentWrapper {\n  flex: auto;\n  min-height: 60vh;\n}\n.withSubNav {\n  margin-top: 64px;\n}\n@media (min-width: 750px) {\n  .withSubNav {\n    margin-top: 0;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"pageContentWrapper": "PageContentWrapper_pageContentWrapper_2k1SX",
	"withSubNav": "PageContentWrapper_withSubNav_3Spe8"
};
module.exports = exports;
