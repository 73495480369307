import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import TextInput from 'components/common/forms/TextInput';
import ActionButton from 'components/common/buttons/ActionButton';
import FlexWrapper from 'components/wrappers/FlexWrapper';
import Panel from 'components/sections/Panel';
import PadlockIcon from 'components/common/icons/PadlockIcon';

import styles from './ResetPassword.styl';

const ResetPasswordForm = ({ onLoadResetPassword, submittedForgotPassword}) => {

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const isValid = () => {
    return password && (password == passwordConfirm);
  };

  return (<FlexWrapper>
    <Panel>
      <PadlockIcon className={styles.padlockIcon} />
      <div className={styles.forgotPassword}>
        {!submittedForgotPassword && <form className={styles.forgotPasswordForm} onSubmit={(e) => {
          e.preventDefault();
          onLoadResetPassword(password);
        }}>
          <legend className={styles.formLegend}><span>Enter your email adress</span></legend>
          <fieldset>
            <div className={styles.formInput}>
              <TextInput type='password' id='new_password' placeholder='Enter new password' value={password} onChange={(e)=>setPassword(e.target.value)} className={styles.formTextInput} />
            </div>
            <div className={styles.formInput}>
              <TextInput type='password' id='new_password_confirm' placeholder='Please confirm the new password' value={passwordConfirm} onChange={(e)=>setPasswordConfirm(e.target.value)} className={styles.formTextInput} />
            </div>
          </fieldset>

          <div className={styles.formFooter}>
            <ActionButton disabled={!isValid()} fullWidth blue inverted={false} type='submit' onButtonClicked={()=>{}} label='Reset password' />
          </div>
        </form>}
        {submittedForgotPassword && <div><h3>Thanks, please check your email for a password reset link</h3></div>}
      </div>
    </Panel>
  </FlexWrapper>);
};

ResetPasswordForm.propTypes = {
  submittedForgotPassword: PropTypes.bool,
  onLoadResetPassword: PropTypes.func,
};

export default withStyles(styles)(ResetPasswordForm);
