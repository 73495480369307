// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MetaSidebarSection_metaSidebarSection_2wwiO {\n  -webkit-box-flex: 1;\n  -webkit-flex-grow: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n}\n@media (min-width: 544px) {\n  .MetaSidebarSection_metaSidebarSection_2wwiO {\n    display: block;\n    -webkit-box-flex: 1;\n    -webkit-flex-grow: 1;\n        -ms-flex-positive: 1;\n            flex-grow: 1;\n  }\n}\n@media (min-width: 850px) {\n  .MetaSidebarSection_metaSidebarSection_2wwiO {\n    -webkit-flex-basis: 33.33333333333333%;\n        -ms-flex-preferred-size: 33.33333333333333%;\n            flex-basis: 33.33333333333333%;\n    display: block;\n  }\n}\n@media (min-width: 1176px) {\n  .MetaSidebarSection_metaSidebarSection_2wwiO {\n    -webkit-flex-basis: 25%;\n        -ms-flex-preferred-size: 25%;\n            flex-basis: 25%;\n  }\n}\n", "",{"version":3,"sources":["MetaSidebarSection.styl"],"names":[],"mappings":"AAAA;EACE,mBAAY;EAAZ,oBAAY;MAAZ,oBAAY;UAAZ,YAAY;AACd;AACA;EACE;IACE,cAAc;IACd,mBAAY;IAAZ,oBAAY;QAAZ,oBAAY;YAAZ,YAAY;EACd;AACF;AACA;EACE;IACE,sCAA8B;QAA9B,2CAA8B;YAA9B,8BAA8B;IAC9B,cAAc;EAChB;AACF;AACA;EACE;IACE,uBAAe;QAAf,4BAAe;YAAf,eAAe;EACjB;AACF","file":"MetaSidebarSection.styl","sourcesContent":[".metaSidebarSection {\n  flex-grow: 1;\n}\n@media (min-width: 544px) {\n  .metaSidebarSection {\n    display: block;\n    flex-grow: 1;\n  }\n}\n@media (min-width: 850px) {\n  .metaSidebarSection {\n    flex-basis: 33.33333333333333%;\n    display: block;\n  }\n}\n@media (min-width: 1176px) {\n  .metaSidebarSection {\n    flex-basis: 25%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"metaSidebarSection": "MetaSidebarSection_metaSidebarSection_2wwiO"
};
module.exports = exports;
