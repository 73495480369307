import React from 'react';

const CalandarIcon = () => (
  <svg width='38' height='40.427' version='1' viewBox='0 0 38 40.427' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path d='m0 38.214v-25.786h38v25.709c0 2.2-0.01359 2.2913-2.2136 2.2913h-33.495c-2.2 0-2.2913-0.013592-2.2913-2.2136z' fill='#e6e6e6' />
      <path d='m38 6.7184v7.7087h-38l0.07767-7.6311c0.022391-2.1999 0.013592-2.2913 2.2136-2.2913h33.417c2.2 0 2.2913 0.013592 2.2913 2.2136z' fill='#1a1a1a' />
      <g transform='matrix(1.1553 0 0 1.1553 -8.5728 -4.9903)' fill='#2b2b2b'>
        <rect x='13' y='20' width='4' height='4' />
        <rect x='19' y='20' width='4' height='4' />
        <rect x='25' y='20' width='4' height='4' />
        <rect x='31' y='20' width='4' height='4' />
        <rect x='13' y='26' width='4' height='4' />
        <rect x='19' y='26' width='4' height='4' />
        <rect x='25' y='26' width='4' height='4' />
        <rect x='31' y='26' width='4' height='4' />
        <rect x='13' y='32' width='4' height='4' />
        <rect x='19' y='32' width='4' height='4' />
        <rect x='25' y='32' width='4' height='4' />
        <rect x='31' y='32' width='4' height='4' />
      </g>
      <rect x='10.191' width='4.0514' height='8.1553' ry='1.012' fill='#e6e6e6' />
      <rect x='24.191' width='4.0514' height='8.1553' ry='1.012' fill='#e6e6e6' />
    </g>
  </svg >
);

export default CalandarIcon;
