import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import urls from 'constants/urls';

import LinkButton from 'components/common/buttons/LinkButton';
import Panel from 'components/sections/Panel';
import JuceLogo from 'components/common/icons/JuceLogo';

import styles from './userFunnel.styl';

const UserFunnel = ({ planSlug }) => (
  <Panel>
    <JuceLogo className={styles.juceIcon} />
    <div className={styles.funnelWrapper}>
      <h3>Do you have a JUCE Account?</h3>
      <p>You must sign in to purchase a JUCE licence.</p>
      <p>If you have previously registered a JUCE licence in My ROLI, or have an account on the JUCE forum, then you can sign in using the credentials for that service.</p>
      <div className={styles.buttonWrapper}>
        <LinkButton fullWidth label={'Sign in'} to={`${urls.signIn}?redirect=/get-juce/${planSlug}`} />
        <LinkButton fullWidth label={'Register'} to={urls.register} />
      </div>
    </div>
  </Panel>
);

UserFunnel.propTypes = {
  planSlug: PropTypes.string,
};

export default withStyles(styles)(UserFunnel);
