import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './Panel.styl';

const Panel = ({ children  }) => {
  return (
    <section className={styles.panelWrapper}>
      <div className={styles.panel}>
        {children}
      </div>
    </section>
  );
};

Panel.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(Panel);
