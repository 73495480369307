import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import { stripePromise } from 'utils/stripeUtils';
import { Elements } from '@stripe/react-stripe-js';
import PaymentMethod from 'components/shop/paymentMethod';
import UpdatePaymentMethodForm from 'components/shop/updatePaymentMethodForm';

import styles from './basket.styl';

class EditSubscriptionPaymentBasket extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const paymentMethod = this.props.customer ? this.props.customer.default_payment_method : null;
    const shipping = this.props.customer ? this.props.customer.shipping : null;
    const vatId = this.props.basket ? this.props.basket.customer_vat_id : null;

    return (
      paymentMethod
        ? <div className={styles.basketContainer}>
          <div className={styles.existingBillingDetails}>
            <PaymentMethod
              shipping={shipping}
              card={paymentMethod.card}
              vatId={vatId}
            />
          </div>
          <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd', setupFutureUsage: 'off_session' }}>
            <UpdatePaymentMethodForm returnUrl={window.location.href} />
          </Elements>
        </div>
        : <div className={styles.basketContainer}>
          <span>There was an error retrieving existing payment details. Please contact JUCE support.</span>
        </div>
    );
  }
}

EditSubscriptionPaymentBasket.propTypes = {
  customer: PropTypes.object,
  basket: PropTypes.object,
};

export default withStyles(styles)(EditSubscriptionPaymentBasket);
