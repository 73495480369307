// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PaymentCard_wrapper_36IKZ {\n  border: 1px solid #dcdcdc;\n  display: -webkit-inline-box;\n  display: -webkit-inline-flex;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  padding: 5px;\n  border-radius: 4px;\n  background: #fff;\n}\n.PaymentCard_cardDetail_1PfMU {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  font-size: 0.9375rem;\n  margin-right: 15px;\n}\n.PaymentCard_cardIcon_1A3AP {\n  border-radius: 5px;\n}\n", "",{"version":3,"sources":["PaymentCard.styl"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,2BAAoB;EAApB,4BAAoB;EAApB,2BAAoB;EAApB,oBAAoB;EACpB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,oBAAa;EAAb,qBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,4BAAsB;EAAtB,6BAAsB;EAAtB,8BAAsB;MAAtB,0BAAsB;UAAtB,sBAAsB;EACtB,wBAAuB;EAAvB,+BAAuB;MAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,oBAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","file":"PaymentCard.styl","sourcesContent":[".wrapper {\n  border: 1px solid #dcdcdc;\n  display: inline-flex;\n  padding: 5px;\n  border-radius: 4px;\n  background: #fff;\n}\n.cardDetail {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  font-size: 15px;\n  margin-right: 15px;\n}\n.cardIcon {\n  border-radius: 5px;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "PaymentCard_wrapper_36IKZ",
	"cardDetail": "PaymentCard_cardDetail_1PfMU",
	"cardIcon": "PaymentCard_cardIcon_1A3AP"
};
module.exports = exports;
