import { observable, action, flow, makeObservable } from 'mobx';
import { getEnvVar } from 'utils/envUtils';

import urls from 'constants/urls';

import SiteMetaStore from 'stores/domain/SiteMetaStore';
import SiteUIStore from 'stores/ui/SiteUIStore';

const apiHost = getEnvVar('JUCE_API_URL');


/**
 * Class that loads and stores data for the 404 page
 * @class
 * @param {object} preloadedData Object containing data loaded server-side
 */
class AccountStore {
  pageData = undefined;
  accountData = undefined;
  licenceData = undefined;
  chargesData = undefined;
  serialData = undefined;
  subscriptionsData = undefined;
  customerData = undefined;
  loading = false;
  pendingOrder = false;
  signedIn = false;
  emailVerificationRequired = false;
  signInError = undefined;
  submittedForgotPassword = undefined;
  registerError = undefined;
  updateError = undefined;
  hasMultipleStripeAccounts = false;
  recently_registered_user = undefined;

  constructor(siteMetaStore, siteUIStore, preloadedData) {
    makeObservable(this, {
      pageData: observable,
      accountData: observable,
      licenceData: observable,
      chargesData: observable,
      serialData: observable,
      subscriptionsData: observable,
      customerData: observable,
      loading: observable,
      pendingOrder: observable,
      signedIn: observable,
      emailVerificationRequired: observable,
      signInError: observable,
      submittedForgotPassword: observable,
      registerError: observable,
      updateError: observable,
      hasMultipleStripeAccounts: observable,
      recently_registered_user: observable,
      loadNotFoundData: action('Load 404 page data'),
      loadSignOut: action.bound,
      loadDeleteAccount: action.bound,
      loadSignIn: action.bound,
      loadSignInDiscourse: action.bound,
      loadSignInReauthenticateDiscourse: action.bound,
      loadForgotPassword: action.bound,
      loadResetPassword: action.bound,
      loadRequestValidation: action.bound,
      loadRegister: action.bound,
      loadUpdateUser: action.bound,
      loadAccountProfileData: action('Load account profile data'),
      loadAccountLicenceData: action('Load account licence data'),
      loadAccountChargesData: action('Load account charges data'),
      loadAccountCustomerData: action('Load stripe account data'),
      loadAccountSubscriptionsData: action('Load account subscriptions data'),
      deleteSubscription: action.bound,
      loadAccountSerialData: action('Load account serial data'),
      loadRegisterProduct: action.bound,
      loadUnregisterProduct: action.bound,
      onNotFoundDataLoaded: action('Successfully loaded 404 page data'),
      onAccountDataLoaded: action('Successfully loaded account data'),
      onSignOutLoaded: action('Successfully signed out of account'),
      onSignInLoaded: action('Successfully signed in to account'),
      onSignInDiscourseLoaded: action('Successfully signed in to discourse account'),
      onSignInReauthenticateDiscourseLoaded: action('Successfully signed in to discourse account'),
      onUpdateUserLoaded: action('Successfully updated user'),
      onForgotPasswordLoaded: action('Successfully signed out of account'),
      onResetPasswordLoaded: action('Successfully changed password'),
      onRequestValidationLoaded: action('Successfully requested validation'),
      onLicenceDataLoaded: action('Successfully loaded licence data'),
      onCustomerDataLoaded: action('Successfully loaded licence data'),
      onChargesDataLoaded: action('Successfully loaded charges data'),
      onSubscriptionsDataLoaded: action('Successfully loaded subscriptions data'),
      onSubscriptionDeleteLoaded: action('Successfully deleted subscription'),
      onSerialDataLoaded: action('Successfully loaded serial data'),
      onRegisterAccountLoaded: action('Successfully registered account'),
      onPreloadedDataFound: action('Preloaded 404 page data found'),
    });

    this.siteMetaStore = siteMetaStore || new SiteMetaStore();
    this.siteUIStore = siteUIStore || new SiteUIStore();

    // Store receives preloadedData when the server-side render completes
    if (preloadedData && preloadedData.notFoundStore) {
      this.onPreloadedDataFound(preloadedData);
    }
  }

  __preloadServerSideData() {
    this.loading = true;
    this.siteMetaStore.setPageMetadata(null, 'Get Juce');
  }

  async requestForgotPassword(email) {
    let forgotPasswordResponse = await fetch(`${apiHost}/api/v1/tokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    });
    if (forgotPasswordResponse.status == 200) {
      return true;
    }
    throw new Error('Sending out new password');
  }

  async requestResetPassword(reset_token, password) {
    const resetPasswordResponse = await fetch(`${apiHost}/api/v1/tokens`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ value: reset_token, password }),
    });

    const text = await resetPasswordResponse.text();
    if (text === 'EMAIL_VERIFICATION_REQUIRED') {
      throw new Error('Email verification is required before a password can be reset.');
    }

    let message = 'There was an unexpected problem resetting your password.';
    try {
      const response = JSON.parse (text);
      if (resetPasswordResponse.status == 200) {
        return response;
      }
      if (response.errors.length > 0) {
        message = response.errors[0].message;
      }
    } catch (e) {
      message = 'There was a problem resetting your password.';
    }
    throw new Error(message);
  }

  async requestAccountValidation(email) {
    let response = await fetch(`${apiHost}/api/v1/tokens/request_validation`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    });
    if (response.status == 200) {
      return response.json();
    }
    throw new Error('There was a problem requesting account validation email');
  }

  async requestProfileData(passive) {
    let profileResponse = await fetch(`${apiHost}/api/v1/user`, {
      method: 'GET',
      credentials: 'include',
    });
    if (profileResponse.status == 200) {
      return profileResponse.json();
    }
    if (profileResponse.status == 401) {
      if (!passive) {
        window.location = urls.signIn;
      }

      return false;
    }
  }

  async requestSignIn(email, password) {
    let loginResponse = await fetch(`${apiHost}/api/v1/authenticate`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({identity:email, password}),
    });
    if (loginResponse.status == 200) {
      return loginResponse.json();
    }
    if (loginResponse.status == 403) {
      let response = await loginResponse.json();
      if (response.errors.reason == 'PASSWORD_RESET_REQUIRED') {
        window.location = urls.accountPasswordResetRequired;
      } else if (response.errors.reason == 'EMAIL_VERIFICATION_REQUIRED') {
        window.location = urls.accountLocked;
      }
      throw new Error('');
    }
    throw new Error('Incorrect username or password, please try again');
  }

  async requestSignInDiscourse(email, password, sso, sig) {
    let profileResponse = await fetch(`${apiHost}/api/v1/authenticate/discourse`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        identity:email,
        password,
        consumer: {
          name: 'discourse',
          sso,
          sig,
          application: 'juce',
        },
      }),
    });
    if (profileResponse.status == 200) {
      return profileResponse.json();
    }
    throw new Error('Incorrect username or password, please try again');
  }

  async requestSignInReauthenticateDiscourse(sso, sig) {
    let profileResponse = await fetch(`${apiHost}/api/v1/reauthenticate/discourse`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        consumer: {
          name: 'discourse',
          sso,
          sig,
          application: 'juce',
        },
      }),
    });
    if (profileResponse.status == 200) {
      return profileResponse.json();
    }
    throw new Error('Sign in required.');
  }

  async requestSignOut() {
    let profileResponse = await fetch(`${apiHost}/api/v1/deauthenticate`, {
      method: 'POST',
      credentials: 'include',
    });
    deleteCookie('token');
    if (profileResponse.status == 200) {
      return profileResponse.json();
    }

    return profileResponse.json();
  }

  async requestDeleteAccount() {
    let response = await fetch(`${apiHost}/api/v1/user`, {
      method: 'DELETE',
      credentials: 'include',
    });
    deleteCookie('token');
    if (response.status == 200) {
      return response.json();
    }
    let errorJson = await response.json();
    if (errorJson && errorJson.error) {
      throw new Error(errorJson.error);
    }
    throw new Error('An error occurred while deleting your account, please contact support@juce.com');
  }

  async requestUpdateUser(user) {
    let response = await fetch(`${apiHost}/api/v1/user`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    if (response.status == 200) {
      return response.json();
    }
    if (response.status == 400) {
      this.updateError = await response.json();
      throw new Error('User update failed');
    }
    if (response.status == 401 ) {
      this.siteUIStore.setPresentDismissable('Could not update account password, current password supplied is incorrect');
      throw new Error('User update failed');
    }
    throw new Error('User update failed');
  }

  async requestUpdateEmail(email) {
    let response = await fetch(`${apiHost}/api/v1/user/update_email`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    });
    if (response.status == 200) {
      return response.json();
    }
    if (response.status == 400) {
      let parsed = await response.json();
      throw new Error(parsed.error);
    }
    throw new Error('Email update failed');
  }

  async requestLicenceData() {
    let licenceResponse = await fetch(`${apiHost}/api/v1/user/licences`, {
      method: 'GET',
      credentials: 'include',
    });
    if (licenceResponse.status == 200) {
      return licenceResponse.json();
    }
  }

  async requestChargesData() {
    let chargesResponse = await fetch(`${apiHost}/api/v1/user/stripe/charges`, {
      method: 'GET',
      credentials: 'include',
    });
    if (chargesResponse.status == 200) {
      return chargesResponse.json();
    }
    throw new Error('Email update failed');
  }

  async requestSubscriptionsData() {
    let subscriptionsResponse = await fetch(`${apiHost}/api/v1/user/stripe/subscriptions`, {
      method: 'GET',
      credentials: 'include',
    });
    if (subscriptionsResponse.status == 200) {
      return subscriptionsResponse.json();
    }
    throw new Error('Problem fetching subscription data');
  }

  async requestCustomerData() {
    let response = await fetch(`${apiHost}/api/v1/user/stripe/customer`, {
      method: 'GET',
      credentials: 'include',
    });
    if (response.status == 200) {
      return response.json();
    }
    throw new Error('Error fetching customer data');
  }

  async requestSerialData() {
    let serialResponse = await fetch(`${apiHost}/api/v1/user/serials`, {
      method: 'GET',
      credentials: 'include',
    });
    if (serialResponse.status == 200) {
      return serialResponse.json();
    }
    throw new Error('Email update failed');
  }

  async requestRegisterAccount(newUser, turnstileToken) {
    const registerResponse = await fetch(`${apiHost}/api/v1/user`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'turnstile-token': turnstileToken,
      },
      body: JSON.stringify(newUser),
    });
    if (registerResponse.status == 200) {
      this.registerError = null;
    }
    if (registerResponse.status == 401) {
      window.location = urls.accountLocked;
      // this.siteUIStore.setPresentDismissable('It looks like this email address has been used before. Please check your email inbox for a verification link.');
    }

    return registerResponse.json();
  }

  async requestRegisterProduct(serial_number) {
    let registerResponse = await fetch(`${apiHost}/api/v1/user/products`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({serial_number}),
    });
    if (registerResponse.status == 200) {
      this.siteUIStore.setPresentDismissable('Product successfully activated');

      return registerResponse.json();
    }
    throw new Error(await registerResponse.text());
  }

  async requestUnregisterProduct(serial_number) {
    let unregisterResponse = await fetch(`${apiHost}/api/v1/serial/${serial_number}/unregister`, {
      method: 'PUT',
      credentials: 'include',
    });
    if (unregisterResponse.status == 200) {
      this.siteUIStore.setPresentDismissable('Product successfully deactivated');

      return unregisterResponse.json();
    }
    this.siteUIStore.setPresentDismissable('Error deactivating serial number');
  }

  async requestDeleteSubscription(subscriptionId) {
    let response = await fetch(`${apiHost}/api/v1/user/stripe/subscription/${subscriptionId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (response.status == 200) {
      return response.json();
    }
    throw new Error('The was an error whilst cancelling subscripion, please contact support');
  }

  /**
   * Load the 404 page data
   */
  loadNotFoundData() {
    if (!this.pageData) {
      this.loading = true;
      this.requestNotFoundData()
        .then(response => this.onNotFoundDataLoaded(response.items[0]));
    } else {
      this.onNotFoundDataLoaded(this.pageData);
    }
  }

  /**
   * Load the sign out call
   */
  loadSignOut(redirectUrl) {
    this.loading = true;
    this.requestSignOut()
      .then(() => this.onSignOutLoaded(redirectUrl))
      .catch(() => this.onSignOutLoaded(redirectUrl));
  }

  /**
   * Load the delete user call
   */
  loadDeleteAccount() {
    this.loading = true;
    this.requestDeleteAccount()
      .then(() => this.loadSignOut())
      .catch(e => this.siteUIStore.setPresentDismissable(e.message));
  }

  /**
   * Load the sign in call
   */
  loadSignIn(email, password, passive, successCallback) {

    this.loading = true;
    this.requestSignIn(email, password)
      .then(() => this.onSignInLoaded(successCallback)).catch((e) => {
        if (!passive) {
          this.signInError = e.message;
        }
      });
  }

  /**
   * Load the sign in call
   */
  loadSignInDiscourse(email, password, sso, sig) {
    if (!this.accountData) {
      this.loading = true;
      this.requestSignInDiscourse(email, password, sso, sig)
        .then(response => this.onSignInDiscourseLoaded(response)).catch((e) => {
          this.signInError = e.message;
        });
    }
  }

  /**
   * Load the sign in call
   */
  loadSignInReauthenticateDiscourse(sso, sig) {
    if (!this.accountData) {
      this.loading = true;
      this.requestSignInReauthenticateDiscourse(sso, sig)
        .then(response => this.onSignInReauthenticateDiscourseLoaded(response)).catch((e) => {
          this.signInError = e.message;
        });
    }
  }

  /**
   * Loads forgot passwords\
   */
  loadForgotPassword(email) {
    if (email) {
      this.accountData = undefined;
      this.loading = true;
      this.requestForgotPassword(email)
        .then(() => this.onForgotPasswordLoaded(true))
        .catch(() => this.onForgotPasswordLoaded(false));
    }
  }

  /**
   * Loads reset password
   */
  loadResetPassword(reset_token, password) {
    this.accountData = undefined;
    this.loading = true;
    this.requestResetPassword(reset_token, password)
      .then(() => this.onResetPasswordLoaded())
      .catch((e)=> {
        this.siteUIStore.setPresentDismissable(e.message);
        this.loading = false;
      });
  }

  /**
   * Loads request validation
   */
  loadRequestValidation(email) {
    this.requestAccountValidation(email)
      .then(() => this.onRequestValidationLoaded());
  }

  /**
   * Loads sign up form
   */
  async loadRegister(newUser, turnstileToken, callback) {
    let errors = [];
    if (newUser.password != newUser.password_confirmation) {
      errors.push({
        field: 'password_confirmation',
        message: 'Password confirmation does not match password',
      });
    }
    if (newUser.email != newUser.email_confirmation) {
      errors.push({
        field: 'email_confirmation',
        message: 'Email confirmation does not match email',
      });
    }
    if (errors.length !== 0) {
      this.registerError = {errors};
      this.siteUIStore.setLoading(false);
    } else {
      delete newUser.password_confirmation;
      delete newUser.email_confirmation;
      this.siteUIStore.setLoading('Registering new account');
      this.requestRegisterAccount(newUser, turnstileToken)
        .then(response => {
          if (response.user) {
            this.onRegisterAccountLoaded(response.user);
            if (callback) {
              callback();
            }
          } else if (response.verificationRequired) {
            window.location = urls.accountLocked;
          } else if (response.errors && response.errors.length !== 0) {
            this.registerError = { errors: response.errors };
          } else if (response.turnstileError) {
            this.registerError = { errors: [{ field: 'email', message: `${response.turnstileError} - please contact JUCE support: info@juce.com` }] };
          }
        })
        .catch(() => {
          this.registerError = { errors: [{ field: 'email', message: 'There has been an unexpected error creating your account. Please try again.' }] };
        })
        .finally(() => {
          this.siteUIStore.setLoading(false);
        });
    }
  }

  /**
   * Loads reset password
   */
  loadUpdateUser = flow(function*(user) {
    try {
      this.siteUIStore.setLoading('Updating profile');
      this.updateError = null;
      yield this.requestUpdateUser(user);
      let response = yield this.requestProfileData();
      yield this.onAccountDataLoaded(response);
      if (user.email && (user.email !== this.accountData.email)) {
        //send off request to change email
        try {
          yield this.requestUpdateEmail(user.email);
          this.siteUIStore.setLoading(false);
          this.siteUIStore.setPresentDismissable(`Please check your email inbox at ${user.email} to confirm your new email address`);
        } catch(e) {
          this.siteUIStore.setLoading(false);
          this.siteUIStore.setPresentDismissable(e.message);
        }

      } else {
        this.siteUIStore.setLoading(false);
        this.siteUIStore.setPresentDismissable('Your details were updated');
      }
    } catch (e) {
      this.siteUIStore.setLoading(false);
      if (user.password) {
        try {
          this.siteUIStore.setPresentDismissable(`${this.updateError.errors[0].message} Please try again`);
        } catch (e) {
          this.siteUIStore.setPresentDismissable('Update failed! Please check the existing password you supplied is correct');
        }
      } else {
        this.siteUIStore.setPresentDismissable('There was a problem updating your profile');
      }
    }
  });

  /**
   * Load the User account profile
   */
  loadAccountProfileData(passive = false) {

    this.loading = true;
    this.requestProfileData(passive)
      .then(response => {
        this.onAccountDataLoaded(response);
      }).catch(()=>{});
  }

  /**
   * Load the User account liceses
   */
  loadAccountLicenceData() {
    this.loading = true;
    this.requestLicenceData()
      .then(licences => {
        this.onLicenceDataLoaded(licences);
      }).catch(()=>{});
  }

  /**
   * Load the User account charges
   */
  loadAccountChargesData() {

    this.loading = true;
    this.requestChargesData()
      .then(chargesResponse => {
        this.onChargesDataLoaded(chargesResponse.charges);
        if (chargesResponse.customerCount > 1) {
          this.hasMultipleStripeAccounts = true;
        }
      }).catch(()=>{});
  }

  /**
   * Load the User stripe account
   */
  loadAccountCustomerData() {
    this.loading = true;
    this.requestCustomerData()
      .then(response => {
        if (response.customers) {
          this.hasMultipleStripeAccounts = true;
        } else {
          this.onCustomerDataLoaded(response.customer);
        }

      }).catch(()=>{});
  }

  /**
   * Load the User account subscriptions
   */
  loadAccountSubscriptionsData() {
    this.loading = true;
    this.requestSubscriptionsData()
      .then(subscriptionsResponse => {
        this.onSubscriptionsDataLoaded(subscriptionsResponse.subscriptions);
      }).catch(() =>{});
  }

  /**
   * Delete User account subscriptions
   */
  deleteSubscription(subscriptionId) {
    this.siteUIStore.setLoading('Cancelling subscripiton');
    this.loading = true;
    this.requestDeleteSubscription(subscriptionId)
      .then(response => {
        this.siteUIStore.setLoading(false);
        this.loadAccountSerialData();
        this.loadAccountSubscriptionsData();
        this.loadAccountProfileData();
        this.onSubscriptionDeleteLoaded(response);
      }).catch(e => {
        this.siteUIStore.setLoading(e.message);
      });
  }

  /**
   * Load the User account liceses
   */
  loadAccountSerialData() {
    this.loading = true;
    this.requestSerialData()
      .then(serials => {
        this.onSerialDataLoaded(serials);
      }).catch(()=>{});
  }


  /**
   * Load the register serial number
   */
  loadRegisterProduct(serialNumber) {
    this.siteUIStore.setLoading('Registering serial');
    this.requestRegisterProduct(serialNumber)
      .then(() => {
        this.siteUIStore.setLoading(false);
        this.requestSerialData()
          .then(serials => {
            this.onSerialDataLoaded(serials);
          });
        this.requestLicenceData()
          .then(licences => {
            this.onLicenceDataLoaded(licences);
          });
      }).catch(e => {
        this.siteUIStore.setLoading(false);
        this.siteUIStore.setPresentDismissable(e.message);
      });
  }

  /**
   * Load the register serial number
   */
  loadUnregisterProduct(serialNumber) {
    this.siteUIStore.setLoading('Unregistering product');
    this.requestUnregisterProduct(serialNumber)
      .then(() => {
        this.siteUIStore.setLoading(false);
        this.requestSerialData()
          .then(serials => {
            this.onSerialDataLoaded(serials);
          });
        this.requestLicenceData()
          .then(licences => {
            this.onLicenceDataLoaded(licences);
          });
      }).catch(()=>{});

  }

  onNotFoundDataLoaded(pageData) {
    this.pageData = pageData;
    this.loading = false;
    // this.siteMetaStore.setPageMetadata(getField(this.pageData, 'pageMetadata.fields'), getField(this.pageData, 'title'));
  }

  onAccountDataLoaded(profile) {
    if (profile) {
      this.accountData = profile;
      this.signedOut = false;
      this.signedIn = true;
    } else {
      this.signedIn = false;
    }
    this.loading = false;
  }

  onSignOutLoaded(redirectUrl) {
    this.accountData = undefined;
    this.licenceData = undefined;
    this.loading = false;
    this.signedOut = true;
    this.signedIn = false;
    if (redirectUrl) {
      window.location = decodeURIComponent(redirectUrl);
    } else {
      window.location = urls.signIn;
    }
  }

  onSignInLoaded(successCallback) {
    this.loading = false;
    this.signedOut = false;
    this.signedIn = true;
    successCallback();
  }

  onSignInDiscourseLoaded(response) {
    if (response.redirect) {
      window.location = response.redirect;
    }
  }

  onSignInReauthenticateDiscourseLoaded(response) {
    if (response.redirect) {
      window.location = response.redirect;
    }
  }

  onUpdateUserLoaded() {
    this.loading = false;
    //now go and fetch profile again

  }

  onForgotPasswordLoaded(success) {
    this.loading = false;
    this.submittedForgotPassword = success;
  }

  onResetPasswordLoaded() {
    this.loading = false;
    window.location = urls.dashboard;
  }

  onRequestValidationLoaded() {
    window.location = urls.requestValidationSent;
  }

  onLicenceDataLoaded(licences) {
    this.licenceData = licences;
    this.loading = false;
  }
  onCustomerDataLoaded(customer) {
    this.customerData = customer;
    this.loading = false;
  }

  onChargesDataLoaded(charges) {
    this.chargesData = charges;
    this.loading = false;
  }

  onSubscriptionsDataLoaded(subscriptions) {
    this.subscriptionsData = subscriptions;
    this.loading = false;
  }

  onSubscriptionDeleteLoaded() {
    this.siteUIStore.setPresentDismissable('Successfully deleted subscription.');
    this.loading = false;
  }

  onSerialDataLoaded(serials) {
    this.serialData = serials;
    this.loading = false;
  }

  onRegisterAccountLoaded(user) {
    if (user) {
      this.accountData = user;
    }
  }

  onPreloadedDataFound(preloadedData) {
    this.onNotFoundDataLoaded(preloadedData.notFoundStore.pageData);
  }
}

function setCookie(name, value, days) {
  var d = new Date;
  d.setTime(d.getTime() + 24*60*60*1000*days);
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
}
function deleteCookie(name) { setCookie(name, '', -1); }


export default AccountStore;
