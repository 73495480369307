import React from 'react';

const CircularTickIcon = (props) => (
  <svg version='1.1' viewBox='0 0 80 80' preserveAspectRatio='xMidYMid meet' {...props}>
    <title>Tick</title>
    <path d='M40 0C17.9 0 0 17.9 0 40s17.9 40 40 40 40-17.9 40-40S62.1 0 40 0zm21.6 32.9L32.7 57.2c-1.8 1.5-4.6 1.3-6.1-.5-.2-.2-.3-.4-.5-.7-.3-.2-.6-.5-.9-.8l-7.5-8.9c-1.5-1.8-1.3-4.6.5-6.1s4.6-1.3 6.1.5l5.9 7.1L56 26.2c1.8-1.5 4.6-1.3 6.1.5 1.6 1.9 1.4 4.7-.5 6.2z'/>
  </svg>
);

export default CircularTickIcon;
