import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './LearningCard.styl';

import InfoCard from './InfoCard';
import urls from 'constants/urls';

const LearningCard = () => (
  <InfoCard className={styles.card}>
    <h3 className={styles.cardTitle}>
      Tutorials
    </h3>
    <p className={styles.cardMessage}>
      Get started using the JUCE tutorials
    </p>
    <div><a href={urls.tutorials} className={styles.cardCta}>
      Discover JUCE
    </a></div>
  </InfoCard>
);

export default withStyles(styles)(LearningCard);
