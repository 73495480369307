// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OrderAuthorisation_basketSuccess_3dQHe p {\n  text-align: center;\n}\n.OrderAuthorisation_basketSuccess_3dQHe svg {\n  width: 100px;\n  height: 100px;\n  fill: #6cc04a;\n}\n", "",{"version":3,"sources":["OrderAuthorisation.styl"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;AACf","file":"OrderAuthorisation.styl","sourcesContent":[".basketSuccess p {\n  text-align: center;\n}\n.basketSuccess svg {\n  width: 100px;\n  height: 100px;\n  fill: #6cc04a;\n}\n"]}]);
// Exports
exports.locals = {
	"basketSuccess": "OrderAuthorisation_basketSuccess_3dQHe"
};
module.exports = exports;
