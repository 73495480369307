import React, { Component } from 'react';

import Loading from '../../../../components/common/loading/ContentLoading';
class ConfirmedBasket extends Component {

  componentDidMount() {
    if (window) {
      // pass notification onto stripe client library
      window.top.postMessage('3DS-authentication-complete');
    }

  }
  render() {
    return <section>
      <Loading />
    </section>;
  }
}

export default ConfirmedBasket;
