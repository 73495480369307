// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OrderComplete_basketSuccess_2Yrit {\n  text-align: center;\n}\n.OrderComplete_basketSuccess_2Yrit svg {\n  width: 100px;\n  height: 100px;\n  fill: #6cc04a;\n}\n.OrderComplete_downloadLink_3VqmK {\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["OrderComplete.styl"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;AACf;AACA;EACE,gBAAgB;AAClB","file":"OrderComplete.styl","sourcesContent":[".basketSuccess {\n  text-align: center;\n}\n.basketSuccess svg {\n  width: 100px;\n  height: 100px;\n  fill: #6cc04a;\n}\n.downloadLink {\n  margin-top: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"basketSuccess": "OrderComplete_basketSuccess_2Yrit",
	"downloadLink": "OrderComplete_downloadLink_3VqmK"
};
module.exports = exports;
