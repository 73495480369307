import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import TextHeader from 'components/common/pageHeaders/TextHeader';

import EditSubscriptionPaymentBasket from 'components/shop/baskets/editSubscriptionPayment';
import FlexWrapper from '../../../../components/wrappers/FlexWrapper';
import ContentLoading from '../../../../components/common/loading/ContentLoading';

class EditSubscriptionPaymentPage extends Component {

  componentWillMount() {
    if (!this.props.siteUIStore.runningOnServer) {
      this.props.accountStore.loadAccountProfileData();
      this.props.shopStore.reset();
      this.props.shopStore.getPlans();
      this.props.accountStore.loadAccountCustomerData();
      this.props.shopStore.createCurrentSubscriptionBasket();
    }
    this.props.siteMetaStore.setPageMetadata(null, 'Edit Subscription');
  }

  render() {
    const { shopStore, accountStore } = this.props;

    return <section>
      <TextHeader
        title={'Update billing details'}
        inverted={false}
      />
      <FlexWrapper>
        { shopStore.basket ? <EditSubscriptionPaymentBasket
          basket={shopStore.basket}
          customer={accountStore.customerData}
        />: <ContentLoading />}
      </FlexWrapper>
    </section>;
  }
}

EditSubscriptionPaymentPage.propTypes = {
  siteUIStore: PropTypes.object,
  shopStore: PropTypes.object,
  accountStore: PropTypes.object,
  siteMetaStore: PropTypes.object,
};

export default inject('shopStore', 'accountStore', 'siteUIStore', 'siteMetaStore')(observer(EditSubscriptionPaymentPage));
