import React from 'react';

const KeyIcon = () => (
  <svg viewBox='0 0 1024 896.9' height='1024' width='896.9'>
    <path d='M640.9 63.89999999999998c-141.4 0-256 114.6-256 256 0 19.6 2.2 38.6 6.4 56.9L0 768v64l64 64h128l64-64v-64h64v-64h64v-64h128l70.8-70.8c18.7 4.3 38.1 6.6 58.1 6.6 141.4 0 256-114.6 256-256S782.2 63.89999999999998 640.9 63.89999999999998zM384 512L64 832v-64l320-320V512zM704 320c-35.3 0-64-28.7-64-64 0-35.3 28.7-64 64-64s64 28.7 64 64C768 291.29999999999995 739.3 320 704 320z' />
  </svg>
);


export default KeyIcon;
